import React from 'react';

const RoomAmenitiesHeader = ({ children, containerStyles }) => (
  <div className={`${containerStyles} underlined-title-sm`}>
    {children}
  </div>
);

RoomAmenitiesHeader.defaultProps = {
  containerStyles: '',
};

export default RoomAmenitiesHeader;
