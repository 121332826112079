import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'gatsby';

import PropertiesService from '../../services/properties.service';
import Arrow from '../../assets/img/icons/ic-chevron-right-light-gray.svg';
import { replaceKeywords } from '../../helpers/helper-methods';

import styles from './Breadcrumbs.module.scss';

const Routes = [
  {
    href: '/my-properties',
    title: 'My Properties',
  },
  {
    href: '/my-properties/:id',
    title: ':proprtyTitle',
  },
  {
    href: '/my-properties/:id/offers',
    title: 'Offers',
  },
  {
    href: '/my-properties/:id/offers/create-offer',
    title: 'Create offer',
  },
  {
    href: '/my-properties/:id/offers/:offerId',
    title: ':offerTitle',
  },
  {
    href: '/my-properties/:id/offers/:offerId/edit',
    title: 'Edit offer',
  },
  {
    href: '/my-properties/:id/edit',
    title: 'Edit property',
  },
  {
    href: '/my-properties/:id/rooms',
    title: 'Rooms',
  },
  {
    href: '/my-properties/:id/rooms/:roomId/edit',
    title: 'Edit room',
  },
  {
    href: '/my-properties/:id/bookings',
    title: 'Bookings',
  },
  {
    href: '/my-properties/:id/bookings/:bookId',
    title: 'Booking details',
  },
];

const Breadcrumbs = ({
  path, base, id, offerId, roomId, bookId,
}) => {
  const propertiesService = useMemo(() => PropertiesService(), []);
  const [proprtyTitle, setPropertyTitle] = useState();
  const [offerTitle, setOfferTitle] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const paths = Routes
    .filter((route) => path
      .match(route.href))
    .map(({ title, href }) => ({
      title: replaceKeywords(title, {
        proprtyTitle: proprtyTitle || 'Property details',
        offerTitle: offerTitle || 'Offer details',
      }),
      href: replaceKeywords(`${base}${href}`, {
        id, offerId, roomId, bookId,
      }),
    }));

  useEffect(() => {
    const crumbRequests = [propertiesService.getPropertyTitle(id)];

    if (offerId) {
      crumbRequests.push(propertiesService.getOfferTitle(id, offerId));
    }

    Promise.all(crumbRequests)
      .then(([propertyTitleRes, offerTitleRes]) => {
        const { data: { title: selectedPropertyTitle } } = propertyTitleRes;
        setPropertyTitle(selectedPropertyTitle);

        if (offerTitleRes) {
          const { data: { title: selectedOfferTitle } } = offerTitleRes;
          setOfferTitle(selectedOfferTitle);
        }

        setTimeout(() => setIsLoading(false), 100);
      });
  }, [id, offerId]);

  return (
    <div className={`flex flex-v-center ${styles.wrapper}`}>
      {isLoading
        ? <Link className={styles.link} to={paths[0].href}>{paths[0].title}</Link>
        : paths.map(({ title, href }, index) => (
          <Fragment key={index}>
            <Link className={`${styles.link} text-truncate`} to={href}>
              {title}
            </Link>
            <Arrow className={styles.arrow} />
          </Fragment>
        ))}
    </div>
  );
};

Breadcrumbs.defaultProps = {
  base: '/sellers',
};

export default Breadcrumbs;
