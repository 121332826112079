import React, { useMemo } from 'react';
import toastr from 'toastr';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { TimePicker } from 'antd';

import { Textarea } from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import FormikRadioGroup from '../RadioGroup/RadioGroup';
import IconTime from '../../assets/img/icons/ic-time-default.svg';
import RoomsServicesService from '../../services/rooms-services.service';
import { ROOM_SERVICE_TIME_FORMAT } from '../../constans/formats';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { PHONE_WIDTH_BREAKPOINT } from '../../constans/header';
import {
  ROOMS_SERVICES_OPTIONS,
  ROOMS_SERVICES_OPTIONS_MOBILE,
  LIMITED_HOURS,
  DEFAULT_FROM,
  DEFAULT_TO,
} from '../../constans/roomsServices';

import styles from './RoomService.module.scss';
import './picker.scss';
import { MAX_ROOM_SERVICE_DESCRIPTION_LENGTH, REGEXP_NOSPACES } from '../../constans/validation';
import { ROOM_SERVICE_DESCRIPTION } from '../../constans/error-messages';
import { SUCCESS_SEND_MSG } from '../../constans/texts/texts';
import { filterObjectEmptyProperties } from '../../helpers/helper-methods';

const validationSchema = Yup.object({
  comfort: Yup.string(),
  status: Yup.string(),
  fromTime: Yup.string().when('status', {
    is: LIMITED_HOURS.value,
    then: Yup.string().required(),
  }),
  toTime: Yup.string().when('status', {
    is: LIMITED_HOURS.value,
    then: Yup.string().required(),
  }),
  description: Yup.string().matches(REGEXP_NOSPACES, ROOM_SERVICE_DESCRIPTION),
});

const RoomService = ({ services, propertyId }) => {
  const { RangePicker } = useMemo(() => TimePicker, []);
  const service = useMemo(() => RoomsServicesService(), []);
  const windowWidth = useWindowWidth();

  const handleSubmit = ({
    description, fromTime, status, toTime,
  }) => {
    const requestModel = {
      status,
      description,
      propertyId,
    };

    if (status === LIMITED_HOURS.value) {
      requestModel.fromTime = fromTime;
      requestModel.toTime = toTime;
    }

    const filteredModel = filterObjectEmptyProperties(requestModel);

    service.editPropertysRoomService(filteredModel)
      .then(() => toastr.success(SUCCESS_SEND_MSG));
  };

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        initialValues={{
          propertyId,
          status: services.status || '',
          description: services.description || '',
          fromTime: services.fromTime || '',
          toTime: services.toTime || '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => {
          const handleTimeChange = (inputsValues) => {
            setFieldValue('fromTime', inputsValues[0]);
            setFieldValue('toTime', inputsValues[1]);
          };

          return (
            <Form>
              <div
                className={`${styles.wrapper} flex flex-v-center flex-between`}
              >
                <p className={styles.title}>Deliver food & drinks</p>
                <Field
                  name="status"
                  options={
                    windowWidth > PHONE_WIDTH_BREAKPOINT
                      ? ROOMS_SERVICES_OPTIONS
                      : ROOMS_SERVICES_OPTIONS_MOBILE
                  }
                  component={FormikRadioGroup}
                >
                  <div>
                    <p className={`${styles.labelMobile} ${styles.label}`}>
                      Time
                    </p>
                    <RangePicker
                      className={styles.picker}
                      allowClear={false}
                      format={ROOM_SERVICE_TIME_FORMAT}
                      minuteStep={5}
                      disabled={values.status !== LIMITED_HOURS.value}
                      separator="-"
                      defaultValue={[
                        moment(
                          services.fromTime || DEFAULT_FROM,
                          ROOM_SERVICE_TIME_FORMAT,
                        ),
                        moment(
                          services.toTime || DEFAULT_TO,
                          ROOM_SERVICE_TIME_FORMAT,
                        ),
                      ]}
                      order={false}
                      onChange={(_event, inputsValues) => handleTimeChange(inputsValues)}
                      suffixIcon={<IconTime className={styles.icon} />}
                    />
                  </div>
                </Field>
              </div>
              <div className={styles.label}>Comment</div>
              <Textarea name="description" placeholder="Add comment here" maxLength={MAX_ROOM_SERVICE_DESCRIPTION_LENGTH} />
              <Button
                type="submit"
                classes={`
                  btn btn_block btn-uppercased btn_common btn_auth t-600 
                  ${styles.submit}
                `}
              >
                Save Changes
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RoomService;
