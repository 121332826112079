import React from 'react';
import { navigate } from 'gatsby';
import http from '../../../services/http.service';
import ChangePasswordForm from '../../../components/ChangePasswordForm/ChangePasswordForm';
import SellerSettingsWrapper from '../SellerSettingsWrapper/SellerSettingsWrapper';
import { API } from '../../../constans/http';
import { MY_PROPERTIES_LINK } from '../../../constans/links';

const SellerPassword = () => {
  const formSubmitHandler = ({ password, newPassword }, { setSubmitting }) => {
    http.patch(API.SUPPLIERS_PASSWORD, { password, newPassword })
      .then(() => {
        navigate(MY_PROPERTIES_LINK);
      })
      .catch(() => {
        setSubmitting(false);
      //  Do nothing . . .
      });
  };

  return (
    <SellerSettingsWrapper>
      <ChangePasswordForm formSubmitHandler={formSubmitHandler} />
    </SellerSettingsWrapper>
  );
};

export default SellerPassword;
