import React from 'react';

import styles from './index.module.scss';

const OfferDetailsSection = ({
  title, value, cols, descriptionClassName,
}) => (
  <div className={`${cols} ${styles.item}`}>
    <p className={styles.title}>{title}</p>
    <p className={`${styles.value} ${descriptionClassName}`}>
      {value}
    </p>
  </div>
);

OfferDetailsSection.defaultProps = {
  cols: 'col-12 col-md-6',
};

export default OfferDetailsSection;
