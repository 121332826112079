export const facilitiesIntervalStyles = (error, touched) => ({
  control: (provided, state) => ({
    ...provided,
    zIndex: 1,
    height: '50px',
    backgroundColor: 'transparent',
    outline: 'none',
    fontWeight: '500',
    border:
      touched && !!error
        ? '1px solid #e94949'
        : state.selectProps.menuIsOpen || state.isFocused || state.isSelected
          ? '1px solid #2684ff'
          : '1px solid #dbe2ea',
    borderRadius: '0 6px 6px 0',
    boxShadow: state.selectProps.menuIsOpen || state.isFocused || state.isSelected ? '0 0 0px 1px #2684ff' : 'none',
    '&:active': {
      border: '1px solid #2684ff',
      boxShadow: '0 0 0px 1px #2684ff',
      cursor: 'pointer',
    },
    '&:hover': {
      border: '1px solid #2684ff',
      cursor: 'pointer',
      boxShadow: '0 0 0px 1px #2684ff',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '& > div': {
      padding: 0,
      marginRight: '10px',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    transition: 'all 0.3s',
    '& svg': {
      fill: '#2684ff',
      width: '16px',
      height: '16px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    transition: 'all 0.3s ease-out',
    backgroundColor: state.isFocused || state.isSelected ? 'rgba(2,170,255,0.05)' : 'transparent',
    cursor: 'pointer',
    color: (state.isFocused || state.isSelected) && 'rgba(43, 43, 43, 0.6)',
    fontSize: '16px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: 'rgba(2,170,255,0.05)',
      color: 'rgba(43, 43, 43, 0.6)',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '10px',
    borderRadius: '6px',
    border: '1px solid #dbe2ea',
    boxShadow: state.selectProps.menuIsOpen
      ? '0 20px 20px 0 rgba(44, 39, 56, 0.04), 0 4px 8px 0 rgba(44, 39, 56, 0.04)'
      : '',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '11px 0 11px 15px',
  }),
  singleValue: (_, state) => ({
    color: state.isDisabled ? 'rgba(43, 43, 43, 0.2)' : 'rgba(43, 43, 43, 0.6)',
  }),
  placeholder: (provided, state) => ({
    color: state.isDisabled ? 'rgba(43, 43, 43, 0.2)' : 'rgba(43, 43, 43, 0.4)',
    fontWeight: '500',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: '164px',
    overflow: 'auto',
  }),
});

export const facilitiesCurrencyStyles = (error, touched) => ({
  control: (provided, state) => ({
    ...provided,
    zIndex: 1,
    height: '50px',
    backgroundColor: 'transparent',
    outline: 'none',
    fontWeight: '500',
    border:
      touched && !!error
        ? '1px solid #e94949'
        : state.selectProps.menuIsOpen || state.isFocused || state.isSelected
          ? '1px solid #2684ff'
          : '1px solid #dbe2ea',
    borderRadius: '6px 0 0 6px',
    boxShadow: state.selectProps.menuIsOpen || state.isFocused || state.isSelected ? '0 0 0px 1px #2684ff' : 'none',
    '&:active': {
      border: '1px solid #2684ff',
      boxShadow: '0 0 0px 1px #2684ff',
      cursor: 'pointer',
    },
    '&:hover': {
      border: '1px solid #2684ff',
      cursor: 'pointer',
      boxShadow: '0 0 0px 1px #2684ff',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '& > div': {
      padding: 0,
      marginRight: '10px',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    transition: 'all 0.3s',
    '& svg': {
      fill: '#2684ff',
      width: '16px',
      height: '16px',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    transition: 'all 0.3s ease-out',
    backgroundColor: state.isFocused || state.isSelected ? 'rgba(2,170,255,0.05)' : 'transparent',
    cursor: 'pointer',
    color: (state.isFocused || state.isSelected) && 'rgba(43, 43, 43, 0.6)',
    fontSize: '16px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: 'rgba(2,170,255,0.05)',
      color: 'rgba(43, 43, 43, 0.6)',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '10px',
    borderRadius: '6px',
    border: '1px solid #dbe2ea',
    boxShadow: state.selectProps.menuIsOpen
      ? '0 20px 20px 0 rgba(44, 39, 56, 0.04), 0 4px 8px 0 rgba(44, 39, 56, 0.04)'
      : '',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '11px 0 11px 15px',
  }),
  singleValue: (_, state) => ({
    color: state.isDisabled ? 'rgba(43, 43, 43, 0.2)' : 'rgba(43, 43, 43, 0.6)',
  }),
  placeholder: (provided, state) => ({
    color: state.isDisabled ? 'rgba(43, 43, 43, 0.2)' : 'rgba(43, 43, 43, 0.4)',
    fontWeight: '500',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: '164px',
    overflow: 'auto',
  }),
});

export const userProfileMenuStyles = (provided, state) => ({
  ...provided,
  padding: 0,
  maxHeight: 'initial',
  overflow: 'auto',
  zIndex: state.selectProps.menuIsOpen ? 5 : 'auto',
});
