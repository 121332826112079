import React from 'react';
import { Field } from 'formik';
import { Input } from '../../../../components/UI/Input/Input';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../../components/UI/Label/Label';
import CustomSelect from '../../../../components/UI/CustomSelect/CustomSelect';
import styles from './KeyFacility.module.scss';
import { FACILITY_PRICE_REQUIRED } from '../../../../constans/error-messages';
import FormikRadioGroup from '../../../../components/RadioGroup/RadioGroup';
import {
  facilitiesCurrencyStyles,
  facilitiesIntervalStyles,
} from '../../../../constans/react-select-styles';

const KeyFacility = ({
  touched,
  errors,
  priceInputsDisabled,
  title,
  containerStyles,
  currenciesOptions,
  currencyInputName,
  currencyInputPlaceholder,
  priceInputName,
  priceInputPlaceholder,
  intervalInputName,
  intervalOptions,
  intervalInputPlaceholder,
  descriptionInputName,
  descriptionInputPlaceholder,
  radioButtonName,
  radioButtonsValues,
}) => (
  <div className={`${styles.item} ${containerStyles}`}>
    <div className={`${styles.title} t-600`}>{title}</div>
    <div className={styles.radioGroup}>
      <Field
        name={radioButtonName}
        options={radioButtonsValues}
        component={FormikRadioGroup}
      />
    </div>
    <InputWrapper
      containerClasses={`${styles.priceInputsWrapper} ${
        priceInputsDisabled ? styles.disabled : ''
      }`}
    >
      <Label>Price</Label>
      <div className={`${styles.priceInputsContainer} flex`}>
        <CustomSelect
          hideError
          customStylesFunc={facilitiesCurrencyStyles}
          containerStyles={styles.currencyInput}
          isDisabled={priceInputsDisabled}
          name={currencyInputName}
          options={currenciesOptions}
          placeholder={currencyInputPlaceholder}
        />
        <Input
          hideError
          inputWrapperStyles={styles.priceInput}
          containerStyles={styles.priceInputWrapper}
          disabled={priceInputsDisabled}
          type="number"
          name={priceInputName}
          placeholder={priceInputPlaceholder}
        />
        <CustomSelect
          hideError
          customStylesFunc={facilitiesIntervalStyles}
          containerStyles={styles.intervalInput}
          isDisabled={priceInputsDisabled}
          name={intervalInputName}
          options={intervalOptions}
          placeholder={intervalInputPlaceholder}
        />
      </div>
      {touched
        ? (errors
          ? errors.currency || errors.price || errors.interval
          : null) && (
            <div className={styles.error}>{FACILITY_PRICE_REQUIRED}</div>
        )
        : null}
    </InputWrapper>
    <InputWrapper containerClasses={styles.commentInput}>
      <Label>Comment</Label>
      <Input
        type="text"
        name={descriptionInputName}
        placeholder={descriptionInputPlaceholder}
      />
    </InputWrapper>
  </div>
);

export default KeyFacility;
