export const FEATURE_TYPES = Object.seal({
  SERVICE: 'SERVICE',
  FOOD_AND_BEVERAGES: 'FOOD_AND_BEVERAGES',
  OTHER: 'OTHER',
});

export const SERVICE = 'SERVICE';
export const FOOD_AND_BEVERAGES = 'FOOD_AND_BEVERAGES';
export const OTHER = 'OTHER';

export const FEATURES_SECTION_TITLES = {
  SERVICE: 'Service',
  FOOD_AND_BEVERAGES: 'Food & Beverages',
  OTHER: 'Other',
};
