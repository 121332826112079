import React, { useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';

import PropertiesService from '../../../services/properties.service';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import EditIcon from '../../../assets/img/icons/ic-edit-underlined.svg';
import IncludedFeatures from './IncludedFeatures/IncludedFeatures';
import PriceAndTaxes from './Price-and-taxes/Price-and-taxes';
import { OFFER_DETAILS_TAB_NAMES, OFFER_DETAILS_TAB_HEADERS } from '../../../constans/offers';
import IconMore from '../../../assets/img/icons/ic-more.svg';
import OfferCardDropdown from '../../../components/OfferCardDropdown/OfferCardDropdown';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

import styles from './OfferDetails.module.scss';
import DeleteOfferModal from '../../../components/DeleteOfferModal/DeleteOfferModal';
import { toggleBodyStylesForModal } from '../../../helpers/helper-methods';

const OfferDetails = ({ id, offerId, path }) => {
  const service = useMemo(() => PropertiesService(), []);
  const [modalShown, setModalShown] = useState(false);
  const tabs = useMemo(() => [
    GeneralInfo,
    IncludedFeatures,
    PriceAndTaxes,
  ], []);

  const [offerDetails, setOfferDetails] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const TabToDisplay = tabs[activeTab];

  const toggleDeleteModal = () => {
    toggleBodyStylesForModal();
    setModalShown((prevState) => !prevState);
  };

  const getData = async () => {
    const { data } = await service.getOfferDetails(id, offerId);

    setOfferDetails(data);
  };

  const handleDuplicate = () => service.duplicateOffer(id, offerId)
    .then(({ data }) => navigate(
      `/sellers/my-properties/${id}/offers/${data.id}/edit`,
    ));

  const handleDelete = () => service.deleteOffers(id, [offerId])
    .then(() => navigate(`/sellers/my-properties/${id}/offers`));

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container property-edit-wrapper">
      <DeleteOfferModal
        show={modalShown}
        offerId={offerId}
        onSubmit={handleDelete}
        closeModal={toggleDeleteModal}
      />
      <div className="row flex-grow-1">
        <div className="col-12">
          <Breadcrumbs path={path} id={id} offerId={offerId} />
        </div>
        <div className="col-12 col-md-12 col-lg-3">
          <div className={`${styles.nav} flex flex-grow`}>
            <div className={styles.links}>
              {OFFER_DETAILS_TAB_NAMES.map((tabName, index) => (
                <button
                  className={`
                    ${styles.link}
                    ${index === activeTab && styles.linkActive}
                  `}
                  type="button"
                  key={index}
                  onClick={() => setActiveTab(index)}
                >
                  <div className={styles.dot} />
                  {tabName}
                </button>
              ))}
            </div>
            <div className={styles.buttons}>
              <button
                type="button"
                className="btn btn-uppercased btn_inverted btn_radius-28 t-600"
                onClick={handleDuplicate}
              >
                Duplicate
              </button>
              <button
                onClick={toggleDeleteModal}
                className="btn btn-uppercased btn_radius-28 t-600"
                type="button"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-9">
          <div className={styles.wrapper}>
            <div className={`flex ${styles.sectionTitle}`}>
              {OFFER_DETAILS_TAB_HEADERS[activeTab]}
              <Link to="edit" className={`ml-auto ${styles.edit}`}>
                <EditIcon />
                Edit
              </Link>
              <div className={`${styles.moreButtonWrapper} ml-auto`}>
                <OfferCardDropdown
                  togglerClassName={styles.more}
                  togglerText={<IconMore className={styles.iconMore} />}
                >
                  <Link
                    className={styles.dropdownButton}
                    to={`/sellers/my-properties/${id}/offers/${offerId}/edit`}
                  >
                    Edit
                  </Link>
                  <div>
                    <button
                      type="button"
                      onClick={handleDuplicate}
                      className={styles.dropdownButton}
                    >
                      Duplicate
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={toggleDeleteModal}
                      className={`
                        ${styles.dropdownButtonDanger}
                        ${styles.dropdownButton}
                      `}
                    >
                      Delete
                    </button>
                  </div>
                </OfferCardDropdown>
              </div>
            </div>
            <TabToDisplay {...offerDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
