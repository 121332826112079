import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { Formik, Form } from 'formik';
import EditRoomWrapper from '../EditRoomWrapper/EditRoomWrapper';
import EditRoomFooter from '../EditRoomFooter/EditRoomFooter';
import RoomAmenities from '../RoomAmenities/RoomAmenities';
import { roomAmenitiesSchema } from '../../../../constans/validation-schemas';
import {
  getRoomGeneralAmenities,
  getRoomAmenities,
  sendRoomAmenities,
} from '../../../../services/rooms-manage.service';
import { generateMapFromListByKey } from '../../../../helpers/helper-methods';
import { SUCCESS_SEND_MSG } from '../../../../constans/texts/texts';

const EditRoomAmenities = ({ id, roomId, path }) => {
  const [initialValues, setInitialValues] = useState({
    amenities: {
      general: [],
      custom: [],
    },
  });

  const fetchData = async () => {
    const amenitiesResponse = await getRoomAmenities(roomId);
    const generalAmenitiesList = await getRoomGeneralAmenities();
    let amenitiesData = initialValues;

    if (amenitiesResponse.data.length > 0) {
      let generalAmenities = [];
      let customAmenities = [];

      generalAmenities = amenitiesResponse.data
        .filter((amenity) => amenity.isGeneral)
        .map((amenity) => ({
          title: amenity.title,
          check: amenity.isGeneral,
        }));

      let parsedAmenities = [];

      if (generalAmenities.length > 0 && generalAmenitiesList.data.length > 0) {
        const generalAmenitiesMap = generateMapFromListByKey(generalAmenities, 'title');

        parsedAmenities = generalAmenitiesList.data.map((amenity) => ({
          title: amenity.title ? amenity.title : '',
          check: generalAmenitiesMap[amenity.title]
            ? generalAmenitiesMap[amenity.title].check
            : false,
        }));
      } else if (generalAmenitiesList.data.length > 0) {
        parsedAmenities = generalAmenitiesList.data.map((amenity) => ({
          title: amenity.title ? amenity.title : '',
          check: false,
        }));
      }

      amenitiesData = {
        amenities: {
          ...amenitiesData.amenities,
          general: parsedAmenities,
        },
      };

      customAmenities = amenitiesResponse.data
        .filter((amenity) => !amenity.isGeneral)
        .map((amenity) => ({ title: amenity.title }));

      if (customAmenities.length > 0) {
        amenitiesData = {
          amenities: {
            ...amenitiesData.amenities,
            custom: customAmenities,
          },
        };
      }

      // setInitialValues(amenitiesData);
    } else {
      amenitiesData = {
        amenities: {
          ...amenitiesData.amenities,
          general: generalAmenitiesList.data.map((amenity) => ({
            title: amenity.title,
            check: false,
          })),
        },
      };
    }
    setInitialValues(amenitiesData);
  };

  const formSubmitHandler = async ({ amenities }) => {
    await sendRoomAmenities(amenities, roomId);
    toastr.success(SUCCESS_SEND_MSG);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <EditRoomWrapper
      propertyId={id}
      roomId={roomId}
      path={path}
    >
      <Formik
        enableReinitialize
        onSubmit={formSubmitHandler}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={roomAmenitiesSchema}
        initialValues={initialValues}
      >
        {({ values, errors }) => (
          <Form className="edit-from-mobile edit-form-wrapper">
            <RoomAmenities errors={errors} values={values} />
            <EditRoomFooter propertyId={id} />
          </Form>
        )}
      </Formik>
    </EditRoomWrapper>
  );
};

export default EditRoomAmenities;
