import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { Formik, Form } from 'formik';
import EditRoomWrapper from '../EditRoomWrapper/EditRoomWrapper';
import EditRoomFooter from '../EditRoomFooter/EditRoomFooter';
import BedSettings from '../BedSettings/BedSettings';
import { roomBedConfigSchema } from '../../../../constans/validation-schemas';
import {
  getRoomBedSettings,
  sendBedSettings,
} from '../../../../services/rooms-manage.service';
import { SUCCESS_SEND_MSG } from '../../../../constans/texts/texts';
import { ROOM_BED_CONFIG_MIN } from '../../../../constans/validation';

const EditRoomBedSettings = ({ id, roomId, path }) => {
  const [initialValues, setInitialValues] = useState({
    bedConfigurations: [{
      title: 'Configuration #1',
      amount: ROOM_BED_CONFIG_MIN,
      bedTypes: [
        {
          title: '',
          bedWidth: '',
        },
      ],
    }],
  });

  const fetchData = () => {
    getRoomBedSettings(roomId).then(({ data }) => {
      const bedSettingsData = data.map((bedSetting) => ({
        title: bedSetting.title,
        amount: bedSetting.amount,
        bedTypes: bedSetting.bedTypes.map((bedType) => ({
          title: bedType.title ? bedType.title : '',
          bedWidth: bedType.bedWidth ? bedType.bedWidth : '',
        })),
      }));
      setInitialValues(({
        bedConfigurations: bedSettingsData,
      }));
    });
  };

  const formSubmitHandler = async ({ bedConfigurations }) => {
    await sendBedSettings(bedConfigurations, roomId);
    toastr.success(SUCCESS_SEND_MSG);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <EditRoomWrapper
      propertyId={id}
      roomId={roomId}
      path={path}
    >
      <Formik
        enableReinitialize
        onSubmit={formSubmitHandler}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={roomBedConfigSchema}
        initialValues={initialValues}
      >
        {({ values, errors }) => (
          <Form className="edit-from-mobile edit-form-wrapper">
            <BedSettings errors={errors} values={values} />
            <EditRoomFooter propertyId={id} />
          </Form>
        )}
      </Formik>
    </EditRoomWrapper>
  );
};

export default EditRoomBedSettings;
