import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { Formik, Form } from 'formik';
import RoomPhotos from '../RoomPhotos/RoomPhotos';
import EditRoomWrapper from '../EditRoomWrapper/EditRoomWrapper';
import EditRoomFooter from '../EditRoomFooter/EditRoomFooter';
import { roomEditPhotosSchema } from '../../../../constans/validation-schemas';
import {
  getRoomPhotos,
  sendRoomPhotos,
} from '../../../../services/rooms-manage.service';
import { SUCCESS_SEND_MSG } from '../../../../constans/texts/texts';
import { IMG_UPLOAD_FAILED, ROOMS_PHOTOS_MIN } from '../../../../constans/error-messages';

const EditRoomPhotos = ({ id, roomId, path }) => {
  const [initialValues, setInitialValues] = useState({
    photos: [],
  });

  const fetchData = () => {
    getRoomPhotos(roomId).then(({ data }) => {
      setInitialValues({
        photos: data.map(({ file, id: itemId }) => ({
          file,
          id: itemId,
        })),
      });
    });
  };

  const formSubmitHandler = async (values, { setFieldError }) => {
    try {
      const newImages = values.photos.filter(({ id: imageId }) => !imageId);
      if (newImages.length) {
        await sendRoomPhotos(newImages, roomId);
        toastr.success(SUCCESS_SEND_MSG);
      } else {
        setFieldError('photos', ROOMS_PHOTOS_MIN);
      }
    } catch (e) {
      toastr.error(IMG_UPLOAD_FAILED);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <EditRoomWrapper
      propertyId={id}
      roomId={roomId}
      path={path}
    >
      <Formik
        enableReinitialize
        onSubmit={formSubmitHandler}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={roomEditPhotosSchema}
        initialValues={initialValues}
      >
        {({ values, errors, setFieldError }) => (
          <Form className="edit-from-mobile edit-form-wrapper">
            <RoomPhotos
              setFieldError={setFieldError}
              roomId={roomId}
              values={values}
              errors={errors}
            />
            <EditRoomFooter propertyId={id} />
          </Form>
        )}
      </Formik>
    </EditRoomWrapper>
  );
};

export default EditRoomPhotos;
