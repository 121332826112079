import React from 'react';

import Modal from '../UI/Modal/Modal';
import styles from './DeleteOfferModal.module.scss';

const DeleteOfferModal = ({
  onSubmit, show, offerId, closeModal, multiple,
}) => (
  <Modal
    showClose={false}
    displayToggle={closeModal}
    show={show}
    modalClasses={styles.modal}
  >
    <h1 className={styles.modalTitle}>
      {`Are you sure? This offer${multiple ? 's' : ''} will be permanently deleted.`}
    </h1>

    <div
      className={`
        ${styles.modalFooter}
        flex flex-between
      `}
    >
      <button
        type="button"
        onClick={closeModal}
        className={`
          ${styles.modalCancel}
          ${styles.modalButton}
          btn btn-uppercased btn_block btn_bordered btn_radius-28 t-700
        `}
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={() => onSubmit(offerId)}
        className={`
          ${styles.modalSubmit}
          ${styles.modalButton}
          btn btn-uppercased btn_block btn_radius-28 btn_danger-bg t-600
        `}
      >
        Delete
      </button>
    </div>
  </Modal>
);

export default DeleteOfferModal;
