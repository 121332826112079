import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

import styles from './index.module.scss';

const Dropdown = ({
  togglerText,
  children,
  togglerClassName,
  activeTogglerClassName,
  dropdownClassName,
}) => {
  const [visible, setVisibility] = useState(false);

  const handleDropdownClick = () => {
    setVisibility(!visible);
  };

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <button
            className={`
              ${togglerClassName}
              ${visible && activeTogglerClassName}
            `}
            type="button"
            ref={ref}
            onClick={handleDropdownClick}
          >
            {togglerText}
          </button>
        )}
      </Reference>
      <Popper placement="top" position="top">
        {({ ref, style }) => (
          <div
            ref={ref}
            style={{
              ...style,
              zIndex: visible ? '1' : '-999',
              opacity: visible ? '1' : '0',
            }}
            className={`
              ${styles.dropdownContent}
              ${dropdownClassName}
              ${visible && activeTogglerClassName}
            `}
          >
            {children}
          </div>
        )}
      </Popper>
    </Manager>
  );
};

Dropdown.propTypes = {
  togglerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  togglerClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  activeTogglerClassName: PropTypes.string,
};

Dropdown.defaultProps = {
  togglerText: 'Dropdown',
  togglerClassName: '',
  dropdownClassName: '',
  activeTogglerClassName: '',
};

export default Dropdown;
