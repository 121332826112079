import React from 'react';
import { Link } from 'gatsby';

import SideNav from '../../../components/SideNav/SideNav';
import PlusIcon from '../../../assets/img/icons/plus.svg';
import PropertyEditRoomItem from '../../../components/RoomItem/PropertyEditRoomItem/PropertyEditRoomItem';
import Pagination from '../../../components/Pagination';
import useRoomListDataHandling from '../../../hooks/useRoomListDataHandling';
import { ROOM_LIMIT } from '../../../constans/pagination';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { MAX_ROOMS } from '../../../constans/validation';

import styles from './Rooms.module.scss';

const Rooms = ({ id, location, path }) => {
  const [
    roomsList,
    pageNumber,
    total,
    getRooms,
    checkForUpdateCurrentPage,
  ] = useRoomListDataHandling(id);

  return (
    <div className="pt-0 container property-edit-wrapper flex">
      <div className="row flex-grow-1">
        <div className="col-12">
          <Breadcrumbs
            path={path}
            id={id}
          />
        </div>
        <SideNav links={PROPERTY_EDIT_NAV} propertyId={id} />
        <div className="col-12 col-lg-9">
          <div className="edit-form-wrapper edit-from-mobile">
            <div className={`${styles.title} property-edit-title`}>
              <span>
                Room
                {total > 1 && 's'}
              </span>
              <Link
                to={`${location.pathname}/add-room`}
                state={{ prevPath: location.pathname }}
                className={`${total >= MAX_ROOMS ? 'disabled-link' : ''} edit-add-btn t-600 btn btn_bordered br-17 flex flex-center t-600`}
              >
                <PlusIcon />
                <span>Add new</span>
              </Link>
            </div>
            {roomsList && (
              <>
                <div className={styles.listWrapper}>
                  {roomsList.map((roomItem, index) => (
                    <PropertyEditRoomItem
                      checkForPaginUpdate={checkForUpdateCurrentPage}
                      // nextOffsetPage={nextOffsetPage}
                      // totalCount={total}
                      pageNumber={pageNumber}
                      getRooms={getRooms}
                      roomId={roomItem.id}
                      capacitiesObj={roomItem.capacitiesObj}
                      itemStyles={styles.roomItem}
                      key={`xlne${index}`}
                      editUrl={`/sellers/my-properties/${id}/rooms/${roomItem.id}/edit/general-info`}
                      title={roomItem.title}
                      description={roomItem.description}
                      index={index + 1}
                      amenities={roomItem.amenities}
                      photos={roomItem.photos}
                      surface={roomItem.surface}
                    />
                  ))}
                </div>
                {total > ROOM_LIMIT ? (
                  <Pagination
                    perPage={ROOM_LIMIT}
                    forcePage={pageNumber}
                    total={total}
                    callback={getRooms}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rooms;
