import React, { useState } from 'react';
import { Link } from 'gatsby';
import RoomItemSlider from '../RoomItemSlider/RoomItemSlider';
import RoomCapacityItem from '../RoomCapacityItem/RoomCapacityItem';
import AmenitiesBtn from '../AmenitiesBtn/AmenitiesBtn';
import AmenitiesModal from '../AmenitiesModal/AmenitiesModal';
import RoomItemTitle from '../RoomItemTitle/RoomItemTitle';
import RoomItemDescr from '../RoomItemDescr/RoomItemDescr';
import RoomCapacityList from '../RoomCapacityList/RoomCapacityList';
import styles from './PropertyDetailRoomItem.module.scss';
import EditIcon from '../../../assets/img/icons/ic-edit-default.svg';
import { toggleBodyStylesForModal } from '../../../helpers/helper-methods';
import http from '../../../services/http.service';
import RemoveModal from '../../UI/RemoveModal/RemoveModal';

const PropertyDetailRoomItem = ({
  roomId,
  editUrl,
  capacitiesObj,
  title,
  description,
  index,
  amenities,
  photos,
  surface,
  itemStyles,
  bedTypes,
  // getRooms,
  // pageNumber,
  // totalCount,
  // nextOffsetPage,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const toggleModal = () => {
    toggleBodyStylesForModal();
    setDisplayModal(!displayModal);
  };

  const removeRoomHandler = () => {
    http.delete(`/rooms/${roomId}`)
      .then(() => window.location.reload());
  };

  return (
    <div className={itemStyles}>
      <div
        className={`
          ${styles.actionBtnsWrapper} 
          flex flex-v-center
        `}
      >
         <Link
          to={editUrl}
          className={`
            ${styles.editBtn}
            edit-add-btn edit-add-btn-edit-icon t-600 btn btn_bordered br-17 flex flex-center
          `}
        >
          <EditIcon />
          <span>Edit</span>
        </Link>
        <RemoveModal
          showRemove
          modalWrapperClasses={styles.removeModalWrapper}
          removeIconClasses={`${styles.actionBtn} ${styles.removeBtn}`}
          modalSubtitle="Do you want to remove this item?"
          modalSubmit={removeRoomHandler}
        />
      </div>
      <div className={`${styles.mainContentWrapper} flex`}>
        <RoomItemSlider
          wrapperStyles={styles.sliderWrapper}
          slideStyles={styles.slideItem}
          photosArr={photos}
        />
        <div className="flex flex-col flex-grow-1">
          <RoomItemTitle containerStyles={`${styles.title} t-700`}>
            #
            {index}
            <span className={styles.titleDelimiter}>-</span>
            {title}
          </RoomItemTitle>
          <RoomItemDescr containerStyles={`${styles.description} t-500`}>{description}</RoomItemDescr>
          <RoomCapacityList>
            {Object.keys(capacitiesObj).map((capacityKey, indexStep) => (
              <RoomCapacityItem
                key={`sdlkfj${indexStep}`}
                containerStyles={styles.capacityItem}
                title={capacitiesObj[capacityKey].cardLabel}
              >
                {capacitiesObj[capacityKey].values.map((capacityValues, innerIndex) => (
                  <span
                    key={`sdfd${innerIndex}`}
                  >
                    {capacityValues.value}
                    {capacityValues.prefix}
                  </span>
                ))}
              </RoomCapacityItem>
            ))}
            {amenities?.length || capacitiesObj || surface || bedTypes ? (
              <>
                <AmenitiesBtn
                  onClick={toggleModal}
                  // amenitiesLength={amenities.length}
                />
                <AmenitiesModal
                  surface={surface}
                  showModal={displayModal}
                  toggleModal={toggleModal}
                  amenities={amenities}
                  capacitiesObj={capacitiesObj}
                  bedTypesArr={bedTypes}
                />
              </>
            ) : null}
          </RoomCapacityList>
        </div>
      </div>
    </div>
  );
};

PropertyDetailRoomItem.defaultProps = {
  itemStyles: '',
};

export default PropertyDetailRoomItem;
