import http from './http.service';
import { defaultParamsSerializer } from '../helpers/helper-methods';

const RestaurantsService = () => {
  const basePath = '/restaurants';

  const getRestaurantsByPropertyId = (model) => http.get(basePath, {
    params: model,
    paramsSerializer: (params) => defaultParamsSerializer(params),
  });

  const createRestaurant = (model) => http.post(basePath, { ...model });

  const deleteRestaurant = (id) => http.delete(`${basePath}/${id}`);

  const editRestaurant = (id, model) => http.patch(`${basePath}/${id}`, { ...model });

  const addRestaurantPhotos = (id, model) => http.post(`${basePath}/${id}/photos`, { photos: [...model] });

  const deleteRestaurantPhotos = (id, model) => http.delete(`${basePath}/${id}/photos`, { data: { ...model } });

  return {
    getRestaurantsByPropertyId,
    createRestaurant,
    deleteRestaurant,
    editRestaurant,
    addRestaurantPhotos,
    deleteRestaurantPhotos,
  };
};

export default RestaurantsService;
