import React from 'react';
import SortIcon from '../assets/img/icons/ic_sort.svg';

export const TABLE_LIMIT = 20;
export const SEARCH_MIN_LENGTH = 2;

export const BOOKING_TABLE = {
  columns: [
    {
      Header: (rowData) => (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Booking ref #</span>
          {rowData.column.sortable ? <SortIcon /> : null}
        </div>
      ),
      accessor: 'bookingRef',
      width: 168,
      sortable: false,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Guest name row</span>
          <SortIcon />
        </div>
      ),
      accessor: 'username',
      width: 190,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Check-In - Check-Out date</span>
          <SortIcon className="table-header-sort-icon" />
        </div>
      ),
      accessor: 'dates',
      minWidth: 215,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Room type</span>
          <SortIcon />
        </div>
      ),
      accessor: 'roomType',
      width: 190,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Guests</span>
          <SortIcon />
        </div>
      ),
      accessor: 'numberOfGuests',
      width: 156,
    },
    {
      Header: (rowData) => (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Payment Status</span>
          {rowData.column.sortable ? <SortIcon /> : null}
        </div>
      ),
      accessor: 'status',
      width: 140,
      sortable: false,
    },
  ],
  data: [],
};

export const PAYMENT_TABLE = {
  columns: [
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Property name</span>
          <SortIcon />
        </div>
      ),
      accessor: 'propertyName',
      width: 350,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Offer ID</span>
          <SortIcon />
        </div>
      ),
      accessor: 'offerId',
      width: 150,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Customer</span>
          <SortIcon className="table-header-sort-icon" />
        </div>
      ),
      accessor: 'customer',
      id: 'customerName',
      width: 250,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Amount</span>
          <SortIcon />
        </div>
      ),
      accessor: 'amount',
      width: 130,
    },
    {
      Header: (
        <div className="th-inner flex flex-v-center">
          <span className="table-header-text text-truncate t-500">Payment Status</span>
          <SortIcon />
        </div>
      ),
      accessor: 'status',
      width: 140,
    },
  ],
  data: [],
};
