import React, { useState } from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';

import http from '../../services/http.service';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PropertyDetailsHeader from '../../components/PropertyDetails/PropertyDetailsHeader/PropertyDetailsHeader';
import Table from '../../components/Table/Table';
import SearchInput from '../../components/Search';
import TableContentWrapper from '../../components/Table/TableContentWrapper/TableContentWrapper';
import { BOOKING_TABLE, TABLE_LIMIT } from '../../constans/tables';
import styles from './PropertyBookings.module.scss';
import { DEFAULT_FORMAT } from '../../constans/formats';
import { API } from '../../constans/http';
import { SORT_ORDERS } from '../../constans/sort';
import { BOOKING_STATUSES_MAP } from '../../constans/bookings';
import { EMPTY } from '../../constans/texts/texts';

const PropertyBookings = ({
  id,
  path,
}) => {
  const [tableData, setTableData] = useState(BOOKING_TABLE.data);
  const [searchQuery, setSearchQuery] = useState('');
  const [paginState, setPaginState] = useState(-1);

  const getBookingDetails = (rowInfo) => {
    navigate(`/sellers/my-properties/${id}/bookings/${rowInfo.original.id}`);
  };

  const parseBookingData = (data) => {
    const adultPrefix = 'A';
    const kidsPrefix = 'K';
    const parsedBookingData = data.data.map((bookItem) => ({
      id: bookItem.id,
      bookingRef: bookItem?.offer?.code,
      username: <span className="text-truncate">{`${bookItem.firstName || ''} ${bookItem.lastName || ''}`}</span>,
      dates: `${moment(bookItem.startDate).format(DEFAULT_FORMAT)} - ${moment(bookItem.endDate).format(DEFAULT_FORMAT)}`,
      roomType: bookItem?.offer?.room?.title,
      numberOfGuests: `${bookItem?.numberOfAdults ? bookItem.numberOfAdults + adultPrefix : ''}${bookItem?.numberOfKids ? ' + ' : ' '}${bookItem?.numberOfKids ? bookItem.numberOfKids + kidsPrefix : ''}`,
      status: bookItem?.status ? <div className={`${BOOKING_STATUSES_MAP[bookItem.status].background} status-item text-center`}>{BOOKING_STATUSES_MAP[bookItem.status].text}</div> : EMPTY,
    }));
    setPaginState(data.pagination.totalCount);
    setTableData(parsedBookingData);
  };

  const getBookingData = (model) => http.get(`${API.PROPERTIES_BASE}/${id}/bookings`, { params: model });

  // Add sort state
  const fetchBookingData = (tableState, instance) => getBookingData({
    limit: TABLE_LIMIT,
    offset: (tableState?.page || 0) * TABLE_LIMIT,
    searchString: instance?.props?.search || null,
    orderBy: tableState.sorted?.[0]?.id || null,
    orderType: tableState.sorted?.[0]
      ? tableState.sorted[0].desc
        ? SORT_ORDERS.DESC
        : SORT_ORDERS.ASC
      : null,
  })
    .then((data) => {
      parseBookingData(data);
    });

  return (
    <div className={`${styles.mainWrapper} pt-0 container property-edit-wrapper`}>
      <Breadcrumbs
        path={path}
        id={id}
      />
      <PropertyDetailsHeader id={id} />
      <TableContentWrapper>
        <div className={`${styles.header} flex flex-h-end flex-v-center`}>
          <div className={`${styles.title} block-title`}>List of Bookings</div>
          <div className={`${styles.desktopHeaderWrapper} flex`}>
            <SearchInput
              closeBtnStyles={styles.closeBtn}
              inputStyles={styles.searchInput}
              searchBtnStyles={styles.searchBtn}
              containerStyles={styles.searchWrapper}
              placeholder="Search"
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              value={searchQuery}
              type="text"
            />
          </div>
          <div className={styles.mobileHeaderWrapper}>
            <div className={`${styles.titleMobile} block-title`}>List of Bookings</div>
            <SearchInput
              closeBtnStyles={styles.closeBtn}
              inputStyles={styles.searchInput}
              searchBtnStyles={styles.searchBtn}
              containerStyles={styles.searchWrapper}
              placeholder="Search"
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              value={searchQuery}
              type="text"
            />
          </div>
        </div>
        <Table
          searchQuery={searchQuery}
          fetchDataHandler={fetchBookingData}
          defaultPageSize={TABLE_LIMIT}
          rowClickHandler={getBookingDetails}
          paginState={paginState}
          tableData={tableData}
          columns={BOOKING_TABLE.columns}
        />
      </TableContentWrapper>
    </div>
  );
};

export default PropertyBookings;
