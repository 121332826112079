import React from 'react';
import { Router, Redirect } from '@reach/router';

import CreateOffer from '../../views/offers/create-offer/CreateOffer';
import MyProperties from '../../views/properties';
import SellersPayments from '../../views/payments';
// import SellerSettings from '../../views/seller-admin/SellerSettings';
import SellerPassword from '../../views/seller-admin/SellerPassword/SellerPassword';
import SellerPaymentAccount from '../../views/seller-admin/SellerPaymentAccount/SellerPaymentAccount';
import SellerStripeRefresh from '../../views/seller-admin/SellerStripeRefresh/SellerStripeRefresh';
import PropertyDetails from '../../views/property-details';
import GeneralInfo from '../../views/property-edit/GeneralInfo/GeneralInfo';
import TermsConditions from '../../views/property-edit/TermsConditions/TermsConditions';
import LocationsTransport from '../../views/property-edit/LocationsTransport/LocationsTransport';
import Facilities from '../../views/property-edit/Facilities/Facilities';
import ProtectedRoute from '../../components/ProtectedRoute';
import Layout from '../../components/Layout/Layout';
import { SELLER_NAVBAR } from '../../constans/links';
import StorageService from '../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import FoodAndBeverages from '../../views/property-edit/FoodAndBeverages/FoodAndBeverages';
import PropertyPhotos from '../../views/property-edit/PropertyPhotos/PropertyPhotos';
import Rooms from '../../views/property-edit/Rooms/Rooms';
import EditGeneralInfo from '../../views/property-edit/Rooms/EditGeneralInfo/EditGeneralInfo';
import EditRoomPhotos from '../../views/property-edit/Rooms/EditRoomPhotos/EditRoomPhotos';
import EditRoomBedSettings from '../../views/property-edit/Rooms/EditRoomBedSettings/EditRoomBedSettings';
import EditRoomAmenities from '../../views/property-edit/Rooms/EditRoomAmenities/EditRoomAmenities';
import AddRoom from '../../views/property-edit/Rooms/AddRoom/AddRoom';
import RoomsList from '../../views/property-rooms/RoomsList';
import Offers from '../../views/offers/Offers';
import OfferDetails from '../../views/offers/offer-details/OfferDetails';
import SEO from '../../components/Seo';
import EditOffer from '../../views/offers/edit-offer/EditOffer';
import PropertyBookings from '../../views/property-bookings/PropertyBookings';
import PropertyBookingItem from '../../components/PropertyBookingItem/PropertyBookingItem';
import SellerPropertyReview from '../../views/property-review';

const getUser = () => StorageService().get(LOCALSTORAGE_KEYS.USER);

const Sellers = () => (
  <>
    <SEO title="Koralgo" />
    <Layout
      noTopNav
      userName={`${getUser()?.nickName || getUser()?.firstName + " " + getUser()?.lastName}`}
      navigation={SELLER_NAVBAR}
    >
      <Router basepath="/sellers">
        <ProtectedRoute
          path="/my-properties/:id/bookings/:bookId"
          component={PropertyBookingItem}
        />
        <ProtectedRoute
          path="/my-properties/:id/bookings"
          component={PropertyBookings}
        />
        <ProtectedRoute
          path="/my-properties/:id/rooms/:roomId/edit/room-amenities"
          component={EditRoomAmenities}
        />
        <ProtectedRoute
          path="/my-properties/:id/rooms/:roomId/edit/bed-settings"
          component={EditRoomBedSettings}
        />
        <ProtectedRoute
          path="/my-properties/:id/rooms/:roomId/edit/room-photos"
          component={EditRoomPhotos}
        />
        <ProtectedRoute
          path="/my-properties/:id/rooms/:roomId/edit/general-info"
          component={EditGeneralInfo}
        />
        <ProtectedRoute
          path="/my-properties/:id/edit/rooms/add-room"
          component={AddRoom}
        />
        <ProtectedRoute
          path="/my-properties/:id/edit/rooms"
          component={Rooms}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/review"
          component={SellerPropertyReview}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/offers"
          component={Offers}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/offers/:offerId"
          component={OfferDetails}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/offers/:offerId/edit"
          component={EditOffer}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/offers/create-offer"
          component={CreateOffer}
        />
        <ProtectedRoute
          path="/my-properties/:id/edit/facilities"
          component={Facilities}
        />
        <ProtectedRoute
          path="/my-properties/:id/edit/location-transports"
          component={LocationsTransport}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/edit/property-photos"
          component={PropertyPhotos}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/edit/food-beverages"
          component={FoodAndBeverages}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/edit/general-info"
          component={GeneralInfo}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/edit/terms-conditions"
          component={TermsConditions}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id/rooms"
          component={RoomsList}
        />
        <ProtectedRoute
          exact
          path="/my-properties/:id"
          component={PropertyDetails}
        />
        <ProtectedRoute exact path="/my-properties" component={MyProperties} />
        <ProtectedRoute exact path="/payments" component={SellersPayments} />
        <ProtectedRoute path="/admin-password" component={SellerPassword} />
        <ProtectedRoute path="/admin-payment/return" component={SellerPaymentAccount} />
        <ProtectedRoute path="/admin-payment/reauth" component={SellerStripeRefresh} />
        <Redirect default noThrow from="*" to="/sellers/my-properties" />
      </Router>
    </Layout>
  </>
);

export default Sellers;
