import React from 'react';
import { FieldArray } from 'formik';

import Button from '../../../../components/UI/Button/Button';
import PlusIcon from '../../../../assets/img/icons/plus.svg';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import { Input } from '../../../../components/UI/Input/Input';
import ExpandableBlock from '../../../../components/ExpandableBlock/ExpandableBlock';
import BedSettingsHeader from './BedSettingsHeader/BedSettingsHeader';
import RemoveModal from '../../../../components/UI/RemoveModal/RemoveModal';
import CustomSelect from '../../../../components/UI/CustomSelect/CustomSelect';
import Label from '../../../../components/UI/Label/Label';
import DeleteIcon from '../../../../assets/img/icons/ic-delete-default.svg';
import { BED_AMOUNT_MAX, ROOM_BED_CONFIG_MIN } from '../../../../constans/validation';
import { ROOM_BED_SETTINGS } from '../../../../constans/form-fields';
import { parseFormikIntValue } from '../../../../helpers/helper-methods';

import styles from './BedSettings.module.scss';

const BedSettings = ({
  _id, values, errors,
}) => (
  <FieldArray
    name="bedConfigurations"
    render={(arrayHelpers) => (
      <>
        <div className="property-edit-title property-edit-title__with-button">
          <span>Bed Settings</span>
          <Button
            disabled={values.bedConfigurations?.length >= BED_AMOUNT_MAX}
            onClick={() => arrayHelpers.push({
              title: `Configuration #${values.bedConfigurations.length + 1}`,
              amount: ROOM_BED_CONFIG_MIN,
              bedTypes: [{
                title: '',
                bedWidth: '',
              }],
            })}
            classes="edit-add-btn t-600 btn btn_bordered br-17 flex flex-center"
            type="button"
          >
            <PlusIcon />
            <span>Add configuration</span>
          </Button>
        </div>
        {values.bedConfigurations
          && values.bedConfigurations.map((bedItem, index) => (
            <ExpandableBlock
              isOpen
              errored={
                !!errors.bedConfigurations?.[index]?.bedTypes
              }
              key={`olpp${index}`}
              header={(
                <BedSettingsHeader
                  containerStyles="flex-grow-1"
                >
                  <span>
                    Configuration #
                    {index + 1}
                  </span>
                  {values.bedConfigurations.length > 1 && (
                    <RemoveModal
                      showRemove
                      modalWrapperClasses={styles.configRemoveModal}
                      modalTitle="Are you sure? This offer will be permanently deleted."
                      titleClassName={styles.configRemoveTitle}
                      removeIconClasses={styles.configRemoveIcon}
                      modalSubmit={() => arrayHelpers.remove(index)}
                    />
                  )}
                </BedSettingsHeader>
              )}
            >
              <FieldArray
                name={`bedConfigurations.${index}.bedTypes`}
                render={(arrayHelpersInner) => {
                  const isAddMoreDisabled = values.bedConfigurations[index]
                    .bedTypes.length >= BED_AMOUNT_MAX;

                  return (
                    <div className={`${styles.configContentWrapper} flex flex-col flex-h-start`}>
                      <Button
                        disabled={isAddMoreDisabled}
                        classes={`${styles.addBedTypeBtn} edit-add-btn t-600 btn btn_bordered br-17 flex flex-center`}
                        type="button"
                        onClick={() => arrayHelpersInner.push({
                          title: '',
                          bedWidth: '',
                        })}
                      >
                        <PlusIcon />
                        <span>Add bed type</span>
                      </Button>
                      {values.bedConfigurations[index].bedTypes
                        && values.bedConfigurations[index].bedTypes
                          .map((badType, innerIndex) => (
                            <div
                              className={`
                                    ${styles.bedTypeContainer} 
                                    flex flex-h-start
                                    ${values.bedConfigurations[index].bedTypes.length > 1 ? styles.removable : ''}
                                  `}
                              key={`xfgkj${innerIndex}`}
                            >
                              <InputWrapper containerClasses={styles.bedTypeSelect}>
                                <Label>Type of bed</Label>
                                <CustomSelect
                                  options={ROOM_BED_SETTINGS.bedTypes.values}
                                  placeholder={ROOM_BED_SETTINGS.bedTypes.placeholder}
                                  name={`bedConfigurations[${index}].bedTypes[${innerIndex}].${ROOM_BED_SETTINGS.bedTypes.name}`}
                                />
                              </InputWrapper>
                              <InputWrapper containerClasses={styles.bedTypeWidth}>
                                <Label>Bed width (cm)</Label>
                                <Input
                                  customHandler={parseFormikIntValue}
                                  type="number"
                                  step="1"
                                  name={`bedConfigurations[${index}].bedTypes[${innerIndex}].${ROOM_BED_SETTINGS.bedWidth.name}`}
                                  placeholder={ROOM_BED_SETTINGS.bedWidth.placeholder}
                                />
                              </InputWrapper>
                              {values.bedConfigurations[index].bedTypes.length > 1 && (
                                <DeleteIcon
                                  onClick={() => arrayHelpersInner.remove(innerIndex)}
                                  className={`${styles.bedTypeRemoveIcon} remove-icon`}
                                />
                              )}
                            </div>
                          ))}
                    </div>
                  );
                }}
              />
            </ExpandableBlock>
          ))}
      </>
    )}
  />
);

export default BedSettings;
