/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import CloseIcon from '../../assets/img/icons/close.svg';

import styles from './DeletableImage.module.scss';

const DeletableImage = ({
  src,
  alt,
  onDeletePhoto,
  className,
  children,
  onPhotoClick,
}) => (
  <div className={`${className} ${styles.wrapper}`}>
    <img onClick={onPhotoClick} src={src} alt={alt} />
    <button
      title="Delete"
      onClick={onDeletePhoto}
      className={styles.delete}
      type="button"
    >
      <CloseIcon />
    </button>
    {children}
  </div>
);

export default DeletableImage;
