import React, { useMemo, useState } from 'react';

import PaymentsService from '../../services/payments.service';
import SellerHeader from '../../components/SellerHeader';
import TableContentWrapper from '../../components/Table/TableContentWrapper/TableContentWrapper';
import Table from '../../components/Table/Table';
import { PAYMENT_TABLE, TABLE_LIMIT } from '../../constans/tables';
import { PAYMENT_STATUS, PAYMENT_STATUS_MAP } from '../../constans/payments';

import styles from './index.module.scss';
import { SORT_ORDERS } from '../../constans/sort';

const SellersPayments = () => {
  const paymentsService = useMemo(() => PaymentsService(), []);

  const [tableData, setTableData] = useState(PAYMENT_TABLE);
  const [paginationState, setPaginationState] = useState(-1);

  const transformData = (data) => {
    const transformedData = data
      .map(({
        status,
        amount,
        booking: {
          user: {
            firstName,
            lastName,
          } = {},
          offer: {
            id: offerId,
            property: {
              title: propertyName,
            } = {},
          } = {},
        },
      }) => ({
        offerId,
        status: <div className={`status-item ${PAYMENT_STATUS_MAP[status]?.background}`}>{PAYMENT_STATUS_MAP[status]?.text}</div>,
        amount: `$ ${status === PAYMENT_STATUS.REFUNDED ? 0 - amount : amount}`,
        propertyName: <span title={propertyName} className="text-truncate">{propertyName}</span>,
        customer: <span className="text-truncate" title={`${firstName} ${lastName}`}>{`${firstName} ${lastName}`}</span>,
      }));

    return transformedData;
  };

  const fetchPaymentsList = async ({ page, sorted }) => {
    const sorting = {
      orderBy: undefined,
      orderType: undefined,
    };

    if (sorted?.[0]?.id) {
      sorting.orderBy = sorted?.[0]?.id;
      sorting.orderType = sorted?.[0]?.desc ? SORT_ORDERS.DESC : SORT_ORDERS.ASC;
    }

    const { data, pagination } = await paymentsService.getPaymentsList({
      limit: TABLE_LIMIT,
      offset: (page || 0) * TABLE_LIMIT,
      ...sorting,
    });

    setPaginationState(pagination?.totalCount);
    setTableData({ PAYMENT_TABLE, data: transformData(data) });
  };

  //     if (searchQuery?.length > 2 || !searchQuery) {
  return (
    <>
      <SellerHeader />
      <div className="container property-edit-wrapper">
        <TableContentWrapper>
          <div className={`${styles.title} h5-title`}>Payments received</div>
          <Table
            defaultPageSize={TABLE_LIMIT}
            fetchDataHandler={fetchPaymentsList}
            // rowClickHandler={getBookingDetails}
            paginState={paginationState}
            tableData={tableData.data}
            columns={tableData.columns}
          />
        </TableContentWrapper>
      </div>
    </>
  );
};

export default SellersPayments;
