import React from 'react';

import styles from './IncludedFeatures.module.scss';

const IncludedFeatures = ({ features, maxNumberOfGuests }) => (
  <ul className={styles.list}>
    {features?.map(({ title, maxNumberOfGuests: maxGuests }, index) => (
      <li key={index}>
        <p>
          {title}
          {maxGuests && (
            <span>
              {maxGuests === maxNumberOfGuests
                ? ' - for all guests'
                : ` - ${maxGuests}`}
            </span>
          )}
        </p>
      </li>
    ))}
  </ul>
);

export default IncludedFeatures;
