import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import { Input } from '../Input/Input';
import Label from '../Label/Label';
import styles from './CheckboxBlock.module.scss';

const CheckboxBlock = ({
  checkBoxName,
  inputName,
  commentName,
  checkBoxContainer,
  checkboxMarkClasses,
  containerClasses,
  containerInnerClasses,
  labelClasses,
  titleValue,
  titleClasses,
  withComment,
  commentDisabled,
}) => (
  <div className={containerClasses}>
    <div className={containerInnerClasses}>
      <Checkbox
        containerClasses={`${checkBoxContainer}`}
        checkboxClasses={`${checkboxMarkClasses} c-custom__check-bordered-primary c-custom__check-md`}
        labelClasses={`${styles.checkboxLabel} ${labelClasses} flex-inline flex-v-center`}
        name={checkBoxName}
      >
        <Input
          containerStyles={styles.checkBoxTitle}
          readOnly
          asTitle
          type="hidden"
          name={inputName}
        />
        <span className={`${styles.checkBoxTitle} ${titleClasses} t-500`}>{titleValue}</span>
      </Checkbox>
    </div>
    {withComment && (
      <>
        <Label>Comment</Label>
        <Input disabled={commentDisabled} type="text" name={commentName} placeholder="Add comment here" />
      </>
    )}
  </div>
);

CheckboxBlock.defaultProps = {
  checkBoxContainer: '',
  containerClasses: '',
  checkboxMarkClasses: '',
  containerInnerClasses: '',
  titleValue: '',
  labelClasses: '',
  withComment: true,
};

export default CheckboxBlock;
