import React from 'react';
import { Link } from 'gatsby';
import Button from '../../../../components/UI/Button/Button';

const EditRoomFooter = ({ propertyId }) => (
  <div className="room-edit-footer flex flex-h-end">
    <Link
      to={`/sellers/my-properties/${propertyId}/edit/rooms`}
      className="btn btn_room-md flex flex-center btn-uppercased btn_brs-28 btn_padded btn_bordered cancel-btn t-700"
    >
      <span className="btn_text">
        Cancel
      </span>
    </Link>
    <Button
      type="submit"
      classes="btn btn_room-md btn-uppercased btn_common submit-btn t-700"
    >
      <span className="btn_text">
        Save Changes
      </span>
    </Button>
  </div>
);

export default EditRoomFooter;
