/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useField } from 'formik';

import styles from './CheckboxGroup.module.scss';

const Label = ({
  id,
  name,
  option,
  checkboxClassName,
  wrapperClassName,
  titleClassName,
  valueField,
  ...props
}) => {
  const [field] = useField({ name, ...props });

  return (
    <label
      key={id}
      className={`
        ${styles.label}
        ${wrapperClassName}
        flex
        flex-v-center
        c-custom
      `}
    >
      <input
        type="checkbox"
        {...field}
        {...props}
        value={option[valueField]}
        className="c-custom__input"
        checked={field.value.includes(option[valueField])}
      />
      <span
        className={`
          ${checkboxClassName}
          c-custom__check-bordered-primary
          c-custom__check-sm
          c-custom__custom-input
          c-custom__check
        `}
      />
      <span
        className={`
          ${styles.title}
          ${titleClassName}
        `}
      >
        {option.title}
      </span>
    </label>
  );
};

const CheckboxGroup = ({
  name,
  options,
  wrap,
  valueField = 'title',
  ...props
}) => {
  const WrapComponent = wrap;

  return options.map(({ id, ...option }) => (
    WrapComponent
      ? (
        <WrapComponent key={id}>
          <Label option={option} valueField={valueField} id={id} name={name} {...props} />
        </WrapComponent>
      )
      : <Label key={id} option={option} id={id} name={name} valueField={valueField} {...props} />
  ));
};

export default CheckboxGroup;
