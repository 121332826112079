import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { DatePicker } from 'antd';
import moment from 'moment';

import RoomsService from '../../services/rooms.service';
import FeaturesService from '../../services/features.service';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';
import IconCalendar from '../../assets/img/icons/ic-calendar-active.svg';
import IconClose from '../../assets/img/icons/close.svg';
import IconFilters from '../../assets/img/icons/ic-filter.svg';
import FormikRadioGroup from '../RadioGroup/RadioGroup';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { useLockBodyScroll } from '../../hooks/useBodyScrollLock';
import { DEFAULT_FORMAT } from '../../constans/formats';
import { filterByUniqueKey } from '../../helpers/helper-methods';
import { TABLET_WIDTH_BREAKPOINT } from '../../constans/header';
import {
  ALL,
  AVAILABILITY_DATE_OPTIONS,
  PERIODS,
} from '../../constans/offers';

import styles from './Filters.module.scss';

const Filters = ({ id, setFilters }) => {
  const [roomCategories, setRoomCategories] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [features, setFeatures] = useState([]);
  const [locked, setLocked] = useState(false);
  const service = useMemo(() => RoomsService(), []);
  const featuresService = useMemo(() => FeaturesService(), []);
  const windowWidth = useWindowWidth();
  const filtersShown = isShown || windowWidth > TABLET_WIDTH_BREAKPOINT;
  const backdropShown = isShown && windowWidth <= TABLET_WIDTH_BREAKPOINT;

  const { enable, disable } = useLockBodyScroll();

  const getRoomCategories = () => service.getRoomsByPropertyId({
    propertyId: id,
    shortResponse: true,
  }).then(({ data }) => setRoomCategories(filterByUniqueKey(data, 'title')));

  const getFeatures = () => featuresService.getGeneralFeatures()
    .then(({ data }) => setFeatures(data));

  const handleSubmit = ({
    roomTitles, featureTitles, specificDate, period,
  }) => {
    const { startDate = null, endDate = null } = PERIODS[period] || {};

    const newFilters = {
      roomTitles,
      featureTitles,
      specificDate: !period.length ? specificDate : null,
      startDate,
      endDate,
    };

    setIsShown(false);
    setFilters(newFilters);
  };

  useEffect(() => {
    getRoomCategories();
    getFeatures();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isShown ? disable() : enable();

    return () => enable();
  }, [isShown]);

  return (
    <>
      {true && (
        <>
          {backdropShown && (
            <div
              className={styles.filtersBackdrop}
              onClick={() => setIsShown(false)}
            />
          )}
          <div
            className={`
              ${styles.filters}
              ${filtersShown && styles.active}
              ${locked && styles.modalScrollLocked}
            `}
          >
            <Formik
              initialValues={{
                roomTitles: [],
                featureTitles: [],
                specificDate: null,
                period: ALL,
              }}
              onSubmit={handleSubmit}
            >
              {({ values, resetForm, setFieldValue }) => {
                const handleReset = () => {
                  setFilters(null);
                  resetForm();
                  setIsShown(false);
                };

                const handleDatePick = ($moment) => {
                  setFieldValue('specificDate', $moment.format());
                  setFieldValue('period', {});
                };

                const handlePeriodPick = ({ target }) => {
                  setFieldValue('specificDate', null);
                  setFieldValue('period', target.value);
                };

                return (
                  <Form>
                    <div
                      className={`
                        flex flex-v-center
                        ${styles.filtersHeader}
                      `}
                    >
                      <p className="mb-0">Filters</p>
                      <button
                        onClick={handleReset}
                        className={styles.filtersReset}
                        type="button"
                      >
                        reset
                      </button>
                      <button
                        className={styles.close}
                        type="button"
                        onClick={() => setIsShown(false)}
                      >
                        <IconClose />
                      </button>
                    </div>

                    <div className={styles.filtersSection}>
                      <p className={styles.filtersSectionTitle}>Availability date</p>
                      <DatePicker
                        size="large"
                        inputReadOnly={backdropShown}
                        format={DEFAULT_FORMAT}
                        placeholder="Specific day"
                        value={values.specificDate ? moment(values.specificDate) : null}
                        suffixIcon={<IconCalendar />}
                        allowClear={false}
                        onOpenChange={() => setLocked(!locked)}
                        onChange={handleDatePick}
                        style={{
                          borderRadius: '6px',
                          width: '100%',
                        }}
                      />
                      <Field
                        name="period"
                        onChange={handlePeriodPick}
                        options={AVAILABILITY_DATE_OPTIONS}
                        wrapperStyles={styles.radios}
                        component={FormikRadioGroup}
                      />
                    </div>
                    <div className={styles.filtersSection}>
                      <p className={styles.filtersSectionTitle}>Room category</p>
                      <CheckboxGroup
                        checkboxClassName={styles.checkbox}
                        wrapperClassName={styles.checkboxWrapper}
                        titleClassName={`${styles.checkboxText} text-truncate`}
                        options={roomCategories}
                        name="roomTitles"
                        value={values.roomCategories}
                      />
                    </div>
                    <div className={styles.filtersSectionLast}>
                      <p className={styles.filtersSectionTitle}>Features</p>
                      <CheckboxGroup
                        checkboxClassName={styles.checkbox}
                        wrapperClassName={styles.checkboxWrapper}
                        titleClassName={`${styles.checkboxText} text-truncate`}
                        options={features}
                        name="featureTitles"
                        value={values.roomCategories}
                      />
                      <button
                        type="submit"
                        className={`
                          ${styles.submit}
                          btn btn-uppercased btn_inverted t-600
                        `}
                      >
                        apply
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
      <button
        onClick={() => setIsShown(true)}
        className={styles.filtersToggler}
        type="button"
      >
        <IconFilters />
      </button>
    </>
  );
};

Filters.propTypes = {
  id: PropTypes.number.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
