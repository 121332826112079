import React from 'react';
import SellerHeader from '../../../components/SellerHeader';
// import TabNav from '../../../components/SideNav/TabNav/TabNav';
import SideNav from '../../../components/SideNav/SideNav';
import { SELLER_SETTINGS_NAV } from '../../../constans/links';

const SellerSettingsWrapper = ({ children }) => (
  <>
    <SellerHeader />
    <div className="container property-edit-wrapper">
      <h3 className="h3-title mb-2 t-700 seller-title">Admin Settings</h3>
      <div className="row flex-grow-1">
        <SideNav links={SELLER_SETTINGS_NAV} />
        <div className="col-12 col-lg-9">
          <div className="edit-form-wrapper edit-from-mobile">
            {children}
          </div>
        </div>
      </div>
    </div>
  </>
);

export default SellerSettingsWrapper;
