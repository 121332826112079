import http from './http.service';

const PaymentsService = () => {
  const baseUrl = '/payments';

  const getPaymentsList = (model) => http.get(baseUrl, {
    params: model,
  });

  const getPaymentById = (id) => http.get(`${baseUrl}/${id}`);

  return {
    getPaymentsList,
    getPaymentById,
  };
};

export default PaymentsService;
