import React, { useEffect, useMemo, useState } from 'react';

import PropertiesService from '../../services/properties.service';
import SellerHeader from '../../components/SellerHeader';
import ProperyCard from '../../components/PropertyCard';
import Pagination from '../../components/Pagination';
import SearchInput from '../../components/Search';
import { LIMIT } from '../../constans/pagination';

import styles from './index.module.scss';

const SellersProperties = () => {
  const service = useMemo(() => PropertiesService(), []);
  const [properties, setProperties] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const getProperties = ({ selected: page }) => {
    service
      .getMyProperties({
        title: searchQuery || null,
        limit: LIMIT,
        offset: page * LIMIT,
      })
      .then(({ data, pagination }) => {
        setProperties(data);
        setPageNumber(page);
        setTotal(pagination.totalCount);
      })
      .catch(() => {
        /* do nothing */
      });
  };

  useEffect(() => {
    if (searchQuery?.length > 2 || !searchQuery) {
      service
        .getMyProperties({
          title: searchQuery || null,
          limit: LIMIT,
          offset: 0,
        })
        .then(({ data, pagination }) => {
          setProperties(data);
          setPageNumber(0);
          setTotal(pagination.totalCount);
        })
        .catch(() => {
          /* do nothing */
        });
    }
  }, [searchQuery]);

  return (
    <>
      <SellerHeader />
      <div className={`container ${styles.wrapper}`}>
        <div className={`${styles.header} flex flex-between flex-v-center`}>
          <h3 className={styles.title}>My Properties</h3>
          <SearchInput
            placeholder="Search"
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            value={searchQuery}
            type="text"
          />
        </div>

        {properties &&
          properties.map(({ title, roomsCount, id, photos, address }) => (
            <ProperyCard
              propertyName={title}
              roomsCount={roomsCount}
              address={address}
              id={id}
              image={photos && photos[0]?.file?.link}
              key={id}
            />
          ))}

        <div className={styles.paginationWrapper}>
          <Pagination
            forcePage={pageNumber}
            total={total}
            callback={getProperties}
          />
        </div>
      </div>
    </>
  );
};

export default SellersProperties;
