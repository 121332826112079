import React from 'react';
import { Link } from 'gatsby';

import PropertyDetailsHeader from '../../components/PropertyDetails/PropertyDetailsHeader/PropertyDetailsHeader';
import PlusIcon from '../../assets/img/icons/plus.svg';
import Pagination from '../../components/Pagination';
import PropertyDetailsRoomItem from '../../components/RoomItem/PropertyDetailRoomItem/PropertyDetailRoomItem';
import useRoomListDataHandling from '../../hooks/useRoomListDataHandling';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ROOM_LIMIT } from '../../constans/pagination';
import { MAX_ROOMS } from '../../constans/validation';

import styles from './RoomsList.module.scss';

const RoomsList = ({ id, path, location }) => {
  const [roomsList, pageNumber, total, getRooms] = useRoomListDataHandling(id);

  return (
    <div className={`${styles.container} container property-edit-wrapper`}>
      <Breadcrumbs
        path={path}
        id={id}
      />
      <div className={styles.headerWrapper}>
        <PropertyDetailsHeader id={id} />
        <Link
          to={`/sellers/my-properties/${id}/edit/rooms/add-room`}
          state={{ prevPath: location.pathname }}
          className={`${styles.editButton} ${total >= MAX_ROOMS && 'disabled-link'} edit-add-btn t-600 btn btn_bordered br-17 flex flex-center t-600`}
        >
          <PlusIcon />
          <span>Add new</span>
        </Link>
      </div>

      {roomsList && (
        <>
          <div className={styles.listWrapper}>
            {roomsList && roomsList.map((roomItem, index) => (
              <PropertyDetailsRoomItem
                roomId={roomItem.id}
                bedTypes={roomItem.bedTypes}
                editUrl={`/sellers/my-properties/${id}/rooms/${roomItem.id}/edit/general-info`}
                capacitiesObj={roomItem.capacitiesObj}
                itemStyles={styles.roomItem}
                key={`xlne${index}`}
                title={roomItem.title}
                description={roomItem.description}
                index={index + 1}
                amenities={roomItem.amenities}
                photos={roomItem.photos}
                surface={roomItem.surface}
              />
            ))}
          </div>
          {total > ROOM_LIMIT ? (
            <Pagination
              perPage={ROOM_LIMIT}
              forcePage={pageNumber}
              total={total}
              callback={getRooms}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default RoomsList;
