/* eslint-disable func-names */
import {
  object, string, number, array, boolean, date, ref,
} from 'yup';
import moment from 'moment';

import { FEATURE_TYPES } from '../../../constans/features';
import {
  MAX_CODE_LENGTH,
  MAX_INVENTORY_NUMBER,
  MAX_MIN_STAY_DURATION,
  MAX_OFFER_TITLE_LENGTH,
  MAX_PERCENTAGE,
  MAX_PRICE,
  MAX_TAX_DESCRIPTION_LENGTH,
  ONE,
  REGEXP_ALPHANUM,
  REGEXP_NOSPACES,
  TAX_MAX_LENGTH,
  ZERO,
  MONTH_LENGTH,
} from '../../../constans/validation';
import {
  OFFER_NAME_REQUIRED,
  OFFER_NAME_MATCHES,
  OFFER_CODE_REQUIRED,
  OFFER_CODE_MATCHES,
  OFFER_ROOM_TYPE_REQUIRED,
  OFFER_MAX_GUESTS_REQUIRED,
  OFFER_MAX_CAPACITY,
  OFFER_START_DATE_REQUIRED,
  OFFER_END_DATE_REQUIRED,
  OFFER_END_DATE_LATE,
  OFFER_MIN_SAY_DURATION_REQUIRED,
  OFFER_MIN_STAY_DURATION_PERIOD,
  OFFER_INV_NUMBER_REQUIRED,
  OFFER_INV_NUMBER_RANGE,
  OFFER_BASE_PRICE_REQUIRED,
  OFFER_BASE_PRICE_INVALID,
  ADDITIONAL_PRICE_REQUIRED,
  ADDITIONAL_PRICE_INVALID,
  TAX_DESCRIPTION_INVALID,
  TAX_NAME_REQUIRED,
  TAX_VALUE_REQUIRED,
  TAX_VALUE_INVALID,
  TAX_NAME_TOO_LONG,
  CANCELLATION_VALUE_INVALID,
  CANCELLATION_AMOUNT_TYPE,
  CANCELLATION_AMOUNT_MIN,
  MAX_VALUE_EXCEED,
  PAYMENT_DEAD_LINE_INVALID,
  PAYMENT_DEAD_LINE_REQUIRED,
  ONE_OF_EDIT_POLICY_CHANGE_REQUIRED,
  MAX_NUMBER_OF_GUESTS_MUST_BE_A_NUMBER,
} from '../../../constans/error-messages';
import {
  FIXED_AMOUNT,
  PERCENTAGE,
  DAY_BEFORE_CHECKIN_DATE,
  PAYMENT_TERMS_KEY,
  BEFORE_CHEKIN_DATE,
} from '../../../constans/price-and-taxes';

const oneOfEditPolicyRequired = function () {
  if (!this.parent.editPolicyChanges) {
    return true;
  }

  return this.parent.editPolicy.dates
    || this.parent.editPolicy.roomTypes
    || this.parent.editPolicy.mainGuestName;
};

const featureValidationSchema = object().shape({
  title: string().required(),
  selected: boolean(),
});

const taxesValidationSchema = object().shape({
  title: string()
    .max(TAX_MAX_LENGTH, TAX_NAME_TOO_LONG)
    .required(TAX_NAME_REQUIRED),
  type: string()
    .required(),
  price: number()
    .max(MAX_PERCENTAGE, TAX_VALUE_INVALID)
    .min(ZERO, TAX_VALUE_INVALID)
    .required(TAX_VALUE_REQUIRED),
});

export const GeneralOfferTabValidationSchema = object().shape({
  title: string()
    .max(MAX_OFFER_TITLE_LENGTH)
    .matches(REGEXP_NOSPACES, OFFER_NAME_MATCHES)
    .required(OFFER_NAME_REQUIRED),
  code: string()
    .length(MAX_CODE_LENGTH, OFFER_CODE_MATCHES)
    .matches(REGEXP_ALPHANUM, OFFER_CODE_MATCHES)
    .required(OFFER_CODE_REQUIRED),
  roomId: number()
    .required(OFFER_ROOM_TYPE_REQUIRED),
  maxRoomCapacity: number()
    .optional(),
  maxNumberOfGuests: number()
    .typeError(MAX_NUMBER_OF_GUESTS_MUST_BE_A_NUMBER)
    .max(ref('maxRoomCapacity'), OFFER_MAX_CAPACITY)
    .required(OFFER_MAX_GUESTS_REQUIRED),
  startDate: date()
    .min(moment().endOf('day'))
    .when('noTimeLimit', {
      is: false,
      then: date().min(moment().endOf('day')).required(OFFER_START_DATE_REQUIRED),
    }),
  endDate: date()
    .min(ref('startDate'), OFFER_END_DATE_LATE)
    .when('noTimeLimit', {
      is: false,
      then: date().min(ref('startDate'), OFFER_END_DATE_LATE).required(OFFER_END_DATE_REQUIRED),
    }),
  minimumStayDuration: number()
    .min(ONE, OFFER_MIN_STAY_DURATION_PERIOD)
    .max(MAX_MIN_STAY_DURATION, OFFER_MIN_STAY_DURATION_PERIOD),
    // .required(OFFER_MIN_SAY_DURATION_REQUIRED),
  inventoryNumber: number()
    .max(MAX_INVENTORY_NUMBER, OFFER_INV_NUMBER_RANGE)
    // .min(ONE, OFFER_INV_NUMBER_RANGE)
    // .required(OFFER_INV_NUMBER_REQUIRED),
});

export const GeneralEditOfferTabValidationSchema = object().shape({
  title: string()
    .max(MAX_OFFER_TITLE_LENGTH)
    .matches(REGEXP_NOSPACES, OFFER_NAME_MATCHES)
    .required(OFFER_NAME_REQUIRED),
  code: string()
    .length(MAX_CODE_LENGTH, OFFER_CODE_MATCHES)
    .matches(REGEXP_ALPHANUM, OFFER_CODE_MATCHES)
    .required(OFFER_CODE_REQUIRED),
  roomId: number()
    .required(OFFER_ROOM_TYPE_REQUIRED),
  maxRoomCapacity: number()
    .optional(),
  maxNumberOfGuests: number()
    .typeError(MAX_NUMBER_OF_GUESTS_MUST_BE_A_NUMBER)
    .max(ref('maxRoomCapacity'), OFFER_MAX_CAPACITY)
    .required(OFFER_MAX_GUESTS_REQUIRED),
  startDate: date()
    .when('noTimeLimit', {
      is: false,
      then: date().required(OFFER_START_DATE_REQUIRED),
    }),
  endDate: date()
    .min(ref('startDate'), OFFER_END_DATE_LATE)
    .when('noTimeLimit', {
      is: false,
      then: date().min(ref('startDate'), OFFER_END_DATE_LATE).required(OFFER_END_DATE_REQUIRED),
    }),
  minimumStayDuration: number()
    .min(ONE, OFFER_MIN_STAY_DURATION_PERIOD)
    .max(MAX_MIN_STAY_DURATION, OFFER_MIN_STAY_DURATION_PERIOD),
    // .required(OFFER_MIN_SAY_DURATION_REQUIRED),
  inventoryNumber: number()
    .max(MAX_INVENTORY_NUMBER, OFFER_INV_NUMBER_RANGE)
    // .min(ONE, OFFER_INV_NUMBER_RANGE)
    // .required(OFFER_INV_NUMBER_REQUIRED),
});

export const IncludedFeaturesTabValidationSchema = object().shape({
  features: object().shape({
    [FEATURE_TYPES.FOOD_AND_BEVERAGES]: array()
      .of(featureValidationSchema),
    [FEATURE_TYPES.OTHER]: array()
      .of(featureValidationSchema),
    [FEATURE_TYPES.SERVICE]: array()
      .of(featureValidationSchema),
  }),
  maxGuestPerFeature: number()
    .typeError(MAX_NUMBER_OF_GUESTS_MUST_BE_A_NUMBER)
    .required(OFFER_MAX_GUESTS_REQUIRED),
});

export const PriceAndTaxesTabValidationSchema = object().shape({
  basePrice: number()
    .typeError(OFFER_BASE_PRICE_INVALID)
    .max(MAX_PRICE, OFFER_BASE_PRICE_INVALID)
    .min(ZERO, OFFER_BASE_PRICE_INVALID)
    .required(OFFER_BASE_PRICE_REQUIRED),
  basePriceType: string().required(),
  pricePerGuest: number()
    .typeError(ADDITIONAL_PRICE_INVALID)
    .max(MAX_PRICE, ADDITIONAL_PRICE_INVALID)
    .min(ZERO, ADDITIONAL_PRICE_INVALID)
    .required(ADDITIONAL_PRICE_REQUIRED),
  pricePerGuestType: string().required(),
  taxes: array().of(taxesValidationSchema),
  termsAndConditionId: number().optional(),
  taxesDescription: string()
    .matches(REGEXP_NOSPACES, TAX_DESCRIPTION_INVALID)
    .max(MAX_TAX_DESCRIPTION_LENGTH, TAX_DESCRIPTION_INVALID)
    .optional(),
  cancellationPenaltyType: string()
    .required(),
  percentageOfPenalty: number()
    .when('cancellationPenaltyType', {
      is: PERCENTAGE,
      then: number()
        .typeError(CANCELLATION_VALUE_INVALID)
        .required(CANCELLATION_VALUE_INVALID)
        .max(MAX_PERCENTAGE, CANCELLATION_VALUE_INVALID)
        .min(ZERO, CANCELLATION_VALUE_INVALID),
      otherwise: number()
        .typeError(CANCELLATION_VALUE_INVALID)
        .optional(),
    }),
  amountOfPenalty: number()
    .typeError(CANCELLATION_AMOUNT_TYPE)
    .when('cancellationPenaltyType', {
      is: FIXED_AMOUNT,
      then: number()
        .min(ZERO, CANCELLATION_AMOUNT_MIN)
        .test('maxPenaltyPrice', MAX_VALUE_EXCEED, function () {
          const { basePrice, amountOfPenalty } = this.parent;

          return basePrice * MONTH_LENGTH >= amountOfPenalty;
        })
        .required(CANCELLATION_VALUE_INVALID),
      otherwise: number()
        .typeError(CANCELLATION_VALUE_INVALID)
        .optional(),
    }),
  editPolicy: object().shape({
    dates: boolean(),
    roomTypes: boolean(),
    mainGuestName: boolean(),
  })
    .test('oneOfRequired',
      ONE_OF_EDIT_POLICY_CHANGE_REQUIRED,
      oneOfEditPolicyRequired),
  cancellationDeadlineInHours: number()
    .required(),
  paymentDeadlineInDays: number()
    .max(DAY_BEFORE_CHECKIN_DATE, PAYMENT_DEAD_LINE_INVALID)
    .when(PAYMENT_TERMS_KEY, {
      is: BEFORE_CHEKIN_DATE,
      then: number()
        .max(DAY_BEFORE_CHECKIN_DATE, PAYMENT_DEAD_LINE_INVALID)
        .required(PAYMENT_DEAD_LINE_REQUIRED),
    }),
});
