import React from 'react';
import { FieldArray } from 'formik';
import styles from '../LocationsTransport.module.scss';
import Button from '../../../../components/UI/Button/Button';
import PlusIcon from '../../../../assets/img/icons/plus.svg';
import RemoveModal from '../../../../components/UI/RemoveModal/RemoveModal';
import LocationsInputsBlock from '../LocationsInputsBlock/LocationsInputsBlock';
import { LOCATION_TRANSPORT } from '../../../../constans/form-fields';

const LocationsSurroundingBlock = ({
  title,
  name,
  valuesSurroundings,
  initialValues,
}) => (
  <FieldArray
    name={name}
    render={(arrayHelpers) => (
      <div>
        <div className={`${styles.title} property-edit-title`}>
          <span>{title}</span>
          <Button
            disabled={!(valuesSurroundings?.length < 5)}
            onClick={() => arrayHelpers.push(initialValues)}
            classes="edit-add-btn t-600 btn btn_bordered br-17 flex flex-center"
            type="button"
          >
            <PlusIcon />
            <span>Add new</span>
          </Button>
        </div>
        {valuesSurroundings.map((tourist, index) => (
          <div
            className={styles.surroundingsInputBlock}
            key={`qszqewr${index}`}
          >
            <RemoveModal
              modalWrapperClasses={styles.removeModal}
              showRemove
              removeIconClasses={styles.removeIcon}
              arrayHelpers={arrayHelpers}
              index={index}
              values={valuesSurroundings}
              modalSubtitle="Do you want to remove this item?"
              modalSubmit={() => {
                arrayHelpers.remove(index);
              }}
            />
            <LocationsInputsBlock
              values={valuesSurroundings[index]}
              wrapperStyles={styles.inputGroupWrapper}
              titleStyles={styles.inputGroupTitle}
              distanceStyles={styles.inputGroupDistance}
              commentStyles={styles.inputGroupComment}
              commentName={`${name}.${index}.${LOCATION_TRANSPORT.locationComment.name}`}
              distanceName={`${name}.${index}.${LOCATION_TRANSPORT.distance.name}`}
              titleName={`${name}.${index}.${LOCATION_TRANSPORT.locationName.name}`}
            />
          </div>
        ))}
      </div>
    )}
  />
);

export default LocationsSurroundingBlock;
