import React from 'react';
import styles from './PropertyReviewInfoBlock.module.scss';

function PropertyReviewInfoBlock({ title, children, containerStyles }) {
  return (
    <div className={`${containerStyles} ${styles.infoBlockWrapper}`}>
      <h2 className={`${styles.infoBlockTitle} t-600 uppercased-text`}>
        {title}
      </h2>
      <p className={`t-500 ${styles.text}`}>
        {children}
      </p>
    </div>
  );
}

PropertyReviewInfoBlock.defaultProps = {
  containerStyles: '',
};

export default PropertyReviewInfoBlock;
