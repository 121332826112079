import React, { useEffect, useState } from 'react';
import moment from 'moment';
import http from '../../services/http.service';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import BookingInfoBlock from './BookingInfoBlock/BookingInfoBlock';
import ExpandableBlock from '../ExpandableBlock/ExpandableBlock';
import Spinner from '../UI/Spinner/Spinner';
import styles from './PropertyBookingItem.module.scss';
import { DEFAULT_FORMAT } from '../../constans/formats';
import { formatCurrency } from '../../helpers/helper-methods';
import {
  BOOKING_PAYMENT_STATUSES,
  BOOKING_PAYMENT_STATUS_MAP,
} from '../../constans/bookings';

const PropertyBookingItem = ({ bookId, id, path }) => {
  const [bookingData, setBookingData] = useState(null);

  useEffect(() => {
    http.get(`/bookings/${bookId}`)
      .then((data) => {
        const bookingItemData = data?.data;
        bookingItemData.formattedNumberOfGuests = `
            ${bookingItemData?.numberOfAdults}A
            ${bookingItemData?.numberOfKids ? ' + ' : ''}
            ${bookingItemData?.numberOfKids ? `${bookingItemData.numberOfKids}K` : ''}
        `;
        setBookingData(bookingItemData);
      });
  }, []);

  return (
    <div className="pt-0 container property-edit-wrapper">
      <Breadcrumbs
        path={path}
        id={id}
        bookId={bookId}
      />
      {bookingData ? (
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className={styles.mainInfoWrapper}>
              <div className={`
              ${styles.infoHeader} 
              ${styles.infoBlockWrapper} 
              flex flex-v-center flex-between
            `}
              >
                <div className={`${styles.infoTitle} h5-title`}>
                  {`Booking Reference #${bookingData?.offer?.code}`}
                </div>
                <div
                  className={`
                    ${styles.bookingStatus} 
                    ${BOOKING_PAYMENT_STATUS_MAP[bookingData?.paymentStatus].background}
                      capitalized-text t-500
                      status-item
                    `}
                >
                  {BOOKING_PAYMENT_STATUS_MAP[bookingData?.paymentStatus].text}
                </div>
              </div>
              {/* Booked by */}
              <div className={styles.infoBlockWrapper}>
                <div className={`${styles.subtitleMain} uppercased-text t-700`}>Booked by</div>
                <div className={`${styles.infoGuestList} flex flex-wrap`}>
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Full name"
                    text={`${bookingData.user.firstName} ${bookingData.user.lastName}`}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Title"
                    text={bookingData.user.title}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Phone number"
                    text={bookingData.user.phone}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Email address"
                    text={bookingData.user.email}
                  />
                </div>
              </div>
              {/* Main guest details */}
              <div className={styles.infoBlockWrapper}>
                <div className={`${styles.subtitleMain} uppercased-text t-700`}>Main guest details</div>
                <div className={`${styles.infoGuestList} flex flex-wrap`}>
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Full name"
                    text={`${bookingData.firstName} ${bookingData.lastName}`}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Title"
                    text={bookingData.prefix}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Phone number"
                    text={bookingData.phone}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoGuestListItem}
                    title="Email address"
                    text={bookingData.email}
                  />
                </div>
              </div>
              {/* Booking details */}
              <div className={styles.infoBookingWrapper}>
                <div className={`${styles.subtitleMain} uppercased-text t-700`}>Booking details</div>
                <div className={`${styles.infoBookingList} flex flex-wrap flex-h-start`}>
                  <BookingInfoBlock
                    containerStyles={styles.infoBookingListItem}
                    title="Check-In date"
                    text={moment(bookingData.startDate).format(DEFAULT_FORMAT)}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoBookingListItem}
                    title="Check-out date"
                    text={moment(bookingData.endDate).format(DEFAULT_FORMAT)}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoBookingListItem}
                    title="Total length of stay"
                    text={`${bookingData?.lengthOfStay} ${bookingData?.lengthOfStay ? bookingData.lengthOfStay > 1 ? 'days' : 'day' : ''}`}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoBookingListItem}
                    title="Number of guests"
                    text={bookingData.formattedNumberOfGuests}
                  />
                  <BookingInfoBlock
                    containerStyles={styles.infoBookingListItem}
                    title="Arrival time"
                    text={bookingData.arrivalTime}
                  />
                </div>
              </div>
              {/* Offer information */}
              <div className={styles.infoOfferWrapper}>
                <div className={`${styles.subtitle} uppercased-text t-600`}>Offer information</div>
                <div className="t-500">
                  {bookingData.offer?.room.title}
                  {' '}
                  -
                  {' '}
                  {bookingData.offer?.title}
                </div>
                {bookingData.offer?.features.length > 0 ? (
                  <div className={`${styles.infoOfferList} flex flex-wrap`}>
                    {bookingData.offer.features.map((offerFeature, index) => (
                      <div
                        key={`sdfo${index}`}
                        className={`${styles.infoListItemTextDotted} ${styles.infoOfferListItem} t-500`}
                      >
                        {offerFeature.title}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              {/* T&C */}
              {bookingData?.offer?.termsAndCondition ? (
                <ExpandableBlock
                  headerStyles={styles.expandableBlockHeaderWrapper}
                  arrowStyles={styles.expandableBlockBtn}
                  containerStyles={styles.expandableBlockWrapper}
                  header={(
                    <div className={`${styles.expandableBlockHeader} t-600`}>
                      Terms &amp; Conditions
                    </div>
                  )}
                >
                  <div className={styles.text}>
                    {bookingData.offer.termsAndCondition.description}
                  </div>
                </ExpandableBlock>
              ) : null}
              {bookingData?.comment ? (
                <>
                  <div className={`${styles.subtitle} uppercased-text t-600`}>
                    Comment
                  </div>
                  <div className={styles.text}>
                    {bookingData.comment}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className={styles.paymentWrapper}>
              {/* {bookingData?.paymentStatus === BOOK} */}
              {bookingData?.paymentStatus === BOOKING_PAYMENT_STATUSES.PAID && (
                <div className={styles.paymentBordered}>
                  <div className={`${styles.subtitleMain} ${styles.paymentTitle} uppercased-text t-700`}>Payment details</div>
                  {bookingData?.payment?.paymentMethod && (
                    <>
                      <div className={`${styles.subtitle} uppercased-text t-600`}>
                        Payment method
                      </div>
                      <div
                        className={`${styles.text} ${styles.paymentText} t-500`}
                      >
                        {`${bookingData?.payment?.card?.brand} ${bookingData?.payment?.paymentMethod}`}
                      </div>
                    </>
                  )}
                  {bookingData?.payment?.card && (
                    <>
                      <div className={`${styles.subtitle} uppercased-text t-600`}>
                        Card data
                      </div>
                      <div className={`${styles.text} ${styles.paymentText} t-500`}>
                        {`**** ${bookingData?.payment?.card?.last4}`}
                      </div>
                      <div className={`${styles.subtitle} uppercased-text t-600`}>
                        Payment date
                      </div>
                      <div className={`${styles.text} ${styles.paymentText} t-500`}>
                        {moment(bookingData?.payment?.card?.createdAt).format(DEFAULT_FORMAT)}
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className={styles.paymentBordered}>
                <div className={`${styles.subtitle} uppercased-text t-600`}>
                  Sub-total
                </div>
                <div className={`${styles.text} ${styles.paymentText} t-500`}>
                  {formatCurrency(bookingData?.currency?.code, bookingData?.subTotalPrice)}
                </div>
                <div className={`${styles.subtitle} uppercased-text t-600`}>
                  Tax
                </div>
                <div className={`${styles.text} ${styles.paymentText} t-500`}>
                  {formatCurrency(bookingData?.currency?.code, bookingData?.taxPrice)}
                </div>
                <div className={`${styles.subtitle} uppercased-text t-600`}>
                  Total price
                </div>
                <div className={`${styles.text} ${styles.paymentText} t-500`}>
                  {formatCurrency(bookingData?.currency?.code, bookingData?.totalPrice)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : <Spinner spinnerClasses="spinner-primary" />}
    </div>
  );
};

export default PropertyBookingItem;
