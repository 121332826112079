import React from 'react';

import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../../components/UI/Label/Label';
import { Input } from '../../../../components/UI/Input/Input';
import CounterInputBlock from '../../../../components/UI/CounterInputBlock/CounterInputBlock';
import InputDropDowned from '../../../../components/UI/InputDropDowned/InputDropDowned';
import InfoIcon from '../../../../assets/img/icons/ic-info-white.svg';
import { ROOM_GENERAL_INFO } from '../../../../constans/form-fields';
import {
  ROOM_DESCR_MAX,
  MAX_SURFACE,
  ROOM_TITLE_MAX,
  ROOM_MAX_PEOPLE,
  MIN_ADULTS,
} from '../../../../constans/validation';
import { DISTANCE_INPUT_STEP } from '../../../../constans/values';

import styles from './GeneralInfo.module.scss';

const GeneralInfo = ({
  errors,
  values,
  setFieldValue,
}) => {
  const displayValueFromDropDownInputs = (adultsAmount, kidsAmount = '') => {
    const adultPrefix = 'A';
    const kidsPrefix = 'K';
    return `
      ${parseInt(adultsAmount, 10) > 0 ? adultsAmount + adultPrefix : ''} 
      ${parseInt(kidsAmount, 10) > 0 ? `${adultsAmount ? '+' : ''} ${kidsAmount}${kidsPrefix}` : ''}
    `;
  };

  const increaseValue = (
    inputName,
    inputValue,
    setFieldValueFunc,
  ) => {
    const parsedInputValue = parseInt(inputValue === '' ? 0 : inputValue, 10);
    setFieldValueFunc(inputName, parsedInputValue + 1);
  };
  const reduceValue = (
    inputName,
    inputValue,
    setFieldValueFunc,
  ) => {
    const parsedInputValue = parseInt(inputValue === '' ? 0 : inputValue, 10);
    setFieldValueFunc(inputName, (parsedInputValue - 1) || '');
  };

  return (
    <>
      <div className="property-edit-title">
        <span>General Info</span>
      </div>
      <div className="row">
        <InputWrapper containerClasses="col-12 col-lg-6">
          <Label>Room type</Label>
          <Input
            type="text"
            maxLength={ROOM_TITLE_MAX}
            name={`general.main.${ROOM_GENERAL_INFO.roomType.name}`}
            placeholder={ROOM_GENERAL_INFO.roomType.placeholder}
          />
        </InputWrapper>
        <InputWrapper containerClasses="col-12 col-lg-6">
          <Label>Surface (m2)</Label>
          <Input
            maxLength={MAX_SURFACE}
            step={DISTANCE_INPUT_STEP}
            type="number"
            name={`general.main.${ROOM_GENERAL_INFO.roomSurface.name}`}
            placeholder={ROOM_GENERAL_INFO.roomSurface.placeholder}
          />
        </InputWrapper>
        <InputWrapper containerClasses="col-12">
          <Label>Description</Label>
          <Input
            max={ROOM_DESCR_MAX}
            type="text"
            name={`general.main.${ROOM_GENERAL_INFO.roomDescr.name}`}
            placeholder={ROOM_GENERAL_INFO.roomDescr.placeholder}
          />
        </InputWrapper>
        <InputWrapper containerClasses="col-md-12 col-xl-6">
          <Label>Designed capacity</Label>
          <InputDropDowned
            id="desCap"
            errored={
              errors?.general?.main?.[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]
              || errors?.general?.main?.[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]
            }
            placeHolder={ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.placeholder}
            inputValues={displayValueFromDropDownInputs(
              `${values.general?.main?.[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]}`,
              `${values.general?.main?.[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]}`,
            )}
          >
            <CounterInputBlock
              inputClassName={styles.counterInput}
              inputMaxLength={ROOM_MAX_PEOPLE}
              increaseDisabled={
                values
                  .general?.main[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]
                >= ROOM_MAX_PEOPLE
              }
              reduceDisabled={
                values
                  .general?.main[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name] <= MIN_ADULTS
              }
              labelText="Adults"
              inputName={`general.main.${ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name}`}
              reduceValue={() => reduceValue(
                `general.main.${[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]}`,
                values.general?.main[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name],
                setFieldValue,
              )}
              increaseValue={() => increaseValue(
                `general.main.${[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name],
                setFieldValue,
              )}
            />
            <CounterInputBlock
              inputClassName={styles.counterInput}
              inputMaxLength={ROOM_MAX_PEOPLE}
              increaseDisabled={
                values
                  .general
                  .main[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name] >= ROOM_MAX_PEOPLE
              }
              reduceDisabled={
                values
                  .general.main[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name] < MIN_ADULTS
              }
              labelText="Kids"
              inputName={`general.main.${ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name}`}
              reduceValue={() => reduceValue(
                `general.main.${[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name],
                setFieldValue,
              )}
              increaseValue={() => increaseValue(
                `general.main.${[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name],
                setFieldValue,
              )}
            />
          </InputDropDowned>
        </InputWrapper>
        <InputWrapper containerClasses="col-md-12 col-xl-6">
          <div className={`${styles.tooltipWrapper} flex flex-h-start`}>
            <Label>Maximum capacity</Label>
            <div className={styles.tooltipInner}>
              <InfoIcon className={styles.tooltipIcon} />
              <div className={`${styles.tooltipTextual} t-500`}>
                This is the maximum authorized capacity as per the
                property policy and includes both additional
                bedding and children sharing large size bed with their
                parents
              </div>
            </div>
          </div>
          <InputDropDowned
            id="maxCap"
            errored={
              errors?.general?.main?.[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]
              || errors?.general?.main?.[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]
            }
            placeHolder={ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.placeholder}
            inputValues={displayValueFromDropDownInputs(
              `${values.general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]}`,
              `${values.general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]}`,
            )}
          >
            <CounterInputBlock
              inputClassName={styles.counterInput}
              inputMaxLength={ROOM_MAX_PEOPLE}
              increaseDisabled={
                values
                  .general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]
                >= ROOM_MAX_PEOPLE
              }
              reduceDisabled={
                values
                  .general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name] <= MIN_ADULTS
              }
              labelText="Adults"
              inputName={`general.main.${ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name}`}
              reduceValue={() => reduceValue(
                `general.main.${[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name],
                setFieldValue,
              )}
              increaseValue={() => increaseValue(
                `general.main.${[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name],
                setFieldValue,
              )}
            />
            <CounterInputBlock
              inputClassName={styles.counterInput}
              inputMaxLength={ROOM_MAX_PEOPLE}
              increaseDisabled={
                values
                  .general
                  .main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name] >= ROOM_MAX_PEOPLE
              }
              reduceDisabled={
                values
                  .general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name] < MIN_ADULTS
              }
              labelText="Kids"
              inputName={`general.main.${ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name}`}
              reduceValue={() => reduceValue(
                `general.main.${[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name],
                setFieldValue,
              )}
              increaseValue={() => increaseValue(
                `general.main.${[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]}`,
                values.general.main[ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name],
                setFieldValue,
              )}
            />
          </InputDropDowned>
          {errors?.general?.[ROOM_GENERAL_INFO.maxCapacitySum.name]
            ? (
              <div className="error-input-msg">
                {errors.general[ROOM_GENERAL_INFO.maxCapacitySum.name]}
              </div>
            ) : null}
        </InputWrapper>
      </div>
    </>
  );
};

export default GeneralInfo;
