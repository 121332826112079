import React from 'react';
import { FieldArray } from 'formik';
import Button from '../../../../components/UI/Button/Button';
import ExpandableBlock from '../../../../components/ExpandableBlock/ExpandableBlock';
import RoomAmenitiesHeader from './RoomAmenitiesHeader/RoomAmenitiesHeader';
import CheckboxBlock from '../../../../components/UI/CheckboxBlock/CheckboxBlock';
import Label from '../../../../components/UI/Label/Label';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import RemoveModal from '../../../../components/UI/RemoveModal/RemoveModal';
import { Input } from '../../../../components/UI/Input/Input';
import PlusIcon from '../../../../assets/img/icons/plus.svg';
import { ROOM_CUSTOM_AMENITY_MAX, ROOM_CUSTOM_AMENITY_TITLE_LENGTH } from '../../../../constans/validation';
import styles from './RoomAmenities.module.scss';

const RoomAmenities = ({ values, errors }) => (
  <FieldArray
    name="amenities.custom"
    render={(arrayHelpers) => (
      <>
        <div className="property-edit-title property-edit-title__with-button">
          <span>Room Amenities</span>
          <Button
            disabled={!(values.amenities?.custom.length < ROOM_CUSTOM_AMENITY_MAX)}
            onClick={() => arrayHelpers.push({
              title: '',
            })}
            classes="edit-add-btn t-600 btn btn_bordered br-17 flex flex-center"
            type="button"
          >
            <PlusIcon />
            <span>Add amenities</span>
          </Button>
        </div>
        {values.amenities.general.length > 0 && (
          <ExpandableBlock
            isOpen
            errored={
              !!errors?.amenities?.general
            }
            contentInnerStyles={`${styles.generalAmenityList} flex flex-wrap`}
            header={(
              <RoomAmenitiesHeader
                containerStyles="flex-grow-1"
              >
                <span>General amenities</span>
              </RoomAmenitiesHeader>
            )}
          >
            {values.amenities.general.map((amenity, index) => (
              <CheckboxBlock
                withComment={false}
                key={`sqs${index}`}
                checkboxClasses="c-custom__check-bordered-primary c-custom__check-md"
                titleValue={values.amenities.general[index].title}
                inputName={`amenities.general.${index}.title`}
                checkBoxName={`amenities.general.${index}.check`}
                checkboxMarkClasses={styles.generalAmenityCheckBox}
                titleClasses={styles.generalAmenityTitle}
                labelClasses={styles.generalAmenityCheckLabel}
                containerClasses={styles.generalAmenityItem}
              />
            ))}
          </ExpandableBlock>
        )}
        {values.amenities.custom && (
          <ExpandableBlock
            isOpen
            errored={
              !!errors?.amenities?.custom
            }
            btnDisabled={!values.amenities.custom.length > 0}
            contentInnerStyles="flex flex-wrap"
            header={(
              <RoomAmenitiesHeader
                containerStyles="flex-grow-1"
              >
                <span>Custom amenities</span>
              </RoomAmenitiesHeader>
            )}
          >
            {values.amenities.custom.length > 0 && values.amenities.custom.map((amenity, index) => (
              <InputWrapper key={`sdpr${index}`} containerClasses={`${styles.customAmenityItem}`}>
                <Label>Amenity title</Label>
                <Input
                  maxLength={ROOM_CUSTOM_AMENITY_TITLE_LENGTH}
                  type="text"
                  placeholder="Amenity title"
                  name={`amenities.custom.${index}.title`}
                />
                <RemoveModal
                  modalWrapperClasses={styles.customAmenityRemoveModal}
                  showRemove
                  removeIconClasses={styles.customAmenityRemoveIcon}
                  arrayHelpers={arrayHelpers}
                  index={index}
                  values={values.amenities.custom}
                  modalSubtitle="Do you want to remove this item?"
                  modalSubmit={() => {
                    arrayHelpers.remove(index);
                  }}
                />
              </InputWrapper>
            ))}
          </ExpandableBlock>
        )}
      </>
    )}
  />
);

export default RoomAmenities;
