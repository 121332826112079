import React from 'react';
import PropertyDetailsHeader from '../../components/PropertyDetails/PropertyDetailsHeader/PropertyDetailsHeader';
import PropertyReview from '../../components/PropertyReview/PropertyReview';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const Index = ({ id, path }) => (
  <div className="pt-0 container property-edit-wrapper">
    <Breadcrumbs
      path={path}
      id={id}
    />
    <PropertyDetailsHeader id={id} />
    <PropertyReview propertyId={id} />
  </div>
);

export default Index;
