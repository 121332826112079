import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ImageModal from '../ImageModal/ImageModal';
import IconAllPhotos from '../../assets/img/icons/ic-photos.svg';

import styles from './Gallery.module.scss';

const Gallery = ({
  images,
  galleryInnerStyles,
  tinyImageStyles,
  bigImageStyles,
}) => {
  const [activeImage, setActiveImage] = useState(0);
  const [isModalShown, setIsModalShown] = useState(false);

  const bigImage = images.length && images[0];
  const tinyImages = images.slice(1, 5);

  const handleClick = () => {
    setActiveImage(0);
    setIsModalShown(true);
  };

  return (
    <div className={styles.gallery}>
      <ImageModal
        images={images}
        show={isModalShown}
        onArrowClick={setActiveImage}
        activeIndex={activeImage}
        onCloseClick={() => setIsModalShown(false)}
      />
      <div className={`flex ${styles.galleryImages} ${galleryInnerStyles}`}>
        <div className={styles.galleryImageBig}>
          <img
            className={`
              ${bigImageStyles}
              ${styles.galleryImage}
          `}
            src={bigImage?.file?.link}
            alt="Big"
          />
        </div>
        <div className="flex flex-wrap">
          {tinyImages?.map(({ file, id }) => (
            <div className={styles.galleryImageTiny} key={id}>
              <img
                className={`${tinyImageStyles} ${styles.galleryImage}`}
                src={file.link}
                alt="tiny"
              />
            </div>
          ))}
        </div>
        <button
          type="button"
          className={styles.galleryButton}
          disabled={!images?.length}
          onClick={handleClick}
        >
          <IconAllPhotos />
          All photos
        </button>
      </div>
    </div>
  );
};

Gallery.propTypes = {
  galleryInnerStyles: PropTypes.string,
  tinyImageStyles: PropTypes.string,
  bigImageStyles: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      file: PropTypes.shape({
        link: PropTypes.string,
      }),
    }),
  ),
};

Gallery.defaultProps = {
  images: [],
  galleryInnerStyles: '',
  tinyImageStyles: '',
  bigImageStyles: '',
};

export default Gallery;
