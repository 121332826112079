import http from './http.service';
import { defaultParamsSerializer } from '../helpers/helper-methods';

const FeaturesService = () => {
  const basePath = '/features';

  const getGeneralFeatures = (model) => http.get(`${basePath}/general`, {
    params: model,
    paramsSerializer: (params) => defaultParamsSerializer(params),
  });

  return {
    getGeneralFeatures,
  };
};

export default FeaturesService;
