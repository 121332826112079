import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import toastr from 'toastr';
import Dropzone from 'react-dropzone';
import { FieldArray, Form, Formik } from 'formik';
import { object, string, array } from 'yup';

import { useWindowWidth } from '../../../hooks/useWindowWidth';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import SideNav from '../../../components/SideNav/SideNav';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { Textarea } from '../../../components/UI/Input/Input';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import PropertiesService from '../../../services/properties.service';
import DeletableImage from '../../../components/DeletableImage/DeletableImage';
import UploadIcon from '../../../assets/img/icons/ic-cloud-small.svg';
import { fileUploads, updateFileStatus } from '../../../helpers/file-upload';
import {
  PHOTO_TITLE_REQUIRED,
  PHOTO_TITLE_NO_SPACES,
  IMG_UPLOAD_FAILED,
} from '../../../constans/error-messages';
import ImageModal from '../../../components/ImageModal/ImageModal';
import { PHONE_WIDTH_BREAKPOINT } from '../../../constans/header';
import {
  MAX_PHOTO_TITLE_LEGTH,
  MAX_PHOTO_PER_LOAD,
  MAX_PHOTO_PER_RESTAURAUNT,
  MAX_PHOTO_SIZE,
  MIN_PHOTO_PER_RESTAURAUNT,
  PHOTO_FORMAT,
  REGEXP_NOSPACES,
} from '../../../constans/validation';

import styles from './PropertyPhotos.module.scss';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';

const imageValidationChema = object().shape({
  description: string()
    .required(PHOTO_TITLE_REQUIRED)
    .matches(REGEXP_NOSPACES, PHOTO_TITLE_NO_SPACES),
});

const validationSchema = object().shape({
  images: array()
    .of(imageValidationChema)
    .min(MIN_PHOTO_PER_RESTAURAUNT)
    .max(MAX_PHOTO_PER_RESTAURAUNT),
});

const PropertyPhotos = ({ id, path }) => {
  const service = useMemo(() => PropertiesService(), []);
  const [defaultValues, setDefaultValues] = useState([]);
  const [activeImage, setActiveImage] = useState(0);
  const [isModalShown, setIsModalShown] = useState(false);
  const isNewItemAdded = useRef(false);
  const width = useWindowWidth();

  const isDisabled = false;

  const fetchData = () => service.getPropertyPohotosById(id).then(({ data }) => setDefaultValues(
    data.map(({ file, description, id: itemId }) => ({
      file,
      description,
      id: itemId,
    })),
  ));

  const uploadFiles = (filesToUpload) => fileUploads(filesToUpload).then((fileIds) => {
    updateFileStatus(fileIds);
    return fileIds;
  });

  const handleSubmit = ({ images }, { setSubmitting }) => {
    setSubmitting(true);
    const newImages = images.filter(({ id: imageId }) => !imageId);

    if (newImages.length) {
      uploadFiles(newImages.map(({ file }) => file))
        .then((ids) => {
          const filesWithDescription = ids.map((fileId, index) => ({
            fileId,
            description: newImages[index].description,
          }));

          service
            .uploadPropertyPhotos(id, filesWithDescription)
            .then(() => fetchData())
            .then(() => toastr.success(SUCCESS_SEND_MSG))
            .then(() => {
              setSubmitting(true);
              isNewItemAdded.current = false;
            });
        })
        .catch(() => toastr.error(IMG_UPLOAD_FAILED))
        .finally(() => setSubmitting(false));
    }
  };

  const deletePhoto = (imageId) => service.deletePropertyPhotos(id, {
    photosIds: [imageId],
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pt-0 container property-edit-wrapper">
      <Breadcrumbs path={path} id={id} />
      <div className={`row flex-grow-1 flex ${styles.pageWrapper}`}>
        <SideNav propertyId={id} links={PROPERTY_EDIT_NAV} />
        <div className="col-12 col-lg-9">
          <div className={styles.content}>
            <div className={`${styles.headline} flex flex-v-center flex-between`}>
              <p className={styles.title}>Property photos</p>
            </div>
            <Formik
              enableReinitialize
              onSubmit={handleSubmit}
              validateOnChange
              validateOnBlur
              validationSchema={validationSchema}
              initialValues={{
                images: defaultValues,
              }}
            >
              {({ values, isValid, isSubmitting }) => (
                isSubmitting ? <Spinner spinnerClasses="spinner-primary" /> : (
                  <>
                    <ImageModal
                      images={values.images}
                      show={isModalShown}
                      onArrowClick={setActiveImage}
                      activeIndex={activeImage}
                      onCloseClick={() => setIsModalShown(false)}
                    />
                    <Form>
                      <FieldArray
                        name="images"
                        render={(arrayHelpers) => {
                          const onDelete = (imageId, arrayIndex) => (imageId
                            ? deletePhoto(imageId).then(() => arrayHelpers.remove(arrayIndex))
                            : arrayHelpers.remove(arrayIndex));

                          const onDrop = (files) => {
                            isNewItemAdded.current = true;

                            files.map((fileData) => arrayHelpers.push({
                              file: fileData,
                              link: URL.createObjectURL(fileData),
                              description: '',
                            }));
                          };

                          return (
                            <>
                              <Dropzone
                                accept={PHOTO_FORMAT}
                                maxFiles={MAX_PHOTO_PER_LOAD}
                                maxSize={MAX_PHOTO_SIZE}
                                onDrop={onDrop}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    {...getRootProps()}
                                    className={`
                                ${styles.dropzone}
                                ${isDisabled && styles.dropzoneDisabled}
                              `}
                                  >
                                    <input {...getInputProps()} />
                                    <UploadIcon className={styles.uploadIcon} />
                                    <p className={`${styles.dropzoneText}`}>
                                      {width > PHONE_WIDTH_BREAKPOINT ? (
                                        <>
                                          Drag &amp; Drop or
                                          {' '}
                                          <span className="t-600">
                                            Choose the files
                                          </span>
                                        </>
                                      ) : (
                                        <span className="t-600">
                                          Upload files
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                              </Dropzone>

                              <hr className={styles.divider} />

                              <div className={`flex flex-wrap ${styles.images}`}>
                                {values.images.map(
                                  (
                                    {
                                      description,
                                      id: imageId,
                                      file: { link },
                                      link: inlineFile,
                                    },
                                    index,
                                  ) => (
                                    <DeletableImage
                                      key={index}
                                      onPhotoClick={() => {
                                        setActiveImage(index);
                                        setIsModalShown(true);
                                      }}
                                      onDeletePhoto={() => onDelete(imageId, index)}
                                      className={styles.image}
                                      src={link || inlineFile}
                                    >
                                      <Textarea
                                        placeholder="Enter description"
                                        disabled={imageId && description.length}
                                        maxLength={MAX_PHOTO_TITLE_LEGTH}
                                        containerStyles={styles.textarea}
                                        name={`images.${index}.description`}
                                      />
                                    </DeletableImage>
                                  ),
                                )}
                              </div>
                            </>
                          );
                        }}
                      />

                      <button
                        type="submit"
                        disabled={!isValid || !isNewItemAdded.current || isSubmitting}
                        className={`
                          ${styles.submit}
                          btn btn_block btn-uppercased btn_common btn_auth t-600
                        `}
                      >
                        Save Changes
                      </button>
                    </Form>
                  </>
                )
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyPhotos;
