import { useEffect, useState } from 'react';
import { getRoomsList } from '../services/rooms-manage.service';
import { ROOM_LIMIT } from '../constans/pagination';
import { setPaginState, setCurrentPaginationPageMultiple } from '../helpers/helper-methods';

const useRoomListDataHandling = (id) => {
  const [roomsList, setRoomsList] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [total, setTotal] = useState(0);
  const [nextOffsetPage, setNextOffsetPage] = useState(0);

  const getRooms = ({ selected: page }) => getRoomsList({
    propertyId: id,
    limit: ROOM_LIMIT,
    offset: page * ROOM_LIMIT,
  }).then(({ data, pagination }) => {
    setPaginState(
      data,
      pagination,
      page,
      setRoomsList,
      setPageNumber,
      setTotal,
      setNextOffsetPage,
    );
  });

  useEffect(() => {
    getRoomsList({
      propertyId: id,
      limit: ROOM_LIMIT,
      offset: 0,
    }).then(({ data, pagination }) => {
      setPaginState(
        data,
        pagination,
        0,
        setRoomsList,
        setPageNumber,
        setTotal,
        setNextOffsetPage,
      );
    });
  }, []);

  const checkForUpdateCurrentPage = () => (
    setCurrentPaginationPageMultiple(nextOffsetPage, total, pageNumber, ROOM_LIMIT, 1)
  );

  return [roomsList, pageNumber, total, getRooms, checkForUpdateCurrentPage];
};

export default useRoomListDataHandling;
