import React from 'react';
import styles from './BookingInfoBlock.module.scss';

const BookingInfoBlock = ({
  containerStyles,
  title,
  text,
  textStyles,

}) => (
  <div className={`${containerStyles}`}>
    <div className={`${styles.title} uppercased-text t-600`}>{title}</div>
    <div className={`${textStyles} ${styles.text} t-500`}>{text}</div>
  </div>
);

BookingInfoBlock.defaultProps = {
  containerStyles: '',
  textStyles: '',
};

export default BookingInfoBlock;
