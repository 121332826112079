import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Dropdown from '../Dropdown';
import { PROPERTY_CARD_LINKS } from '../../constans/links';
import MapPin from '../../assets/img/icons/ic-location-gray.svg';
import TogglerIcon from '../../assets/img/icons/ic-chevron-dropdown.svg';
import ArrowIcon from '../../assets/img/icons/ic-arrow-right-blue.svg';
import LogoImg from '../../assets/img/placeholder.svg';

import styles from './index.module.scss';

const PropertyCard = ({
  propertyName, roomsCount, id, image, address,
}) => (
  <div className={`${styles.wrapper} flex br-30`}>
    <div className={styles.sectionWrapper}>
      <img
        src={image}
        alt="main"
        className={image !== LogoImg ? styles.image : styles.placeholder}
      />
      <div className={`${styles.details} text-truncate`}>
        <div className={styles.address}>
          <MapPin />
          <p title={address} className="text-truncate">
            {address}
          </p>
        </div>
        <h5 title={propertyName} className={`${styles.title} text-truncate`}>
          {propertyName}
        </h5>
        <hr className={styles.titleDivider} />
        <p className={styles.rooms}>
          {roomsCount}
          {' '}
          room
          {roomsCount > 1 && 's'}
        </p>
      </div>
      <Link
        className={`${styles.link} flex flex-center`}
        to={PROPERTY_CARD_LINKS[0].href.replace(':id', id)}
      >
        View Details
        <ArrowIcon />
      </Link>
    </div>
    <hr className={styles.bottomDivider} />
    <div className={`${styles.bottomLinks} flex`}>
      {PROPERTY_CARD_LINKS.map(({ title, href }, index) => (
        <Link className={styles.bottomLink} to={href.replace(':id', id)} key={index}>
          {title}
          <div className={styles.dot} />
        </Link>
      ))}

      <Dropdown
        // togglerText={<img src={LogoImg} className={styles.arrow} alt="" />}
        togglerText={<TogglerIcon className={styles.arrow} />}
        togglerClassName={styles.dropdown}
        activeTogglerClassName={styles.dropdownOpened}
      >
        {PROPERTY_CARD_LINKS.map(({ title, href }, index) => (
          <Link className={styles.dropdownLink} to={href.replace(':id', id)} key={index}>
            {title}
            <div className={styles.dot} />
          </Link>
        ))}
      </Dropdown>
    </div>
  </div>
);

PropertyCard.propTypes = {
  propertyName: PropTypes.string,
  address: PropTypes.string,
  roomsCount: PropTypes.number,
  id: PropTypes.number,
  image: PropTypes.string,
};

PropertyCard.defaultProps = {
  propertyName: 'No name',
  address: 'Unknown',
  roomsCount: 0,
  id: 0,
  image: LogoImg,
};

export default PropertyCard;
