import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import toastr from 'toastr';
import { object, string, array } from 'yup';
import http from '../../../services/http.service';
import { API } from '../../../constans/http';
import { Input, Textarea } from '../../../components/UI/Input/Input';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import Button from '../../../components/UI/Button/Button';
import RemoveModal from '../../../components/UI/RemoveModal/RemoveModal';
import InfoIcon from '../../../assets/img/icons/ic-info-white.svg';
import SideNav from '../../../components/SideNav/SideNav';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import { PROP_BRAND_MAX, REGEXP_NOSPACES } from '../../../constans/validation';
import { title, description } from '../../../constans/form-fields';
import {
  TITLE_REQUIRED,
  DESCR_REQUIRED,
  SPACES_ONLY,
  PROPERTY_EDIT_BRAND,
} from '../../../constans/error-messages';
import styles from './TermsConditions.module.scss';
import PlusIcon from '../../../assets/img/icons/plus.svg';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

const termsAndConditionsValidationSchema = object().shape({
  title: string()
    .required(TITLE_REQUIRED)
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_BRAND)
    .max(PROP_BRAND_MAX, PROPERTY_EDIT_BRAND),
  description: string()
    .required(DESCR_REQUIRED)
    .matches(REGEXP_NOSPACES, SPACES_ONLY),
});

const validationSchema = object().shape({
  termsAndConditions: array().of(termsAndConditionsValidationSchema),
});

const termsAndConditionsItemInitialValues = {
  title: '',
  description: '',
};

function TermsConditions({ id, path }) {
  const [initialValues, setInitialValues] = useState({
    termsAndConditions: [termsAndConditionsItemInitialValues],
  });

  const getConditions = () => {
    http
      .get(API.TERMS_CONDITIONS, { params: { propertyId: id } })
      .then((res) => {
        if (res?.data?.length > 0) {
          const termsData = res.data.map((termsItem) => ({
            title: termsItem.title,
            description: termsItem.description,
          }));
          setInitialValues({ termsAndConditions: termsData });
        }
      })
      .catch(() => {
        //  Do nothing ....
      });
  };

  const formSubmitHandler = ({ termsAndConditions }) => {
    const sendData = {
      termsAndConditions,
      propertyId: id,
    };

    http
      .put(API.TERMS_CONDITIONS, sendData)
      .then(() => {
        getConditions();
        toastr.success(SUCCESS_SEND_MSG);
      })
      .catch(() => {
        //  Do nothing;
      });
  };

  useEffect(() => {
    getConditions();
  }, []);

  return (
    <div className="pt-0 container property-edit-wrapper">
      <div className="row">
        <div className="col-12">
          <Breadcrumbs
            path={path}
            id={id}
          />
        </div>
        <SideNav propertyId={id} links={PROPERTY_EDIT_NAV} />
        <div className="col-12 col-lg-9">
          <Formik
            enableReinitialize
            onSubmit={formSubmitHandler}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form className={`${styles.form} edit-form-wrapper`}>
                <div>
                  <FieldArray
                    name="termsAndConditions"
                    render={(arrayHelpers) => (
                      <>
                        <div className={`${styles.title} property-edit-title`}>
                          <div
                            className={`${styles.titleInner} flex flex-h-start`}
                          >
                            <span>Terms and conditions</span>
                            <span className={styles.infoWrapper}>
                              <InfoIcon className={styles.infoIcon} />
                              <div className={`${styles.infoBlock} t-500`}>
                                You can edit and save different terms and
                                conditions in this section and easily select one
                                of them when creating new offers
                              </div>
                            </span>
                          </div>
                          <Button
                            disabled={!(values.termsAndConditions?.length < 5)}
                            onClick={() => arrayHelpers.push(
                              termsAndConditionsItemInitialValues,
                            )}
                            classes="edit-add-btn t-600 btn btn_bordered br-17 flex flex-center"
                            type="button"
                          >
                            <PlusIcon />
                            <span>Add new</span>
                          </Button>
                        </div>
                        {values.termsAndConditions.map((item, index) => (
                          <div
                            className={styles.inputBlock}
                            key={`awwe${index}`}
                          >
                            <InputWrapper containerClasses={styles.titleInput}>
                              <Label>Terms and Conditions title</Label>
                              <Input
                                type="text"
                                maxLength={PROP_BRAND_MAX}
                                name={`termsAndConditions.${index}.${title.name}`}
                                placeholder={title.placeholder}
                              />
                              <RemoveModal
                                showRemove
                                removeIconClasses={styles.removeIcon}
                                arrayHelpers={arrayHelpers}
                                index={index}
                                values={values.termsAndConditions}
                                modalSubtitle={`
                          Are you sure you want to remove 
                          this document? Removing will affect related offers.`}
                                modalSubmit={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <Label>Description</Label>
                              <Textarea
                                name={`termsAndConditions.${index}.${description.name}`}
                                placeholder={description.placeholder}
                                autocomplete="on"
                              />
                            </InputWrapper>
                            <hr className={styles.hr} />
                          </div>
                        ))}
                      </>
                    )}
                  />
                </div>
                <div className="flex flex-h-end property-edit-submit-block">
                  <Button
                    classes={`${styles.button} btn btn_mld btn_common btn-uppercased flex flex-center t-600`}
                    type="submit"
                  >
                    <span className="btn__text">Save Changes</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
