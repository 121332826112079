import React, { useEffect, useMemo, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { Radio } from 'antd';
import Checkbox from '../../../../components/UI/Checkbox/Checkbox';

import TermsAndConditionsService from '../../../../services/terms-and-conditions.service';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import CustomSelect from '../../../../components/UI/CustomSelect/CustomSelect';
import CustomCreatableSelect from '../../../../components/UI/CustomCreatableSelect/CustomCreatableSelect';
import FormikRadioGroup from '../../../../components/RadioGroup/RadioGroup';
import Label from '../../../../components/UI/Label/Label';
import { Input, Textarea } from '../../../../components/UI/Input/Input';
import {
  PRICE_TYPES_OPTIONS,
  TAX_OPTIONS,
  OFFER_TAX_TYPES,
  CANCELLATION_OPTIONS,
  PENALTY_TYPE_KEY,
  FIXED_AMOUNT,
  PERCENTAGE,
  ONE_NIGHT,
  CANCELLATION_DEFAULT_SELECTED_OPTION,
  EDIT_POLICY_CHANGEABLE,
  PAYMENT_TERMS_KEY,
  TIME_OF_BOOKING,
  BEFORE_CHEKIN_DATE,
  PAYMENT_AT_THE_PROPERTY,
  PAYMENTS_DEAD_LINE,
  TAX_TYPES_DETAILS_PAGE,
} from '../../../../constans/price-and-taxes';
import Button from '../../../../components/UI/Button/Button';
import { MAX_TAX_DESCRIPTION_LENGTH, TAX_MAX_LENGTH, TAX_MAX_AMOUNT } from '../../../../constans/validation';
import { integerPipe, moneyPipe } from '../../../../helpers/helper-methods';
import IconDelete from '../../../../assets/img/icons/ic-delete-default.svg';

import styles from './PriceAndTaxesTab.module.scss';
import commonStyles from '../CreateOffer.module.scss';

const PriceAndTaxesTab = ({
  values, propertyId, setFieldValue, errors, setFieldTouched, setFieldError
}) => {
  const termsService = useMemo(() => TermsAndConditionsService(), []);
  const [terms, setTerms] = useState([]);
  const [isCommentFieldShown, setIsCommentFieldShown] = useState(false);

  useEffect(() => {
    termsService.getTermsAndConditionByPropertyId({ propertyId })
      .then(({ data }) => setTerms(data.map(({ title, id: termId }) => ({
        label: title,
        value: termId,
      }))));
  }, []);

  return (
    <div>
      <p className={commonStyles.cardTitle}>
        Price before tax
      </p>
      <hr className={commonStyles.divider} />

      <div className={`flex flex-between ${commonStyles.inputWide}`}>
        <InputWrapper containerClasses={commonStyles.inputWide}>
          <Label>Base price</Label>
          <Input
            type="text"
            name="basePrice"
            placeholder="$"
            formater={moneyPipe}
          />
        </InputWrapper>
        <Field
          name="basePriceType"
          wrapperStyles={styles.radios}
          options={PRICE_TYPES_OPTIONS}
          labelClassName={styles.label}
          component={FormikRadioGroup}
        />
      </div>

      <div className={`flex flex-between ${commonStyles.inputWide}`}>
        <InputWrapper containerClasses={commonStyles.inputWide}>
          <Label>Additional price from second guest onward</Label>
          <Input
            type="text"
            name="pricePerGuest"
            formater={moneyPipe}
            placeholder="$"
          />
        </InputWrapper>
        <Field
          name="pricePerGuestType"
          wrapperStyles={styles.radios}
          labelClassName={styles.label}
          options={PRICE_TYPES_OPTIONS}
          component={FormikRadioGroup}
        />
      </div>

      <p className={commonStyles.cardTitle}>
        Taxes
      </p>
      <hr className={commonStyles.divider} />

      <div>
        <FieldArray
          name="taxes"
          render={(arrayHelpers) => (
            <>
              {values.taxes.map((tax, index) => {
                const inputOptions = [...TAX_OPTIONS];

                if (tax.title) {
                  const isAlreadyExist = TAX_OPTIONS
                    .findIndex((taxOption) => taxOption.value === tax.title);

                  if (isAlreadyExist < 0) {
                    inputOptions.push({
                      label: values.taxes[index].title,
                      value: values.taxes[index].title,
                    });
                  }
                }

                return (
                  <div key={index} className={`flex flex-between ${styles.taxesWrapper}`}>
                    <InputWrapper containerClasses={commonStyles.input}>
                      <Label>Name tax</Label>
                      <CustomCreatableSelect
                        maxLength={TAX_MAX_LENGTH}
                        options={inputOptions}
                        name={`taxes.${index}.title`}
                      />
                    </InputWrapper>
                    <InputWrapper containerClasses={commonStyles.input}>
                      <Label>Tax value</Label>
                      <Input
                        name={`taxes.${index}.price`}
                        type="number"
                        containerStyles={tax.type === PERCENTAGE && styles.percentage}
                        placeholder="Enter tax"
                        formater={moneyPipe}
                      />
                    </InputWrapper>
                    <Field
                      name={`taxes.${index}.type`}
                      wrapperStyles={styles.taxTypes}
                      labelClassName={styles.label}
                      options={OFFER_TAX_TYPES}
                      component={FormikRadioGroup}
                    />
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className={styles.taxRemove}
                    >
                      <IconDelete />
                    </button>
                  </div>
                );
              })}
              <div className={styles.buttonsWrapper}>
                <Button
                  type="button"
                  disabled={values?.taxes.length >= TAX_MAX_AMOUNT}
                  classes={`${styles.addTax} btn btn-uppercased t-600`}
                  onClick={() => arrayHelpers.push({
                    title: '',
                    type: OFFER_TAX_TYPES[1].value,
                    price: '',
                  })}
                >
                  + Add tax
                </Button>
                <Button
                  type="button"
                  disabled={!!values.taxesDescription?.length || isCommentFieldShown}
                  classes={`${styles.addTax} btn btn-uppercased t-600`}
                  onClick={() => setIsCommentFieldShown(true)}
                >
                  + Add comment
                </Button>
              </div>

              {(values.taxesDescription || isCommentFieldShown) && (
                <Textarea
                  containerStyles={styles.textarea}
                  placeholder="Comment"
                  maxLength={MAX_TAX_DESCRIPTION_LENGTH}
                  name="taxesDescription"
                />
              )}
            </>
          )}
        />
      </div>

      <p className={commonStyles.cardTitle}>
        Edit policy
      </p>
      <hr className={commonStyles.divider} />

      <div>
        <Radio.Group
          name={EDIT_POLICY_CHANGEABLE}
          value={values.editPolicyChanges}
          className="flex flex-col"
          onChange={({ target }) => {
            setFieldValue(EDIT_POLICY_CHANGEABLE, target.value)

            if (target.value) {
              setFieldValue("editPolicy.dates", values.editPolicy.dates || true)
              setFieldValue("editPolicy.roomTypes", values.editPolicy.roomTypes || true)
              setFieldValue("editPolicy.mainGuestName", values.editPolicy.roomTypes || true)

              setFieldTouched("editPolicy.dates", true)
              setFieldTouched("editPolicy.roomTypes", true)
              setFieldTouched("editPolicy.mainGuestName", true)

              setFieldError("editPolicy", "")

            }
          }}
        >
          <Radio value={false} className={`${styles.child} ${styles.radiosLabel} ${styles.label}`}>
            No changes allowed
          </Radio>
          <Radio value className={`${styles.child} ${styles.radiosLabel} ${styles.label}`}>
            Changes allowed:
          </Radio>
        </Radio.Group>
        <hr className={`${commonStyles.divider} ${styles.marginedTop}`} />
        <div className={`${styles.editPolicyCheckboxWrapper} ${!values.editPolicyChanges ? styles.disabled : ''}`}>
          <div className={`${styles.editPolicyCheckboxWrapperInner} flex flex-v-center`}>
            <Checkbox
              containerClasses={styles.editPolicyCheckboxItem}
              textClasses={styles.checkBoxText}
              checkboxClasses="c-custom__check-bordered-primary c-custom__check-md"
              name="editPolicy.dates"
              labelClasses={`${styles.checkBoxLabel} flex-inline flex-v-center`}
            >
              <div>Dates (subject to prevailing price)</div>
            </Checkbox>
            <Checkbox
              containerClasses={styles.editPolicyCheckboxItem}
              textClasses={styles.checkBoxText}
              checkboxClasses="c-custom__check-bordered-primary c-custom__check-md"
              name="editPolicy.roomTypes"
              labelClasses={`${styles.checkBoxLabel} flex-inline flex-v-center`}
            >
              <div>Room type (subject to prevailing price)</div>
            </Checkbox>
            <Checkbox
              containerClasses={styles.editPolicyCheckboxItem}
              textClasses={styles.checkBoxText}
              checkboxClasses="c-custom__check-bordered-primary c-custom__check-md"
              name="editPolicy.mainGuestName"
              labelClasses={`${styles.checkBoxLabel} flex-inline flex-v-center`}
            >
              <div>Main guest name</div>
            </Checkbox>
          </div>
          {errors?.editPolicy ? <div className="error-msg-blocked">{errors?.editPolicy}</div> : null}
        </div>
      </div>

      <p className={commonStyles.cardTitle}>
        Cancellation policy
      </p>
      <hr className={commonStyles.divider} />
      <div className={styles.cancellationTitle}>
        Cancellation penalty:
      </div>

      <Radio.Group
        name={PENALTY_TYPE_KEY}
        value={values.cancellationPenaltyType}
        className="flex flex-col"
        onChange={({ target }) => setFieldValue(PENALTY_TYPE_KEY, target.value)}
      >
        <Radio value={CANCELLATION_DEFAULT_SELECTED_OPTION} className={`${styles.child} ${styles.radiosLabel} ${styles.label}`}>
          Non-refundable
        </Radio>
        <Radio value={ONE_NIGHT} className={`${styles.child} ${styles.radiosLabel} ${styles.label}`}>
          1 night
        </Radio>
        <div className={`flex ${styles.child}`}>
          <Radio value={PERCENTAGE} className={`${styles.radiosLabel} ${styles.label}`}>
            Percentage of the transaction amount
          </Radio>
          <InputWrapper containerClasses={styles.inputWrapper}>
            <Input
              type="text"
              inputWrapperStyles={styles.input}
              containerStyles={styles.percentage}
              name="percentageOfPenalty"
              placeholder={TAX_TYPES_DETAILS_PAGE.PERCENTAGE}
              formater={integerPipe}
              readOnly={values[PENALTY_TYPE_KEY] !== PERCENTAGE}
            />
          </InputWrapper>
        </div>
        <div className={`flex ${styles.child}`}>
          <Radio value={FIXED_AMOUNT} className={`${styles.radiosLabel} ${styles.label}`}>
            Other amount
          </Radio>
          <InputWrapper containerClasses={styles.inputWrapper}>
            <Input
              inputWrapperStyles={styles.input}
              name="amountOfPenalty"
              type="text"
              placeholder="$"
              readOnly={values[PENALTY_TYPE_KEY] !== FIXED_AMOUNT}
              formater={moneyPipe}
            />
          </InputWrapper>
        </div>
      </Radio.Group>

      <div className={styles.cancellationValues}>
        <div className={styles.cancellationTitle}>
          Cancellation deadline:
        </div>
        <Field
          name="cancellationDeadlineInHours"
          options={CANCELLATION_OPTIONS}
          labelClassName={styles.label}
          wrapperStyles={styles.deadline}
          component={FormikRadioGroup}
        />
      </div>

      <div className={styles.paymentTermsWrapper}>
        <div className={styles.cancellationTitle}>
          Payment terms:
        </div>
        <Radio.Group
          name={PAYMENT_TERMS_KEY}
          value={values[PAYMENT_TERMS_KEY]}
          className="flex flex-col"
          onChange={({ target }) => setFieldValue(PAYMENT_TERMS_KEY, target.value, false)}
        >
          <Radio value={TIME_OF_BOOKING} className={`${styles.child} ${styles.radiosLabel} ${styles.label}`}>
            100% at the time of booking
          </Radio>
          <div className={`flex ${styles.child}`}>
            <Radio value={BEFORE_CHEKIN_DATE} className={`${styles.radiosLabel} ${styles.label}`}>
              100% [x] days before check-in date
            </Radio>
            <InputWrapper containerClasses={styles.inputWrapper}>
              <Input
                inputWrapperStyles={styles.input}
                name={PAYMENTS_DEAD_LINE}
                type="number"
                placeholder="# of days"
                readOnly={values[PAYMENT_TERMS_KEY] !== BEFORE_CHEKIN_DATE}
              />
            </InputWrapper>
          </div>
          <Radio value={PAYMENT_AT_THE_PROPERTY} className={`${styles.child} ${styles.radiosLabel} ${styles.label}`}>
            Full payment at the Property
          </Radio>
        </Radio.Group>
      </div>

      <p className={commonStyles.cardTitle}>
        Terms and conditions
      </p>
      <hr className={commonStyles.divider} />
      <InputWrapper
        containerClasses={commonStyles.input}
      >
        <Label>Terms and conditions</Label>
        <CustomSelect
          options={terms}
          placeholder="Choose T&C"
          name="termsAndConditionId"
        />
      </InputWrapper>
    </div>
  );
};

export default PriceAndTaxesTab;
