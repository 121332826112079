import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import RestaurantsService from '../../../services/restaurants.service';
import RoomsServicesService from '../../../services/rooms-services.service';
import SideNav from '../../../components/SideNav/SideNav';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import RestaurauntCard from '../../../components/RestaurauntCard/RestaurauntCard';
import { MAX_RESTAURANTS } from '../../../constans/validation';
import RoomService from '../../../components/RoomService/RoomService';
import { useWindowWidth } from '../../../hooks/useWindowWidth';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import PlusIcon from '../../../assets/img/icons/ic-plus-active.svg';
import { PHONE_WIDTH_BREAKPOINT } from '../../../constans/header';

import styles from './FoodAndBeverages.module.scss';

const FoodAndBeverages = ({ id, path }) => {
  const [restaurants, setRestauraunts] = useState([]);
  const [roomsServices, setRoomsServices] = useState({});

  const restaurantsService = useMemo(() => RestaurantsService(), []);
  const roomsServicesService = useMemo(() => RoomsServicesService(), []);
  const windowWidth = useWindowWidth();

  const onDeleteItem = useCallback(
    (deletedRestaurauntId) => {
      setRestauraunts(
        restaurants.filter(
          ({ id: restaurantId }) => restaurantId !== deletedRestaurauntId,
        ),
      );
    }, [restaurants],
  );

  const handleAddNewClick = () => {
    restaurantsService
      .createRestaurant({ propertyId: id })
      .then(({ data }) => setRestauraunts([...restaurants, data]));
  };

  useEffect(() => {
    restaurantsService
      .getRestaurantsByPropertyId({ propertyId: id })
      .then(({ data }) => setRestauraunts(data));
    roomsServicesService
      .getRoomsService({ propertyId: id })
      .then(({ data }) => setRoomsServices(data));
  }, []);

  return (
    <div className="pt-0 container property-edit-wrapper">
      <Breadcrumbs path={path} id={id} />
      <div className={`row flex ${styles.pageWrapper}`}>
        <SideNav propertyId={id} links={PROPERTY_EDIT_NAV} />
        <div className="col-12 col-lg-9">
          <div className={styles.content}>
            <div
              className={`${styles.headline} flex flex-v-center flex-between`}
            >
              <p className={styles.title}>Food & Beverages</p>
              <button
                type="button"
                className={`flex flex-v-center ${styles.add}`}
                onClick={handleAddNewClick}
                disabled={restaurants.length === MAX_RESTAURANTS}
              >
                {windowWidth > PHONE_WIDTH_BREAKPOINT ? (
                  <>
                    <PlusIcon className={styles.plus} />
                    Add new
                  </>
                ) : (
                  <PlusIcon className={styles.plus} />
                )}
              </button>
            </div>
            {restaurants.map(
              ({
                id: restaurauntId, title, description, photos,
              }) => (
                <RestaurauntCard
                  title={title}
                  photos={photos}
                  id={restaurauntId}
                  description={description}
                  key={restaurauntId}
                  onDeleteCallback={onDeleteItem}
                />
              ),
            )}
            <div className={styles.headline}>
              <div className={styles.title}>Room service</div>
            </div>
            {roomsServices?.id && <RoomService propertyId={id} services={roomsServices} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodAndBeverages;
