import http from './http.service';
import { fileUploads, updateFileStatus } from '../helpers/file-upload';
import { transformRoomAmenities } from '../helpers/helper-methods';

const basePath = '/rooms';

// Add limit
export const getRoomsList = (model) => http.get(basePath, { params: { ...model } });

export const createRoom = (propertyId, data) => http.post(basePath, { propertyId, ...data });

export const getRoomGeneralInfo = (roomId) => http.get(`${basePath}/${roomId}`);

export const editRoomGeneralInfo = (roomId, data) => http.patch(`${basePath}/${roomId}`, data);

export const getRoomPhotos = (roomId) => http.get(`${basePath}/${roomId}/photos`);

export const createRoomPhotos = (roomId, photos) => http.post(`${basePath}/${roomId}/photos`, photos);

export const deleteRoomPhotos = (roomId, photosIds) => http.delete(`${basePath}/${roomId}/photos`, { data: { photosIds } });

export const getRoomAmenities = (roomId) => http.get(`${basePath}/${roomId}/amenities`);

export const getRoomGeneralAmenities = () => http.get('/amenities/general');

export const editRoomAmenities = (roomId, amenities) => http.put(`${basePath}/${roomId}/amenities`, { amenities });

export const getRoomBedSettings = (roomId) => http.get('/bed-configurations', { params: { roomId } });

export const editRoomBedSettings = (roomId, bedConfigurations) => http.put('/bed-configurations', { roomId, bedConfigurations });

export const sendRoomPhotos = async (photos, roomId) => {
  // Send photos to s3
  const filesIds = await fileUploads([...photos.map(({ file }) => file)]);

  await updateFileStatus(filesIds);

  // Tie up photos id to room
  const roomPhotosData = {
    photos: filesIds.map((fileId) => ({ fileId })),
  };
  await createRoomPhotos(roomId, roomPhotosData);
};

export const sendBedSettings = async (bedConfigurations, roomId) => {
  const bedConfigWithUpdatedAmount = bedConfigurations.map((bedConfig) => ({
    ...bedConfig, amount: bedConfig.bedTypes.length,
  }));

  await editRoomBedSettings(roomId, bedConfigWithUpdatedAmount);
};

export const sendRoomAmenities = async (amenities, roomId) => {
  let amenitiesData = {};
  const {
    filteredGeneralAmenities,
    transformedCustomAmenities,
  } = transformRoomAmenities(amenities);

  amenitiesData = [...filteredGeneralAmenities, ...transformedCustomAmenities];

  await editRoomAmenities(roomId, amenitiesData);
};
