/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props,indent,react/jsx-closing-bracket-location */
import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import toastr from 'toastr';
import {
  object, array, string, number,
} from 'yup';
import http from '../../../services/http.service';
import SideNav from '../../../components/SideNav/SideNav';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import styles from './Facilities.module.scss';
import CheckboxBlock from '../../../components/UI/CheckboxBlock/CheckboxBlock';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import { Input } from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import PlusIcon from '../../../assets/img/icons/plus.svg';
import SaveIcon from '../../../assets/img/icons/ic-save-colorable.svg';
import RemoveModal from '../../../components/UI/RemoveModal/RemoveModal';
import KeyFacility from './KeyFacility/KeyFacility';
import { currenciesService } from '../../../services/currencies.service';
import { API } from '../../../constans/http';
import { KEY_FACILITIES } from '../../../constans/form-fields';
import {
  PROP_BRAND_MAX,
  PROP_DESCR_MAX,
  REGEXP_NOSPACES,
} from '../../../constans/validation';
import {
  PROPERTY_EDIT_BRAND,
  PROPERTY_EDIT_DESCR,
  FACILITIY_TITLE_REQUIRED,
  COMMENT_REQUIRED,
  FACILITY_PRICE_REQUIRED,
} from '../../../constans/error-messages';
import { accessibilityValidationSchema } from '../../../constans/validation-schemas';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import {
  objectIsNotEmpty,
  createNotEmptyFieldsObject,
  removeEmptyObjectKeys,
} from '../../../helpers/helper-methods';
import FormikPatchTouched from '../../../components/FormikPatchTouched/FormikPatchTouched';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { MAX_CUSTOM_FACILITIES } from '../../../constans/facilities';

// eslint-disable-next-line max-len
const paidOptionValue = KEY_FACILITIES.facilityRadioStatus.values[KEY_FACILITIES.NAOptionIndex].value;

const facilitiesValidationSchema = object().shape({
  facilities: array().of(accessibilityValidationSchema),
  parking: object().shape({
    currency: string().when('status', {
      is: paidOptionValue,
      then: string().required(FACILITY_PRICE_REQUIRED),
    }),
    price: number().when('status', {
      is: paidOptionValue,
      then: number()
        .required(FACILITY_PRICE_REQUIRED)
        .positive(FACILITY_PRICE_REQUIRED),
    }),
    interval: string().when('status', {
      is: paidOptionValue,
      then: string().required(FACILITY_PRICE_REQUIRED),
    }),
    status: string().required(FACILITY_PRICE_REQUIRED),
    description: string()
      .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
      .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR)
      .when('status', {
        is: paidOptionValue,
        then: string()
          .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
          .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR),
      }),
    // .when('status', {
    //   is: 'NOT_AVAILABLE',
    //   then: string()
    //     .required(COMMENT_REQUIRED)
    //     .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    //     .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR),
    // }),
  }),
  wifi: object().shape({
    currency: string().when('status', {
      is: paidOptionValue,
      then: string().required(FACILITY_PRICE_REQUIRED),
    }),
    price: number().when('status', {
      is: paidOptionValue,
      then: number()
        .required(FACILITY_PRICE_REQUIRED)
        .positive(FACILITY_PRICE_REQUIRED),
    }),
    interval: string().when('status', {
      is: paidOptionValue,
      then: string().required(FACILITY_PRICE_REQUIRED),
    }),
    status: string().required(FACILITY_PRICE_REQUIRED),
    description: string()
      .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
      .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR)
      .when('status', {
        is: paidOptionValue,
        then: string()
          .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
          .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR),
      }),
    // .when('status', {
    //   is: 'NOT_AVAILABLE',
    //   then: string().required(COMMENT_REQUIRED)
    //     .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    //     .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR),
    // }),
  }),
});

const countDefaultFacilities = (list) => list.filter(({ isRequired }) => isRequired).length;

const Facilities = ({ id, path }) => {
  const defaultFacilitiesCount = useRef(null);
  const [showCreatableBlock, setShowCreatableBlock] = useState(false);
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    parking: {
      currency: '',
      description: '',
      price: '',
      status: 'FREE',
      interval: '',
    },
    wifi: {
      currency: '',
      description: '',
      price: '',
      status: 'FREE',
      interval: '',
    },
    facilityTitle: '',
    facilities: [],
  });

  const toggleCreatableBlock = () => setShowCreatableBlock((prevState) => !prevState);

  const fetchData = async () => {
    try {
      let initDataValues = {
        ...initialValues,
      };
      const currenciesOptionsData = await currenciesService();

      const keyFacilitiesResp = await http.get(API.KEY_FACILITIES, {
        params: { propertyId: id },
      });

      if (
        Object.prototype.hasOwnProperty.call(keyFacilitiesResp.data, 'parking')
      ) {
        initDataValues.parking = {
          currency: keyFacilitiesResp.data.parking.currency
            ? keyFacilitiesResp.data.parking.currency.code
            : '',
          description: keyFacilitiesResp.data.parking.description,
          price: keyFacilitiesResp.data.parking.price,
          status: keyFacilitiesResp.data.parking.status,
          interval: keyFacilitiesResp.data.parking.interval,
        };
      }

      if (
        Object.prototype.hasOwnProperty.call(keyFacilitiesResp.data, 'wifi')
      ) {
        initDataValues.wifi = {
          currency: keyFacilitiesResp.data.wifi.currency
            ? keyFacilitiesResp.data.wifi.currency.code
            : '',
          description: keyFacilitiesResp.data.wifi.description,
          price: keyFacilitiesResp.data.wifi.price,
          status: keyFacilitiesResp.data.wifi.status,
          interval: keyFacilitiesResp.data.wifi.interval,
        };
      }

      const facilitiesResp = await http.get(API.FACILITIES, {
        params: { propertyId: id },
      });

      defaultFacilitiesCount.current = countDefaultFacilities(facilitiesResp.data);

      if (facilitiesResp.data.length > 0) {
        const facilities = facilitiesResp.data.map((facilityItem) => ({
          isRequired: facilityItem.isRequired,
          title: facilityItem.title,
          description: facilityItem.description,
          check: facilityItem.isActive,
        }));

        initDataValues = {
          ...initialValues,
          ...initDataValues,
          facilities,
        };
      }

      if (objectIsNotEmpty(initDataValues)) {
        setInitialValues(initDataValues);
      }
      setCurrenciesOptions(currenciesOptionsData);
      setIsLoading(false);
    } catch (_e) {
      //  Do nothing . . .
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formSubmitHandler = async ({ parking, wifi, facilities }) => {
    setIsLoading(true);
    try {
      const filteredParking = createNotEmptyFieldsObject(parking);
      const filteredWifi = createNotEmptyFieldsObject(wifi);
      const keyFacilitiesData = {
        propertyId: id,
      };
      // eslint-disable-next-line max-len
      if (parking.status === paidOptionValue) {
        keyFacilitiesData.parking = {
          currency: filteredParking.currency,
          ...filteredParking.description && {
            description: filteredParking.description
          },
          price: filteredParking.price,
          status: filteredParking.status,
          interval: filteredParking.interval,
        };
      } else {
        keyFacilitiesData.parking = {
          ...filteredParking.description && {
            description: filteredParking.description
          },
          status: filteredParking.status,
        };
      }

      // eslint-disable-next-line max-len
      if (wifi.status === paidOptionValue) {
        keyFacilitiesData.wifi = {
          currency: filteredWifi.currency,
          ...filteredWifi.description && {
            description: filteredWifi.description
          },
          price: filteredWifi.price,
          status: filteredWifi.status,
          interval: filteredWifi.interval,
        };
      } else {
        keyFacilitiesData.wifi = {
          ...filteredWifi.description && {
            description: filteredWifi.description
          },
          status: filteredWifi.status,
        };
      }
      const filteredFacilities = facilities
        .filter((item) => item.check)
        .map((item) => (removeEmptyObjectKeys({
          title: item.title,
          description: item.description,
        })));
      const facilitiesData = {
        facilities: filteredFacilities,
        propertyId: id,
      };

      await http.put(API.KEY_FACILITIES, keyFacilitiesData);
      await http.put(API.FACILITIES, facilitiesData);
      toastr.success(SUCCESS_SEND_MSG);
      setIsLoading(false);
    } catch (e) {
      // Do noting...
    }
  };

  const createFacility = (
    fieldName,
    fieldValue,
    setFieldTouched,
    setFieldError,
    arrayHelpers,
    setFieldValue,
  ) => {
    setFieldTouched(fieldName);
    if (fieldValue.trim()) {
      setFieldError(fieldName, '');
      setFieldValue(fieldName, '');
      arrayHelpers.push({
        title: fieldValue,
        description: '',
        check: false,
      });
    } else {
      //  Custom validation for creatable field
      if (!fieldValue) {
        setFieldError(fieldName, FACILITIY_TITLE_REQUIRED);
        return;
      }
      setFieldError(fieldName, '');

      if (!REGEXP_NOSPACES.test(fieldValue)) {
        setFieldError(fieldName, PROPERTY_EDIT_BRAND);
      } else {
        setFieldError(fieldName, '');
      }
    }
  };

  return (
    <div className="pt-0 container property-edit-wrapper flex">
      <div className="row flex-grow-1">
        <div className="col-12">
          <Breadcrumbs
            path={path}
            id={id}
          />
        </div>
        <SideNav links={PROPERTY_EDIT_NAV} propertyId={id} />
        <div className="col-12 col-lg-9">
          <Formik
            enableReinitialize
            onSubmit={formSubmitHandler}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={facilitiesValidationSchema}
            initialValues={initialValues}
          >
            {({
              values,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              touched,
              errors,
            }) => (
              <Form className={`${styles.form} edit-form-wrapper`}>
                <FormikPatchTouched />
                <div className="property-edit-title">Parking & Wi-fi</div>
                <div
                  className={`${styles.keyFacilitiesWrapper} flex flex-between`}
                >
                  <KeyFacility
                    touched={touched}
                    errors={errors.parking}
                    priceInputsDisabled={values.parking.status !== paidOptionValue}
                    title="Parking availability"
                    containerStyles={styles.keyFacilityContainer}
                    currenciesOptions={currenciesOptions}
                    currencyInputName={`parking.${KEY_FACILITIES.currency.name}`}
                    currencyInputPlaceholder=""
                    priceInputName={`parking.${KEY_FACILITIES.priceFacility.name}`}
                    priceInputPlaceholder={KEY_FACILITIES.priceFacility.placeHolder}
                    intervalInputName={`parking.${KEY_FACILITIES.interval.name}`}
                    intervalOptions={KEY_FACILITIES.interval.values}
                    intervalInputPlaceholder={KEY_FACILITIES.interval.placeholder}
                    descriptionInputName={`parking.${KEY_FACILITIES.descriptionFacility.name}`}
                    descriptionInputPlaceholder={KEY_FACILITIES.descriptionFacility.placeHolder}
                    radioButtonName={`parking.${KEY_FACILITIES.facilityRadioStatus.name}`}
                    radioButtonsValues={KEY_FACILITIES.facilityRadioStatus.values}
                  />
                  <KeyFacility
                    touched={touched}
                    errors={errors.wifi}
                    priceInputsDisabled={values.wifi.status !== paidOptionValue}
                    title="Wi-fi availability"
                    containerStyles={styles.keyFacilityContainer}
                    currenciesOptions={currenciesOptions}
                    currencyInputName={`wifi.${KEY_FACILITIES.currency.name}`}
                    currencyInputPlaceholder=""
                    priceInputName={`wifi.${KEY_FACILITIES.priceFacility.name}`}
                    priceInputPlaceholder={KEY_FACILITIES.priceFacility.placeHolder}
                    intervalInputName={`wifi.${KEY_FACILITIES.interval.name}`}
                    intervalOptions={KEY_FACILITIES.interval.values}
                    intervalInputPlaceholder={KEY_FACILITIES.interval.placeholder}
                    descriptionInputName={`wifi.${KEY_FACILITIES.descriptionFacility.name}`}
                    descriptionInputPlaceholder={KEY_FACILITIES.descriptionFacility.placeHolder}
                    radioButtonName={`wifi.${KEY_FACILITIES.facilityRadioStatus.name}`}
                    radioButtonsValues={KEY_FACILITIES.facilityRadioStatus.values}
                  />
                </div>
                <FieldArray
                  name="facilities"
                  render={(arrayHelpers) => (
                    <>
                      <div
                        className={`${styles.title} property-edit-title flex flex-between flex-v-center`}
                      >
                        <span>List of Facilities</span>
                        <Button
                          disabled={
                            isLoading
                            || !(values.facilities?.length < defaultFacilitiesCount.current + MAX_CUSTOM_FACILITIES)
                          }
                          onClick={toggleCreatableBlock}
                          classes={`
                            ${showCreatableBlock ? 'active' : ''}
                            edit-add-btn t-600 btn btn_bordered br-17 flex flex-center
                          `}
                          type="button"
                        >
                          <PlusIcon />
                          <span>Add key facilities</span>
                        </Button>
                      </div>
                      {showCreatableBlock && values.facilities?.length < defaultFacilitiesCount.current + MAX_CUSTOM_FACILITIES
                        ? (
                          <div
                            className={`${styles.facilityCreatableBlock} flex`}
                          >
                            <InputWrapper
                              containerClasses={styles.facilityCreatableTitle}
                            >
                              <Label>Facility title</Label>
                              <Input
                                type="text"
                                maxLength={PROP_BRAND_MAX}
                                name="facilityTitle"
                                placeholder="Enter title here"
                              />
                              <SaveIcon
                                className={styles.icon}
                                onClick={() => createFacility(
                                  'facilityTitle',
                                  values.facilityTitle,
                                  setFieldTouched,
                                  setFieldError,
                                  arrayHelpers,
                                  setFieldValue,
                                )}
                              />
                            </InputWrapper>
                          </div>
                        )
                        : null}
                      <div className="row">
                        {values.facilities
                          && values.facilities.map((checkBlock, index) => (
                            <div
                              key={`a5r${index}`}
                              className="col-md-12 col-xl-6"
                            >
                              <CheckboxBlock
                                commentDisabled={!checkBlock?.check}
                                checkboxMarkClasses={styles.checkbox}
                                titleValue={`${values.facilities[index].title}`}
                                containerClasses={styles.accessibilityWrapper}
                                commentName={`facilities.${index}.description`}
                                inputName={`facilities.${index}.title`}
                                checkBoxName={`facilities.${index}.check`}
                              />
                              {!checkBlock?.isRequired ? (
                                <RemoveModal
                                  modalWrapperClasses={styles.removeModal}
                                  showRemove
                                  removeIconClasses={styles.removeIcon}
                                  arrayHelpers={arrayHelpers}
                                  index={index}
                                  values={values.locations}
                                  modalSubtitle="Do you want to remove this item?"
                                  modalSubmit={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                />
                              ) : null}
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                />
                <div className="flex flex-h-end property-edit-submit-block">
                  <Button
                    classes={`${styles.button} btn btn_mld btn_common btn-uppercased flex flex-center t-600`}
                    type="submit"
                    disabled={isLoading}
                  >
                    <span className="btn__text">Save Changes</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Facilities;
