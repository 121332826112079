import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import toastr from 'toastr';

import RestaurantsService from '../../services/restaurants.service';
import { Input, Textarea } from '../UI/Input/Input';
import RemoveModal from '../UI/RemoveModal/RemoveModal';
import UploadIcon from '../../assets/img/icons/ic-cloud-small.svg';
import SaveIcon from '../../assets/img/icons/ic-save-default.svg';
import CloseIcon from '../../assets/img/icons/close.svg';
import { fileUploads, updateFileStatus } from '../../helpers/file-upload';
import {
  DESCR_REQUIRED, PROPERTY_EDIT_BRAND, ROOM_DESCR_INVALID, TITLE_REQUIRED,
} from '../../constans/error-messages';
import Spinner from '../UI/Spinner/Spinner';
import {
  MAX_PHOTO_SIZE,
  MAX_PHOTO_PER_LOAD,
  MAX_PHOTO_PER_RESTAURAUNT,
  MAX_RESTAURAUNT_TITLE_LENGTH,
  MAX_RESTAURAUNT_DESCRIPTION_LENGTH,
  REGEXP_NOSPACES,
  PHOTO_FORMAT,
} from '../../constans/validation';

import styles from './RestaurauntCard.module.scss';
import { SUCCESS_SEND_MSG } from '../../constans/texts/texts';

const schema = Yup.object().shape({
  title: Yup.string()
    .required(TITLE_REQUIRED)
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_BRAND)
    .max(MAX_RESTAURAUNT_TITLE_LENGTH),
  description: Yup.string()
    .required(DESCR_REQUIRED)
    .matches(REGEXP_NOSPACES, ROOM_DESCR_INVALID)
    .max(MAX_RESTAURAUNT_DESCRIPTION_LENGTH),
});

const RestaurauntCard = ({
  id,
  title,
  description,
  photos,
  onDeleteCallback,
}) => {
  const service = useMemo(() => RestaurantsService(), []);
  const [files, setFiles] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [existingPhotos, setExistingPhotos] = useState(photos);
  const [isLoading, setIsLoading] = useState(false);
  const totalPhotosAfterRequest = existingPhotos.length + files.length - toDelete.length;
  const canAddNewPhotos = totalPhotosAfterRequest <= MAX_PHOTO_PER_RESTAURAUNT;
  const isDisabled = files.length >= MAX_PHOTO_PER_LOAD
    || !canAddNewPhotos
    || totalPhotosAfterRequest >= MAX_PHOTO_PER_RESTAURAUNT;

  const initialValues = {
    id: id || 0,
    title: title || '',
    description: description || '',
  };

  const onDrop = (acceptedFiles) => setFiles(
    acceptedFiles.map((file) => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })),
  );

  const onDropRejected = () => {
    /* todo: get error texts */
  };

  const onDeleteRestaurant = () => service.deleteRestaurant(id).then(() => {
    onDeleteCallback(id);
  });

  const onDeleteExistingPhoto = (photoId) => {
    setToDelete([...toDelete, photoId]);
    setExistingPhotos(existingPhotos.filter(({ id: _id }) => _id !== photoId));
  };

  const onDeleteNewPhoto = (name) => {
    setFiles(files.filter(({ name: photoName }) => photoName !== name));
  };

  const bindFilesToRestaurant = (fileIds) => service
    .addRestaurantPhotos(id, fileIds)
    .then(({ data }) => {
      setExistingPhotos([...existingPhotos, ...data]);
      setFiles([]);
    });

  const uploadFiles = (filesToUpload) => fileUploads(filesToUpload).then((res) => {
    updateFileStatus(res).then(() => bindFilesToRestaurant(res));
  });

  const deletePhotos = (photosIds) => service
    .deleteRestaurantPhotos(id, { photosIds })
    .then(() => setToDelete([]));

  const onSave = ({ id: restorauntId, ...otherData }) => {
    setIsLoading(true);
    const requests = [service.editRestaurant(restorauntId, { ...otherData })];

    if (toDelete.length) {
      requests.push(deletePhotos(toDelete));
    }

    if (files.length) {
      requests.push(uploadFiles(files));
    }

    Promise.all(requests)
      .then(() => toastr.success(SUCCESS_SEND_MSG))
      .finally(() => setTimeout(() => setIsLoading(false), 1000));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: MAX_PHOTO_SIZE,
    maxFiles: MAX_PHOTO_PER_LOAD,
    accept: PHOTO_FORMAT,
    disabled: isDisabled,
    noKeyboard: true,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Cafe / restaurant / bar name</div>
      <Formik
        validateOnBlur={false}
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSave}
      >
        <Form>
          <div className={`flex ${styles.inputWrapper}`}>
            <Input
              containerStyles={styles.inputContainer}
              type="text"
              name="title"
              placeholder="Cafe / restaurant / bar name"
              maxLength={MAX_RESTAURAUNT_TITLE_LENGTH}
            />
            <RemoveModal
              showRemove
              showClose
              titleClassName={styles.modalTitle}
              subtitleClassName={styles.modalText}
              footerClassName={styles.modalFooter}
              removeIconClasses={`${styles.removeIcon} ${styles.desktopOnly}`}
              modalTitle={`Delete ${title || 'Cafe'}?`}
              modalSubtitle="Are you sure you want to delete this cafe / restaurant / bar information?"
              modalSubmit={onDeleteRestaurant}
            />
            <button
              disabled={!canAddNewPhotos || isLoading}
              className={`${styles.iconButton} ${styles.desktopOnly}`}
              type="submit"
            >
              <SaveIcon className={styles.save} />
            </button>
          </div>

          <div className={styles.images}>
            {isLoading ? (
              <Spinner spinnerClasses={styles.spinner} />
            ) : (
              <>
                <div
                  {...getRootProps()}
                  className={`
                       ${styles.dropzone}
                       ${isDragActive && styles.dropzoneActive}
                       ${isDisabled && styles.dropzoneDisabled}
                     `}
                >
                  <input {...getInputProps()} />
                  <UploadIcon />
                  <p className={`${styles.dropzoneText} t-500`}>
                    Drag &amp; Drop or
                  </p>
                  <p className={styles.dropzoneText}>Choose the files</p>
                </div>

                {existingPhotos.map(({ file: { link }, id: photoId }) => (
                  <div key={photoId} className={styles.image}>
                    <img src={link} alt={photoId} />
                    <button
                      type="button"
                      className={styles.imageDelete}
                      onClick={() => onDeleteExistingPhoto(photoId)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))}

                {files.map(({ preview, name }) => (
                  <div key={name} className={styles.image}>
                    <img src={preview} alt={name} />
                    <button
                      type="button"
                      className={styles.imageDelete}
                      onClick={() => onDeleteNewPhoto(name)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
          <div className={styles.title}>Description</div>
          <Textarea maxLength={100} name="description" placeholder="Enter description" />
          <div
            className={`${styles.mobileOnly} flex flex-v-center flex-center`}
          >
            <RemoveModal
              showRemove
              showClose
              titleClassName={styles.modalTitle}
              subtitleClassName={styles.modalText}
              footerClassName={styles.modalFooter}
              removeIconClasses={styles.removeIcon}
              modalTitle={`Delete ${title || 'Cafe'}?`}
              modalSubtitle="Are you sure you want to delete this cafe / restaurant / bar information?"
              modalSubmit={onDeleteRestaurant}
            />
            <button
              disabled={!canAddNewPhotos || isLoading}
              className={styles.iconButton}
              type="submit"
            >
              <SaveIcon className={styles.save} />
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

RestaurauntCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onDeleteCallback: PropTypes.func,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      updatedAt: PropTypes.string,
      file: PropTypes.shape({
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        id: PropTypes.number,
        type: PropTypes.string,
        isUsed: PropTypes.bool,
        link: PropTypes.string,
        name: PropTypes.string,
        status: PropTypes.string,
        userId: PropTypes.number,
      }),
    }),
  ),
};

RestaurauntCard.defaultProps = {
  title: '',
  description: '',
  onDeleteCallback: () => {
    /* */
  },
  photos: [],
};

export default RestaurauntCard;
