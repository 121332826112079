import React from 'react';
import OfferDetailsSection from '../../../../components/OfferDetailsSection';

import {
  AFTER_BOOKING, FIXED_AMOUNT, PENALTY_TYPES, PERCENTAGE, PRICE_TYPES, TAX_TYPES_DETAILS_PAGE,
} from '../../../../constans/price-and-taxes';
import { formatCurrency } from '../../../../helpers/helper-methods';

import commonStyles from '../OfferDetails.module.scss';
import styles from './Price-and-taxes.module.scss';

const PriceAndTaxes = ({
  basePrice,
  pricePerGuest,
  basePriceType,
  pricePerGuestType,
  taxes,
  taxesDescription,
  termsAndCondition,
  cancellationPenaltyType,
  cancellationDeadlineInHours,
  percentageOfPenalty,
  amountOfPenalty,
  property,
}) => {
  const isFixed = cancellationPenaltyType === FIXED_AMOUNT;
  const isPercentage = cancellationPenaltyType === PERCENTAGE;
  const currencyCode = property?.currency?.code;

  return (
    <>
      <div className="row flex flex-between">
        <OfferDetailsSection
          title="Base price"
          value={`${formatCurrency(currencyCode, basePrice)} ${PRICE_TYPES[basePriceType]}`}
        />

        <OfferDetailsSection
          title="Additional price from second guest onward"
          value={`${formatCurrency(currencyCode, pricePerGuest)} ${PRICE_TYPES[pricePerGuestType]}`}
        />
      </div>

      <hr className={commonStyles.divider} />

      <div className={`flex ${commonStyles.sectionTitle}`}>
        Taxes
      </div>

      <div className={`row ${styles.taxes}`}>
        {taxes?.map(({ price, title, type }, index) => (
          <OfferDetailsSection
            key={index}
            title={title}
            value={type === PERCENTAGE
              ? `${price}${TAX_TYPES_DETAILS_PAGE[type]}`
              : formatCurrency(currencyCode, price)}
          />
        ))}
      </div>

      <p className={styles.comment}>
        {taxesDescription}
      </p>

      <hr className={commonStyles.divider} />

      <div className={`flex ${commonStyles.sectionTitle}`}>
        cancellation policy
      </div>
      <div className="row flex flex-between">
        <OfferDetailsSection
          title="Cancellation deadline"
          value={
            cancellationDeadlineInHours
              ? `${cancellationDeadlineInHours} hours`
              : AFTER_BOOKING
          }
        />
        <OfferDetailsSection
          title="cancellation penalty"
          value={PENALTY_TYPES[cancellationPenaltyType]}
        />
      </div>
      {(isFixed || isPercentage)
        && (
          <div className="row flex flex-between">
            <OfferDetailsSection
              title={`${isPercentage ? 'Percentage' : 'Amount'} of penalty`}
              value={isFixed
                ? formatCurrency(currencyCode, amountOfPenalty)
                : `${percentageOfPenalty}${TAX_TYPES_DETAILS_PAGE.PERCENTAGE}`}
            />
          </div>
        )}
      <hr className={commonStyles.divider} />

      <div className={`flex ${commonStyles.sectionTitle}`}>
        Terms and conditions
      </div>

      <div className={`row ${styles.taxes}`}>
        <OfferDetailsSection
          cols="col-12"
          title={termsAndCondition?.title}
          value={termsAndCondition?.description}
          descriptionClassName={styles.textWrap}
        />
      </div>
    </>
  );
};

export default PriceAndTaxes;
