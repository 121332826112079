/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import PropertiesService from '../../../services/properties.service';

import CreateOffer from '../create-offer/CreateOffer';

const EditOffer = ({ id, offerId, path }) => {
  const service = useMemo(() => PropertiesService(), []);
  const [offerData, setOfferData] = useState();

  useEffect(() => {
    service.getOfferDetails(id, offerId)
      .then(({ data }) => {
        const {
          id: _offerId,
          room,
          termsAndCondition,
          noTimeLimit,
          startDate,
          endDate,
          property: _property,
          ...otherOfferData
        } = data;

        setOfferData({
          termsAndConditionId: termsAndCondition?.id,
          roomId: room?.id,
          startDate: noTimeLimit ? '' : startDate,
          endDate: noTimeLimit ? '' : endDate,
          noTimeLimit,
          editPolicyChanges: otherOfferData?.editPolicy
            ? Object.values(otherOfferData.editPolicy).some((item) => item) : false,
          ...otherOfferData,
        });
      });
  }, []);

  return (
    !!offerData && (
      <CreateOffer
        id={id}
        path={path}
        offerId={offerId}
        prefilledOfferData={offerData}
      />
    )
  );
};

export default EditOffer;
