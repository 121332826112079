/* eslint-disable no-param-reassign,no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import ReactTable from 'react-table-v6';
import { SEARCH_MIN_LENGTH } from '../../constans/tables';
import TablePagination from './TablePagination/TablePagination';

const Table = ({
  tableData,
  columns,
  fetchDataHandler,
  defaultPageSize,
  paginState,
  rowClickHandler,
  searchQuery,
}) => {
  const tableRef = useRef(null);
  const isFirstRender = useRef(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if ((searchQuery?.length > SEARCH_MIN_LENGTH || !searchQuery) && !isFirstRender.current) {
      fetchDataHandler(tableRef.current.state, tableRef.current);
    }
  }, [searchQuery]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return (
    <ReactTable
      ref={tableRef}
      search={searchQuery}
      minRows={0}
      defaultPageSize={defaultPageSize}
      PaginationComponent={TablePagination}
      data={tableData} // should default to []
      columns={columns}
      pages={paginState} // should default to -1 (which means we don't know how many pages we have)
      loading={loading}
      manual
      resizable={false}
      multiSort={false}
      getTdProps={(state, rowInfo, _column, _instance) => ({
        onClick: (e, handleOriginal) => {
          if (rowClickHandler) {
            rowClickHandler(rowInfo);
          }
          if (handleOriginal) {
            handleOriginal();
          }
        },
      })}
      onFetchData={(state, instance) => {
        setLoading(true);
        fetchDataHandler(state, instance)
          .then(() => setLoading(false));
      }}
    />
  );
};

Table.defaultProps = {
  defaultPageSize: 7,
  fetchDataHandler: (state, setData, setPages) => new Promise((resolve, reject) => resolve(1)),
  searchQuery: '',
};

export default Table;
