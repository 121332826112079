import React, { useState, Children, useRef } from 'react';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';

import useOutsideClick from '../../hooks/useOutsideClick';

import styles from './OfferCardDropdown.module.scss';

const OfferCardDropdown = ({
  togglerText,
  children,
  togglerClassName,
  activeTogglerClassName,
  dropdownClassName,
}) => {
  const [visible, setVisibility] = useState(false);
  const componentRef = useRef();

  const handleDropdownClick = () => {
    setVisibility(!visible);
  };

  useOutsideClick(componentRef, () => {
    if (visible) setVisibility(false);
  });

  return (
    <div ref={componentRef}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <button
              className={`
              ${togglerClassName}
              ${visible && activeTogglerClassName}
            `}
              type="button"
              ref={ref}
              onClick={handleDropdownClick}
            >
              {togglerText}
            </button>
          )}
        </Reference>
        <Popper placement="bottom-end">
          {({ ref, style }) => (
            <div
              ref={ref}
              style={{
                ...style,
                zIndex: visible ? '1' : '-999',
                opacity: visible ? '1' : '0',
              }}
              className={`
              ${styles.dropdownContent}
              ${dropdownClassName}
              ${visible && activeTogglerClassName}
            `}
            >
              {Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child, { onClick: handleDropdownClick });
                }
                return child;
              })}
            </div>
          )}
        </Popper>
      </Manager>
    </div>
  );
};

OfferCardDropdown.propTypes = {
  togglerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  togglerClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  activeTogglerClassName: PropTypes.string,
};

OfferCardDropdown.defaultProps = {
  togglerText: 'Dropdown',
  togglerClassName: '',
  dropdownClassName: '',
  activeTogglerClassName: '',
};

export default OfferCardDropdown;
