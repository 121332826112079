import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import moment from 'moment';

import PropertiesService from '../../services/properties.service';
import OfferCardDropdown from '../OfferCardDropdown/OfferCardDropdown';
import IconCalendar from '../../assets/img/icons/ic-calendar-disable.svg';
import IconDuration from '../../assets/img/icons/ic-duration-disable.svg';
import IconMore from '../../assets/img/icons/ic-more.svg';
import { PRICE_TYPES } from '../../constans/price-and-taxes';
import { DEFAULT_FORMAT } from '../../constans/formats';
import { formatCurrency } from '../../helpers/helper-methods';

import styles from './OfferCard.module.scss';

const FEATURES_TO_SHOW = 5;

const OfferCard = ({
  id,
  code,
  title,
  endDate,
  features,
  children,
  startDate,
  basePrice,
  propertyId,
  onDeleteClick,
  pricePerGuest,
  basePriceType,
  pricePerGuestType,
  minimumStayDuration,
  noTimeLimit,
  property,
}) => {
  const displayedFeatures = features.slice(0, FEATURES_TO_SHOW);
  const totalFeatures = features.length;
  const service = useMemo(() => PropertiesService(), []);
  const { currency } = property;

  const handleDuplicate = () => {
    service.duplicateOffer(propertyId, id)
      .then(({ data }) => navigate(
        `/sellers/my-properties/${propertyId}/offers/${data.id}/edit`,
      ));
  };

  return (
    <div className={styles.card}>
      <div className={`flex ${styles.cardHeader}`}>
        <div className={styles.child}>
          {children}
        </div>
        <div className={`${styles.stayDuration} flex flex-v-center`}>
          <IconCalendar className={styles.icon} />
          <p className="mb-0">
            {!noTimeLimit ? `${moment(startDate).format(DEFAULT_FORMAT)} - ${moment(endDate).format(DEFAULT_FORMAT)}` : "No time limit"}
          </p>
        </div>
        <div className={`${styles.stayDuration} flex`}>
          <IconDuration className={styles.icon} />
          {`${minimumStayDuration} night(s)`}
        </div>

        <div className={styles.moreButtonWrapper}>
          <OfferCardDropdown
            togglerClassName={styles.more}
            togglerText={<IconMore className={styles.iconMore} />}
          >
            <Link
              className={styles.dropdownButton}
              to={`/sellers/my-properties/${propertyId}/offers/${id}`}
            >
              Detail view
            </Link>
            <Link
              className={styles.dropdownButton}
              to={`/sellers/my-properties/${propertyId}/offers/${id}/edit`}
            >
              Edit
            </Link>
            <div>
              <button
                type="button"
                onClick={handleDuplicate}
                className={styles.dropdownButton}
              >
                Duplicate
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => onDeleteClick(id)}
                className={`
                 ${styles.dropdownButtonDanger}
                 ${styles.dropdownButton}
                `}
              >
                Delete
              </button>
            </div>
          </OfferCardDropdown>
        </div>
      </div>
      <Link
        to={`/sellers/my-properties/${propertyId}/offers/${id}`}
        className={`${styles.cardTitle} text-truncate`}
      >
        {`${code} - ${title}`}
      </Link>
      <div className={styles.cardLine} />

      <div className={`${styles.cardFooter} flex`}>
        <div className={`flex flex-wrap ${styles.features}`}>
          {displayedFeatures.map(({ title: featureTitle, id: featureId }) => (
            <div key={featureId} className={`${styles.feature} flex flex-v-center`}>
              <div className={styles.featureDot} />
              {featureTitle}
            </div>
          ))}
          {totalFeatures > FEATURES_TO_SHOW && (
            <div className={styles.otherFeatures}>
              {`+ ${totalFeatures - displayedFeatures.length} features`}
            </div>
          )}
        </div>
        <div className={styles.price}>
          <p className={styles.priceTitle}>Offer price:</p>
          <p>
            {formatCurrency(currency?.code, basePrice)}
            <span className={styles.priceType}>{PRICE_TYPES[basePriceType]}</span>
          </p>
          {pricePerGuest && (
            <>
              <p className={styles.priceTitle}>
                Additional price from second guest onward:
              </p>
              <p>
                {formatCurrency(currency?.code, pricePerGuest)}
                <span className={styles.priceType}>{PRICE_TYPES[pricePerGuestType]}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

OfferCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  minimumStayDuration: PropTypes.number.isRequired,
  basePrice: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  basePriceType: PropTypes.string.isRequired,
  code: PropTypes.string,
  pricePerGuest: PropTypes.number,
  pricePerGuestType: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    isGeneral: PropTypes.bool,
    maxNumberOfGuests: PropTypes.number,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
  })),
  onDeleteClick: PropTypes.func,
};

OfferCard.defaultProps = {
  features: [],
  code: '[duplicate]',
  pricePerGuest: 0,
  pricePerGuestType: '',
  onDeleteClick: () => { },
};

export default OfferCard;
