import React, { useState, useEffect } from 'react';
import http from '../../../services/http.service';

import Button from '../../../components/UI/Button/Button';
import SellerSettingsWrapper from '../SellerSettingsWrapper/SellerSettingsWrapper';
import Spinner from '../../../components/UI/Spinner/Spinner';
import StripeIcon from '../../../assets/img/icons/im_stripe.svg';
import { API } from '../../../constans/http';
import { stripeConnectHandler } from '../../../helpers/helper-methods';

import styles from './SellerPaymentAccount.module.scss';

const SellerPaymentAccount = () => {
  const [loading, setLoading] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const toggleLoading = () => setLoading((prevState) => !prevState);

  useEffect(() => {
    http.get(API.SUPPLIERS_STRIPE_GET_ACCOUNT)
      .then(({ data }) => {
        setStripeData(data);
      });
  }, []);

  return (
    <SellerSettingsWrapper>
      <div
        className={`${styles.title} property-edit-title flex flex-between flex-v-center`}
      >
        Payment account
      </div>
      {!loading ? (
        <div className={`
            flex
            ${stripeData ? `${styles.editAccountBlockWrapper} flex-between flex-v-end` : `${styles.connectBtnWrapper} flex-h-center`}
          `}
        >
          { stripeData ? (
            <>
              <div>
                {stripeData?.email ? (
                  <div className={styles.editAccountInfoBlock}>
                    <div className={`${styles.editAccountInfoBlockTitle} t-500`}>
                      Linked to Stripe account:
                    </div>
                    <div className={`${styles.editAccountInfoBlockText} uppercased-text t-600`}>
                      {stripeData.email}
                    </div>
                  </div>
                ) : null}
                <div className={styles.editAccountInfoBlock}>
                  <div className={`${styles.editAccountInfoBlockTitle} t-500`}>
                    Stripe account charge status:
                  </div>
                  <div className={`
                      ${styles.editAccountInfoBlockText} 
                      ${stripeData.chargesEnabled ? styles.successText : styles.errorText} 
                      uppercased-text t-600
                    `}
                  >
                    {stripeData.chargesEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                </div>
                <div className={styles.editAccountInfoBlock}>
                  <div className={`${styles.editAccountInfoBlockTitle} t-500`}>
                    Stripe account details filled status:
                  </div>
                  <div className={`
                      ${styles.editAccountInfoBlockText}
                      ${stripeData.detailsSubmitted ? styles.successText : styles.errorText} 
                      uppercased-text t-600
                    `}
                  >
                    {stripeData.detailsSubmitted ? 'Completed' : 'Not completed'}
                  </div>
                </div>
              </div>
              <Button
                classes={`${styles.editAccountBtn} btn btn-uppercased br-17 t-600`}
                onClick={() => stripeConnectHandler(toggleLoading)}
              >
                change account
              </Button>
            </>
          ) : (
            <Button
              classes={`${styles.connectBtn} btn btn-uppercased btn_brs-28 flex flex-center t-600`}
              onClick={() => stripeConnectHandler(toggleLoading)}
            >
              <span className={`${styles.text} btn_text`}>
                link to
              </span>
              <StripeIcon className={styles.icon} />
            </Button>
          )}
        </div>
      ) : <Spinner spinnerClasses="spinner-primary" />}

    </SellerSettingsWrapper>
  );
};

export default SellerPaymentAccount;
