import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { object, string, array } from 'yup';
import toastr from 'toastr';
import http from '../../../services/http.service';
import { API } from '../../../constans/http';
import { Input, Textarea } from '../../../components/UI/Input/Input';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import Button from '../../../components/UI/Button/Button';
import SideNav from '../../../components/SideNav/SideNav';
import CheckboxBlock from '../../../components/UI/CheckboxBlock/CheckboxBlock';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import {
  PROP_DESCR_MAX,
  PROP_BRAND_MAX,
  REGEXP_NOSPACES,
  PROPERTY_BIG_DESCR_MAX,
} from '../../../constans/validation';
import {
  PROPERTY_EDIT_BRAND,
  PROPERTY_EDIT_GENERAL_INTRO_REQUIRED,
  PROPERTY_EDIT_DESCR,
  CURRENCY_REQUIRED,
} from '../../../constans/error-messages';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import { createNotEmptyFieldsObject } from '../../../helpers/helper-methods';
import { accessibilityValidationSchema } from '../../../constans/validation-schemas';
import styles from './GeneralInfo.module.scss';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import { currenciesService } from '../../../services/currencies.service';

const validationSchema = object().shape({
  accessibilityDescription: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROP_DESCR_MAX),
  environmentalDescription: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROPERTY_BIG_DESCR_MAX),
  socialAndEthicalDescription: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROPERTY_BIG_DESCR_MAX),
  brandDescription: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_BRAND)
    .max(PROP_BRAND_MAX, PROPERTY_EDIT_BRAND),
  generalDescription: string()
    // .required(PROPERTY_EDIT_GENERAL_INTRO_REQUIRED)
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR),
  aboutUsDescription: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROPERTY_BIG_DESCR_MAX),
  accessibility: array().of(accessibilityValidationSchema),
  currency: string().required(CURRENCY_REQUIRED),
});

const accessibilityBase = [
  {
    title: 'Wheelchair accessible',
    description: '',
    check: false,
  },
  {
    title: 'Rooms with roll-in shower',
    description: '',
    check: false,
  },
  {
    title: 'Lift to floors',
    description: '',
    check: false,
  },
];

const GeneralInfo = ({ id, path }) => {
  const [initialValues, setInitialValues] = useState({
    title: '',
    currency: '',
    generalDescription: '',
    accessibilityDescription: '',
    brandDescription: '',
    aboutUsDescription: '',
    environmentalDescription: '',
    socialAndEthicalDescription: '',
  });
  const [currenciesOptions, setCurrenciesOptions] = useState([]);

  const formSubmitHandler = async ({
    generalDescription,
    accessibilityDescription,
    brandDescription,
    aboutUsDescription,
    environmentalDescription,
    socialAndEthicalDescription,
    accessibility,
    currency,
  }) => {
    try {
      const generalInfoArray = {
        generalDescription,
        accessibilityDescription,
        brandDescription,
        aboutUsDescription,
        environmentalDescription,
        socialAndEthicalDescription,
        currency,
      };
      const generalInfoData = createNotEmptyFieldsObject(generalInfoArray);
      const accessibilities = accessibility
        .filter((item) => item.check)
        .map(({ title, description }) => {
          const model = { title };

          if (description) {
            model.description = description;
          }

          return model;
        });

      await http.patch(`${API.PROPERTIES}/${id}`, generalInfoData, {
        params: { propertyId: id },
      });
      await http.put(
        `${API.PROPERTIES}/${id}/accessibilities`,
        { accessibilities },
        { params: { propertyId: id } },
      );
      toastr.success(SUCCESS_SEND_MSG);
    } catch (e) {
      //  Do nothing . . .
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await http.get(`${API.PROPERTIES}/${id}`);
      const currenciesOptionsData = await currenciesService();
      const infoInitialValues = {
        title: data?.title || '',
        currency: data?.currency?.code || '',
        generalDescription: data?.generalDescription || '',
        brandDescription: data?.brandDescription || '',
        aboutUsDescription: data?.aboutUsDescription || '',
        environmentalDescription: data?.environmentalDescription || '',
        socialAndEthicalDescription: data?.socialAndEthicalDescription || '',
        accessibilityDescription: data?.accessibilityDescription || '',
      };

      const accessibilityInfoResponse = await http.get(
        `${API.PROPERTIES}/${id}/accessibilities`,
      );
      const accessibilityInfoMap = {};

      accessibilityInfoResponse.data.forEach((item) => {
        accessibilityInfoMap[item.title] = {
          title: item.title,
          description: item.description,
          check: item.isActive,
        };
      });

      infoInitialValues.accessibility = accessibilityBase.map(
        (item) => accessibilityInfoMap[item.title] || item,
      );

      setInitialValues(infoInitialValues);
      setCurrenciesOptions(currenciesOptionsData);
    } catch (error) {
      //  Do nothing...
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className="pt-0 container property-edit-wrapper flex">
      <div className="row flex-grow-1">
        <div className="col-12">
          <Breadcrumbs
            path={path}
            id={id}
          />
        </div>
        <SideNav links={PROPERTY_EDIT_NAV} propertyId={id} />
        <div className="col-12 col-lg-9">
          <Formik
            enableReinitialize
            onSubmit={formSubmitHandler}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form className={`${styles.form} edit-form-wrapper`}>
                <div className={`${styles.title} property-edit-title`}>
                  General info
                </div>
                <div className="row">
                  <InputWrapper containerClasses="col-md-12 col-lg-9">
                    <Label>Property title</Label>
                    <Input readOnly type="text" name="title" />
                  </InputWrapper>
                  <InputWrapper containerClasses="col-md-12 col-lg-3">
                    <Label>Currency</Label>
                    <CustomSelect
                      placeholder="EUR"
                      name="currency"
                      options={currenciesOptions}
                    />
                  </InputWrapper>
                </div>

                <div className={`${styles.title} property-edit-title`}>
                  Descriptions
                </div>
                <InputWrapper>
                  <Label>General intro and description</Label>
                  <Textarea
                    type="text"
                    maxLength={PROP_DESCR_MAX}
                    name="generalDescription"
                    rows={6}
                    placeholder="Enter general intro and description"
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>Chain / brand</Label>
                  <Input
                    type="text"
                    maxLength={PROP_BRAND_MAX}
                    name="brandDescription"
                    placeholder="Enter chain / brand"
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>About us / values / word from management</Label>
                  <Textarea
                    maxLength={PROPERTY_BIG_DESCR_MAX}
                    name="aboutUsDescription"
                    placeholder="Enter about text"
                  />
                </InputWrapper>
                <FieldArray
                  name="accessibility"
                  render={() => (
                    <>
                      <div className={`${styles.title} property-edit-title`}>
                        Accessibility
                      </div>
                      <div className="row">
                        {values.accessibility
                          && values.accessibility.map((checkBlock, index) => (
                            <div
                              key={`aw5r${index}`}
                              className="col-md-12 col-xl-6"
                            >
                              <CheckboxBlock
                                titleValue={`${values.accessibility[index].title}`}
                                containerClasses={styles.accessibilityWrapper}
                                commentName={`accessibility.${index}.description`}
                                inputName={`accessibility.${index}.title`}
                                checkBoxName={`accessibility.${index}.check`}
                              />
                            </div>
                          ))}
                        <InputWrapper containerClasses="col-12">
                          <Label>Accessibility description</Label>
                          <Textarea
                            maxLength={PROP_DESCR_MAX}
                            name="accessibilityDescription"
                            placeholder="Enter Accessibility description text"
                          />
                        </InputWrapper>
                      </div>
                    </>
                  )}
                />
                <div className={`${styles.title} property-edit-title`}>
                  Environmental & Social
                </div>
                <InputWrapper>
                  <Label>Environmental policies, projects and actions</Label>
                  <Textarea
                    maxLength={PROPERTY_BIG_DESCR_MAX}
                    name="environmentalDescription"
                    placeholder="Enter Environmental Description"
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>Social policies, projects and actions</Label>
                  <Textarea
                    maxLength={PROPERTY_BIG_DESCR_MAX}
                    name="socialAndEthicalDescription"
                    placeholder="Enter Social Description"
                  />
                </InputWrapper>
                <div className="flex flex-h-end">
                  <Button
                    classes={`${styles.submitBtn} btn btn_common btn-uppercased flex flex-center t-600`}
                    type="submit"
                  >
                    <span className="btn__text">Save Changes</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default GeneralInfo;
