import React, { useEffect, useState } from 'react';
import {
  object, string, number, array,
} from 'yup';
import toastr from 'toastr';
import { FieldArray, Form, Formik } from 'formik';

import http from '../../../services/http.service';
import SideNav from '../../../components/SideNav/SideNav';
import { PROPERTY_EDIT_NAV } from '../../../constans/links';
import Button from '../../../components/UI/Button/Button';
import PlusIcon from '../../../assets/img/icons/plus.svg';
import CustomSelect from '../../../components/UI/CustomSelect/CustomSelect';
import InputWrapper from '../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../components/UI/Label/Label';
import { LOCATION_TRANSPORT } from '../../../constans/form-fields';
import RemoveModal from '../../../components/UI/RemoveModal/RemoveModal';
import LocationsInputsBlock from './LocationsInputsBlock/LocationsInputsBlock';
import LocationsSurroundingBlock from './LocationsSurroundingBlock/LocationsSurroundingBlock';
import FormikPatchTouched from '../../../components/FormikPatchTouched/FormikPatchTouched';
import {
  filterArrayForEmptyValues,
  filterObjForEmptyValues,
  convertKmToMeters,
  objectIsNotEmpty,
} from '../../../helpers/helper-methods';
import { API } from '../../../constans/http';
import { KM_TO_METERS } from '../../../constans/values';
import {
  NAME_REQUIRED,
  DISTANCE_REQUIRED,
  PROPERTY_EDIT_BRAND,
  PROPERTY_EDIT_DESCR,
  TYPE_REQUIRED,
  DISTANCE_INVALID,
  LOCATION_NAME_NO_SPACES,
} from '../../../constans/error-messages';
import {
  PROP_BRAND_MAX,
  PROP_DESCR_MAX,
  REGEXP_NOSPACES,
  DISTANCE_MAX,
} from '../../../constans/validation';
import { SUCCESS_SEND_MSG } from '../../../constans/texts/texts';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

import styles from './LocationsTransport.module.scss';

const locationTypeValidationSchema = object().shape({
  type: string().required(TYPE_REQUIRED),
});

const infoBlockValidationSchema = object().shape({
  title: string()
    .required(NAME_REQUIRED)
    .matches(REGEXP_NOSPACES, LOCATION_NAME_NO_SPACES)
    .max(PROP_BRAND_MAX, PROPERTY_EDIT_BRAND),
  distance: number()
    .required(DISTANCE_REQUIRED)
    .max(DISTANCE_MAX, DISTANCE_INVALID)
    .positive(DISTANCE_REQUIRED),
  description: string()
    .matches(REGEXP_NOSPACES, PROPERTY_EDIT_DESCR)
    .max(PROP_DESCR_MAX, PROPERTY_EDIT_DESCR),
});

const locationsValidationSchema = object()
  .concat(locationTypeValidationSchema)
  .concat(infoBlockValidationSchema);

const validationSchema = object().shape({
  locations: array().of(locationsValidationSchema),
  tourists: array().of(infoBlockValidationSchema),
  commercial: array().of(infoBlockValidationSchema),
  cultures: array().of(infoBlockValidationSchema),
  activities: array().of(infoBlockValidationSchema),
  natures: array().of(infoBlockValidationSchema),
  foods: array().of(infoBlockValidationSchema),
});

const surroundingsInitialValues = {
  title: LOCATION_TRANSPORT.locationName.initialValue,
  distance: LOCATION_TRANSPORT.distance.initialValue,
  description: LOCATION_TRANSPORT.locationComment.initialValue,
};

const locationsInitialValues = {
  title: LOCATION_TRANSPORT.locationName.initialValue,
  type: LOCATION_TRANSPORT.transportType.initialValue,
  distance: LOCATION_TRANSPORT.distance.initialValue,
  description: LOCATION_TRANSPORT.locationComment.initialValue,
};

const LocationsTransport = ({ id, path }) => {
  const [initialValues, setInitialValues] = useState({
    locations: [],
    tourists: [],
    commercials: [],
    cultures: [],
    activities: [],
    natures: [],
    foods: [],
  });

  const formSubmitHandler = async (values) => {
    try {
      const { locations, ...surroundings } = convertKmToMeters(values);
      const filteredLocations = filterArrayForEmptyValues(locations);
      const locationsData = {
        transports: [...filteredLocations],
        propertyId: id,
      };
      const surroundingsFiltered = filterObjForEmptyValues(surroundings);
      const surroundingsData = {
        ...surroundingsFiltered,
        propertyId: id,
      };

      await http.put(API.TRANSPORTS, locationsData);
      await http.put(API.SURROUNDINGS, surroundingsData);
      // toast.success(SUCCESS_SEND_MSG);

      toastr.success(SUCCESS_SEND_MSG);
    } catch (e) {
      // Do noting...
    }
  };

  const fetchData = async () => {
    try {
      let initialData = {};

      const surroundingsResponse = await http.get(API.SURROUNDINGS, {
        params: { propertyId: id },
      });
      const surrResponseKeysArray = Object.keys(surroundingsResponse.data);

      if (surrResponseKeysArray.length > 0) {
        surrResponseKeysArray.forEach((key) => {
          initialData[key] = surroundingsResponse.data[key].map((item) => ({
            title: item.title,
            description: item.description,
            distance: item.distance / KM_TO_METERS,
          }));
        });
        initialData = {
          ...initialValues,
          ...initialData,
        };
      }

      const transportResponse = await http.get(API.TRANSPORTS, {
        params: { propertyId: id },
      });

      const transportDataArr = transportResponse.data.map((transportItem) => ({
        title: transportItem.title,
        type: transportItem.type,
        distance: transportItem.distance / KM_TO_METERS,
        description: transportItem?.description
          ? transportItem.description
          : '',
      }));
      initialData = {
        ...initialValues,
        ...initialData,
        locations: transportDataArr,
      };

      if (objectIsNotEmpty(initialData)) {
        setInitialValues(initialData);
      }
    } catch (e) {
      // Do noting...
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className="pt-0 container property-edit-wrapper flex">
      <div className="row flex-grow-1">
        <div className="col-12">
          <Breadcrumbs path={path} id={id} />
        </div>
        <SideNav links={PROPERTY_EDIT_NAV} propertyId={id} />
        <div className="col-12 col-lg-9">
          <Formik
            enableReinitialize
            onSubmit={formSubmitHandler}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
          >
            {({ values }) => (
              <Form className={`${styles.form} edit-form-wrapper`}>
                <FormikPatchTouched />
                <div>
                  <FieldArray
                    name="locations"
                    render={(arrayHelpers) => (
                      <div>
                        <div className={`${styles.title} property-edit-title`}>
                          <span>Location and transports</span>
                          <Button
                            disabled={!(values.locations?.length < 5)}
                            onClick={() => arrayHelpers.push(locationsInitialValues)}
                            classes="edit-add-btn t-600 btn btn_bordered br-17 flex flex-center"
                            type="button"
                          >
                            <PlusIcon />
                            <span>Add new</span>
                          </Button>
                        </div>
                        {values.locations.map((location, index) => (
                          <div
                            className={`${styles.inputBlock} ${styles.locationInputBlock}`}
                            key={`qsz${index}`}
                          >
                            <InputWrapper
                              containerClasses={styles.selectInputWrapper}
                            >
                              <Label>Type of transport Hub</Label>
                              <CustomSelect
                                placeholder={
                                  LOCATION_TRANSPORT.transportType.placeholder
                                }
                                options={
                                  LOCATION_TRANSPORT.transportType.values
                                }
                                name={`locations.${index}.${LOCATION_TRANSPORT.transportType.name}`}
                              />
                              <RemoveModal
                                modalWrapperClasses={styles.removeModal}
                                showRemove
                                removeIconClasses={styles.removeIcon}
                                arrayHelpers={arrayHelpers}
                                index={index}
                                values={values.locations}
                                modalSubtitle="Do you want to remove this item?"
                                modalSubmit={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </InputWrapper>
                            <LocationsInputsBlock
                              wrapperStyles={styles.inputGroupWrapper}
                              titleStyles={styles.inputGroupTitle}
                              distanceStyles={styles.inputGroupDistance}
                              commentStyles={styles.inputGroupComment}
                              commentName={`locations.${index}.${LOCATION_TRANSPORT.locationComment.name}`}
                              distanceName={`locations.${index}.${LOCATION_TRANSPORT.distance.name}`}
                              titleName={`locations.${index}.${LOCATION_TRANSPORT.locationName.name}`}
                              values={values.locations[index]}
                            />
                            <hr className={styles.hr} />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <LocationsSurroundingBlock
                    title="Tourist sights and landmarks"
                    name="tourists"
                    valuesSurroundings={values.tourists}
                    initialValues={surroundingsInitialValues}
                  />
                  <LocationsSurroundingBlock
                    title="Commercial"
                    name="commercials"
                    valuesSurroundings={values.commercials}
                    initialValues={surroundingsInitialValues}
                  />
                  <LocationsSurroundingBlock
                    title="Cultural venues"
                    name="cultures"
                    valuesSurroundings={values.cultures}
                    initialValues={surroundingsInitialValues}
                  />
                  <LocationsSurroundingBlock
                    title="Activities"
                    name="activities"
                    valuesSurroundings={values.activities}
                    initialValues={surroundingsInitialValues}
                  />
                  <LocationsSurroundingBlock
                    title="Nature and views"
                    name="natures"
                    valuesSurroundings={values.natures}
                    initialValues={surroundingsInitialValues}
                  />
                  <LocationsSurroundingBlock
                    title="Food, restaurants and bars"
                    name="foods"
                    valuesSurroundings={values.foods}
                    initialValues={surroundingsInitialValues}
                  />
                </div>
                <div className="flex flex-h-end property-edit-submit-block">
                  <Button
                    classes={`${styles.submitBtn} btn btn_mld btn_common btn-uppercased flex flex-center t-600`}
                    type="submit"
                  >
                    <span className="btn__text">Save Changes</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LocationsTransport;
