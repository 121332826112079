import axios from 'axios';

import http from '../services/http.service';
import { API } from '../constans/http';

export const fileUploads = async (files) => {
  const filesIdArr = [];
  let filesMetaResponse = null;
  const filesTypesArray = files.map((file) => ({ contentType: file.type }));

  // Send files content type
  filesMetaResponse = await http.post(API.SAVE_FILES, filesTypesArray);

  // Convert response data from Amazon into formData object
  const filesFormDataArr = filesMetaResponse.data.map((fileData, index) => {
    const filesFormData = new FormData();

    filesIdArr.push(fileData.file.id);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fileData.meta.formData)) {
      filesFormData.append(key, value);
    }

    filesFormData.append('file', files[index]);

    const filesSendObj = {
      filesFormData,
      url: fileData.meta.url,
    };

    return filesSendObj;
  });

  // Create post request array for sending files
  // prettier-ignore
  const filesSendAxiosArray = filesFormDataArr.map((fileData) => axios
    .post(fileData.url, fileData.filesFormData));

  await Promise.all(filesSendAxiosArray);

  return filesIdArr;
};

export const updateFileStatus = (filesIds) => http.patch(API.SAVE_FILES, { filesIds });

export const connectFilesToSupplier = async (filesIdArr, filesData) => {
  await http.patch(API.SAVE_FILES, { filesIds: filesIdArr });

  // Tie up files id with supplies
  return filesIdArr.map((fileId, index) => ({
    fileId,
    title: filesData[index].title,
    description: filesData[index].description,
  }));
};
