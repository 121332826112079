import http from './http.service';
import { defaultParamsSerializer } from '../helpers/helper-methods';

const RoomsServicesService = () => {
  const basePath = '/room-services';

  const getRoomsService = (model) => http.get(basePath, {
    params: model,
    paramsSerializer: (params) => defaultParamsSerializer(params),
  });

  const createRoomsService = (model) => http.post(basePath, { ...model });

  const editPropertysRoomService = (model) => http.patch(basePath, { ...model });

  return {
    getRoomsService,
    createRoomsService,
    editPropertysRoomService,
  };
};

export default RoomsServicesService;
