import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../UI/Modal/Modal';
import IconPrev from '../../assets/img/icons/ic-arrow-left.svg';
import IconNext from '../../assets/img/icons/ic-arrow-right.svg';
import IconClose from '../../assets/img/icons/close.svg';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { PHONE_WIDTH_BREAKPOINT } from '../../constans/header';

import styles from './ImageModal.module.scss';

const ImageModal = ({
  images,
  show,
  activeIndex,
  onCloseClick,
  onArrowClick,
}) => {
  const total = images.length;
  const last = total - 1;
  const width = useWindowWidth();

  const onPrevButtonClick = () => onArrowClick((prevState) => {
    const nextState = prevState - 1;

    return nextState < 0 ? last : nextState;
  });

  const onNextButtonClick = () => onArrowClick((prevState) => {
    const nextState = prevState + 1;

    return nextState >= total ? 0 : nextState;
  });

  return (
    <Modal showClose={false} modalClasses={styles.imageModal} show={show}>
      <div className={`container ${styles.relative}`}>
        <button className={styles.close} type="button" onClick={onCloseClick}>
          <IconClose />
          {width > PHONE_WIDTH_BREAKPOINT && 'Close'}
        </button>
        <div className="flex">
          <p className={styles.imageCounter}>
            <span className={styles.imageCounterActive}>{activeIndex + 1}</span>
            {` / ${images.length}`}
          </p>
        </div>
        <div className={`flex ${styles.imageWrapper}`}>
          <button
            className={styles.button}
            onClick={onPrevButtonClick}
            type="button"
          >
            <IconPrev />
          </button>
          <img
            className={styles.image}
            src={images[activeIndex]?.link || images[activeIndex]?.file.link}
            alt="property"
          />
          <button
            className={`${styles.button} ${styles.buttonRight}`}
            onClick={onNextButtonClick}
            type="button"
          >
            <IconNext />
          </button>
        </div>
        <div className="flex">
          <p className={styles.imageDescription}>
            {images[activeIndex]?.description}
          </p>
        </div>
      </div>
    </Modal>
  );
};

ImageModal.propTypes = {
  show: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        link: PropTypes.string,
      }),
      description: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  onCloseClick: PropTypes.func,
  activeIndex: PropTypes.number,
};

ImageModal.defaultProps = {
  images: [],
  show: null,
  activeIndex: 0,
  onCloseClick: () => { },
};

export default ImageModal;
