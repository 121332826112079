/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import { Link, navigate } from 'gatsby';

import RoomsService from '../../../services/rooms.service';
import PropertiesService from '../../../services/properties.service';
import FeaturesService from '../../../services/features.service';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Button from '../../../components/UI/Button/Button';
import FormStepper from '../../../components/FormStepper/FormStepper';
import { filterByUniqueKey, removeEmptyObjectKeys } from '../../../helpers/helper-methods';
import GeneralOfferInfoTab from './GeneralOfferInfoTab/GeneralOfferInfoTab';
import IncludedFeaturesTab from './IncludedFeaturesTab/IncludedFeaturesTab';
import PriceAndTaxesTab from './PriceAndTaxesTab/PriceAndTaxesTab';
import { FEATURE_TYPES } from '../../../constans/features';
import {
  CANCELLATION_DEFAULT_SELECTED_OPTION,
  PRICE_TYPES_OPTIONS,
  CANCELLATION_DEADLINE_DEFAULT_SELECTED_OPTION,
  PERCENTAGE,
  FIXED_AMOUNT,
  TIME_OF_BOOKING,
  BEFORE_CHEKIN_DATE,
} from '../../../constans/price-and-taxes';
import { MAX_TOTAL_CAPACITY } from '../../../constans/roomsServices';
import {
  GeneralEditOfferTabValidationSchema,
  GeneralOfferTabValidationSchema,
  IncludedFeaturesTabValidationSchema,
  PriceAndTaxesTabValidationSchema,
} from './CreateOfferSchemas';

import styles from './CreateOffer.module.scss';

const navItems = [
  {
    key: 'generalInfo',
    navText: 'General, Dates & Inventory',
    component: GeneralOfferInfoTab,
    validationSchema: GeneralOfferTabValidationSchema,
    editOfferValidatoinSchema: GeneralEditOfferTabValidationSchema,
  },
  {
    key: 'features',
    navText: 'Included features',
    component: IncludedFeaturesTab,
    validationSchema: IncludedFeaturesTabValidationSchema,
    editOfferValidatoinSchema: IncludedFeaturesTabValidationSchema,
  },
  {
    key: 'priceAndTaxes',
    navText: 'Price, taxes and T&C',
    component: PriceAndTaxesTab,
    validationSchema: PriceAndTaxesTabValidationSchema,
    editOfferValidatoinSchema: PriceAndTaxesTabValidationSchema,
  },
];

const CreateOffer = ({
  id,
  prefilledOfferData,
  offerId,
  path,
}) => {
  const service = useMemo(() => PropertiesService(), []);
  const roomsService = useMemo(() => RoomsService(), []);
  const featuresService = useMemo(() => FeaturesService(), []);
  const [roomCategories, setRoomCategories] = useState([]);
  const [existingFeatures, setExistingFeatures] = useState({});
  const [activeNavIndex, setActiveNavIndex] = useState(0);

  const isLastStep = activeNavIndex === navItems.length - 1;
  const isFirstStep = activeNavIndex === 0;

  const getRoomCategories = () => roomsService.getRoomsByPropertyId({
    propertyId: id,
    shortResponse: false,
  }).then(({ data }) => {
    const uniqRooms = filterByUniqueKey(data, 'title')
      .map(({
        title,
        maximumCapacityForAdults,
        maximumCapacityForKids = 0,
        designedCapacityForAdults,
        designedCapacityForKids = 0,
        id: roomId,
      }) => ({
        label: title,
        value: roomId,
        maxCapacity: maximumCapacityForAdults + maximumCapacityForKids,
        designedCapacity: designedCapacityForAdults + designedCapacityForKids,
        designedCapacityForAdults,
        designedCapacityForKids,
      }));

    setRoomCategories(uniqRooms);
  });

  const processFeatures = () => {
    const prefilledFeatures = prefilledOfferData?.features;
    const temp = {
      [FEATURE_TYPES.SERVICE]: [],
      [FEATURE_TYPES.FOOD_AND_BEVERAGES]: [],
      [FEATURE_TYPES.OTHER]: [],
    };

    featuresService.getGeneralFeatures()
      .then(({ data }) => data
        .map(({
          id: featureId, title, isGeneral, maxNumberOfGuests, type,
        }) => {
          const foundItem = prefilledFeatures?.find((item) => item.id === featureId);

          if (foundItem) {
            return {
              featureId,
              title,
              isGeneral,
              type,
              maxNumberOfGuests: foundItem.maxNumberOfGuests || '',
              selected: true,
            };
          }

          return {
            featureId, title, isGeneral, maxNumberOfGuests, type, selected: false,
          };
        }))
      .then((data) => data.map((feature) => temp[feature.type].push(feature)))
      .then(() => prefilledFeatures?.filter(({
        isGeneral,
      }) => (!isGeneral)))
      .then((customFeatures) => customFeatures?.map(({
        title,
        maxNumberOfGuests,
        isGeneral,
      }) => ({
        title,
        isGeneral,
        maxNumberOfGuests: maxNumberOfGuests || '',
        selected: true,
      })))
      .then((customFeatures) => {
        if (customFeatures) {
          temp[FEATURE_TYPES.OTHER].push(...customFeatures);
        }
        setExistingFeatures(temp);
      });
  };

  const switchNextTab = () => {
    setActiveNavIndex((prevState) => (activeNavIndex < navItems.length - 1
      ? prevState + 1
      : prevState));
  };

  const generateFeaturesList = (features, maxGuestPerFeature) => Object.values(features)
    .flat()
    .filter(({ selected }) => (selected))
    .map(({ title }) => ({
      title,
      maxNumberOfGuests: parseInt(maxGuestPerFeature, 10) || undefined,
    }));

  const handleSubmit = (values) => {
    const {
      propertyId,
      newOtherFeatureTitle,
      maxRoomCapacity,
      taxes,
      createdAt,
      updatedAt,
      cancellationPenaltyType,
      percentageOfPenalty,
      amountOfPenalty,
      cancellationDeadlineInHours,
      editPolicy,
      editPolicyChanges,
      paymentTermsType,
      paymentDeadlineInDays,
      maxGuestPerFeature,
      noTimeLimit,
      ...model
    } = values;

    const filteredModel = removeEmptyObjectKeys(model);

    const paymentTermsData = {
      paymentTermsType,
    };

    if (paymentTermsType === BEFORE_CHEKIN_DATE) {
      paymentTermsData.paymentDeadlineInDays = paymentDeadlineInDays;
    }

    if (isLastStep) {
      const features = generateFeaturesList(values.features, maxGuestPerFeature);
      const processedTaxes = taxes.map(({ price, title, type }) => ({ price, title, type }));

      filteredModel.cancellationDeadlineInHours = cancellationDeadlineInHours;
      filteredModel.cancellationPenaltyType = cancellationPenaltyType;

      if (cancellationPenaltyType === PERCENTAGE) {
        filteredModel.percentageOfPenalty = percentageOfPenalty;
      }

      if (cancellationPenaltyType === FIXED_AMOUNT) {
        filteredModel.amountOfPenalty = amountOfPenalty;
      }

      if (!editPolicyChanges) {
        Object.keys(editPolicy).forEach((key) => {
          editPolicy[key] = false;
        });
      }

      filteredModel.noTimeLimit = noTimeLimit

      const dataToSend = {
        ...filteredModel, features, taxes: processedTaxes, editPolicy, ...paymentTermsData,
      };

      const offerDataResp = offerId
        ? service.editOffer(id, offerId, dataToSend) : service.createOffer(id, dataToSend);

      offerDataResp
        .then(({ data: { id: offerNewId } }) => navigate(`/sellers/my-properties/${id}/offers/${offerNewId}`));
    } else switchNextTab();
  };

  useEffect(() => {
    getRoomCategories();
    processFeatures();
  }, [prefilledOfferData]);

  const switchPrevTab = () => {
    setActiveNavIndex((prevState) => prevState - 1);
  };

  const TabToDisplay = navItems[activeNavIndex].component;

  const initialValues = {
    title: '',
    code: '',
    roomId: '',
    inventoryNumber: 1,
    maxNumberOfGuests: '',
    noTimeLimit: prefilledOfferData ? prefilledOfferData.noTimeLimit : true,
    startDate: '',
    endDate: '',
    minimumStayDuration: 1,
    basePrice: '',
    basePriceType: PRICE_TYPES_OPTIONS[0].value,
    pricePerGuest: '',
    pricePerGuestType: PRICE_TYPES_OPTIONS[0].value,
    termsAndConditionId: '',
    taxesDescription: '',
    taxes: [],
    cancellationPenaltyType: CANCELLATION_DEFAULT_SELECTED_OPTION,
    percentageOfPenalty: '',
    amountOfPenalty: '',
    cancellationDeadlineInHours: CANCELLATION_DEADLINE_DEFAULT_SELECTED_OPTION,
    // required to validate max number of guests ( cant be more then Max Capacity )
    maxRoomCapacity: MAX_TOTAL_CAPACITY,
    paymentTermsType: TIME_OF_BOOKING,
    paymentDeadlineInDays: '',
    editPolicyChanges: false,
    editPolicy: {
      dates: false,
      roomTypes: false,
      mainGuestName: false,
    },
    ...prefilledOfferData,
    features: existingFeatures,
    // input field to add new custom element feature to features array
    newOtherFeatureTitle: '',
  };

  return (
    <div className="container">
      <Breadcrumbs path={path} id={id} offerId={offerId} />
      <div className={`row flex-grow-1 flex ${styles.pageWrapper}`}>
        <div className="col-lg-3">
          <FormStepper
            containerClasses={styles.stepperWrapper}
            activeNavIndex={activeNavIndex}
            navItems={navItems}
          />
        </div>
        <div className="col-12 col-lg-9">
          <div className={styles.border}>
            <Formik
              validateOnChange
              enableReinitialize
              initialValues={initialValues}
              validationSchema={prefilledOfferData
                ? navItems[activeNavIndex].editOfferValidatoinSchema
                : navItems[activeNavIndex].validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors, values, setFieldValue, setFieldError, setFieldTouched,
              }) => (
                <Form className={`${styles.form} flex flex-col`}>
                  <TabToDisplay
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    values={values}
                    roomCategories={roomCategories}
                    generalFeatures={existingFeatures}
                    propertyId={id}
                    offerId={offerId}
                  />
                  <div className={`${styles.footer} flex`}>
                    {!isFirstStep && (
                      <Button
                        disabled={!activeNavIndex}
                        onClick={switchPrevTab}
                        classes={`
                          btn btn_room-md btn-uppercased 
                          btn_brs-28 btn_padded btn_bordered
                          flex flex-center t-700
                      `}
                      >
                        <span className="btn_text">
                          Previous
                        </span>
                      </Button>
                    )}
                    <Link
                      to={`/sellers/my-properties/${id}/offers`}
                      className={`
                        ${styles.cancel} 
                        ml-auto
                        btn btn_room-md btn-uppercased btn_brs-28
                        flex flex-center btn_padded btn_bordered t-700
                      `}
                    >
                      <span className="btn_text">
                        Cancel
                      </span>
                    </Link>
                    <Button
                      type="submit"
                      classes="btn btn_room-md btn-uppercased btn_common t-700"
                    >
                      <span className="btn_text">
                        {`${isLastStep ? 'Save' : 'Next'}`}
                      </span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOffer;
