import React, { Fragment, useEffect, useState } from 'react';
import { FieldArray } from 'formik';

import Checkbox from '../../../../components/UI/Checkbox/Checkbox';
import PlusIcon from '../../../../assets/img/icons/ic-plus-active.svg';
import { FEATURES_SECTION_TITLES, FEATURE_TYPES } from '../../../../constans/features';
import CustomCreatableSelect from '../../../../components/UI/CustomCreatableSelect/CustomCreatableSelect';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';
import { Input } from '../../../../components/UI/Input/Input';
import IconDelete from '../../../../assets/img/icons/ic-delete-default.svg';
import SaveIcon from '../../../../assets/img/icons/ic-save-default.svg';
import { TABLET_WIDTH_BREAKPOINT } from '../../../../constans/header';
import { MAX_CUSTOM_FEATURES, CUSTOM_FEATURE_TITLE_FIELD } from '../../../../constans/offers';
import { MAX_FEATURE_TITLE_LENGTH, REGEXP_NOSPACES } from '../../../../constans/validation';
import { OFFER_NEW_FEATURE_NAME, OFFER_NEW_FEATURE_NAME_REQUIRED } from '../../../../constans/error-messages';

import styles from './IncludedFeaturesTab.module.scss';
import commonStyles from '../CreateOffer.module.scss';

const findFeatureWithSettedValue = (featureList) => Object
  .values(featureList)
  .flat()
  .find(({ maxNumberOfGuests }) => maxNumberOfGuests);

const IncludedFeaturesTab = ({
  values: {
    features,
    maxNumberOfGuests,
    newOtherFeatureTitle,
  },
  setFieldValue,
  setFieldTouched,
  setFieldError,
}) => {
  const maxNumberOfGuestsOptions = [
    { label: 'For all guests', value: maxNumberOfGuests },
  ];

  const [isAddFieldShow, setIsAddFieldShows] = useState(false);
  const [maxGuestOptions, setMaxGuestOptions] = useState(maxNumberOfGuestsOptions);
  const windowWidth = useWindowWidth();
  const isAddFeatureDisabled = features.OTHER.length > MAX_CUSTOM_FEATURES;

  const handleAddFeature = (arrayHelpers, title) => {
    setFieldTouched(CUSTOM_FEATURE_TITLE_FIELD);

    if (title.trim()) {
      setFieldError(CUSTOM_FEATURE_TITLE_FIELD, '');
      setFieldValue(CUSTOM_FEATURE_TITLE_FIELD, '');

      arrayHelpers.push({
        title: title.trim(),
        selected: true,
      });

      return;
    }

    if (!title) {
      setFieldError(CUSTOM_FEATURE_TITLE_FIELD, OFFER_NEW_FEATURE_NAME_REQUIRED);

      return;
    }

    if (!REGEXP_NOSPACES.test(title)) {
      setFieldError(CUSTOM_FEATURE_TITLE_FIELD, OFFER_NEW_FEATURE_NAME);

      return;
    }

    setFieldError(CUSTOM_FEATURE_TITLE_FIELD, '');
  };

  useEffect(() => {
    const selectedValue = findFeatureWithSettedValue(features)?.maxNumberOfGuests;

    setFieldValue('maxGuestPerFeature', selectedValue || maxNumberOfGuests);

    if (selectedValue && (selectedValue !== maxNumberOfGuests)) {
      setMaxGuestOptions([...maxNumberOfGuestsOptions, {
        label: selectedValue,
        value: selectedValue,
      }]);
    }
  }, []);

  return (
    <FieldArray
      name="features.OTHER"
      render={(arrayHelpers) => (
        <>
          <div className="flex flex-v-center flex-between">
            <span className={`${commonStyles.cardTitle} mb-0`}>
              Included features
            </span>
            <button
              type="button"
              disabled={isAddFeatureDisabled}
              onClick={() => setIsAddFieldShows((prevState) => !prevState)}
              className={`
                ${styles.addFeature}
                flex flex-v-center
            `}
            >
              <PlusIcon className={styles.iconPlus} />
              {`${windowWidth > TABLET_WIDTH_BREAKPOINT ? 'Add feature' : ''}`}
            </button>
          </div>

          {(isAddFieldShow && !isAddFeatureDisabled) && (
            <div
              className={`
                flex flex-between flex-v-center
                ${styles.addFeatureFieldWrapper}
              `}
            >
              <Input
                containerStyles={styles.addFeatureField}
                disabled={isAddFeatureDisabled}
                maxLength={MAX_FEATURE_TITLE_LENGTH}
                type="text"
                name={CUSTOM_FEATURE_TITLE_FIELD}
                placeholder="Add new feature"
              />
              <button
                type="button"
                disabled={isAddFeatureDisabled}
                onClick={() => handleAddFeature(arrayHelpers, newOtherFeatureTitle)}
                className={styles.addFeatureButton}
              >
                <SaveIcon />
              </button>
            </div>
          )}

          <hr className={`${commonStyles.divider}`} />
          {Object.keys(features).map((key, index) => (
            <Fragment key={`${key}-${index}`}>
              {features[FEATURE_TYPES[key]]?.length > 0 ? (
                <p className={styles.sectionTitle}>
                  {`${FEATURES_SECTION_TITLES[key]}:`}
                </p>
              ) : null}
              <div className="flex flex-wrap flex-between">
                {features[FEATURE_TYPES[key]]?.map(({ featureId, title }, itemIndex) => (
                  <div className={`flex flex-col ${styles.checkboxBlock}`} key={itemIndex}>
                    <div className={`${styles.checkboxBlockHeader} flex flex-v-center flex-between`}>
                      <Checkbox
                        checkboxClasses="c-custom__check-bordered-primary c-custom__check-md"
                        name={`features.${FEATURE_TYPES[key]}.${itemIndex}.selected`}
                        labelClasses={`${styles.checkboxLabel} flex-inline flex-v-center`}
                      >
                        <div>{title}</div>
                      </Checkbox>
                      {!featureId && (
                        <button
                          className={styles.deleteFeature}
                          type="button"
                          onClick={() => arrayHelpers.remove(itemIndex)}
                        >
                          <IconDelete />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          ))}

          <CustomCreatableSelect
            options={maxGuestOptions}
            placeholder="Select or type"
            name="maxGuestPerFeature"
            containerStyles={styles.maxGuestInput}
          />
        </>
      )}
    />
  );
};

export default IncludedFeaturesTab;
