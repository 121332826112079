import React from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import SideNav from '../../../../components/SideNav/SideNav';
import { ROOM_EDIT_NAV } from '../../../../constans/links';

const EditRoomWrapper = ({
  children, propertyId, roomId, path,
}) => (
  <div className="pt-0 container property-edit-wrapper flex">
    <div className="row flex-grow-1">
      <div className="col-12">
        <Breadcrumbs
          path={path}
          id={propertyId}
        />
      </div>
      <SideNav links={ROOM_EDIT_NAV} roomId={roomId} propertyId={propertyId} />
      <div className="col-12 col-lg-9">
        {children}
      </div>
    </div>
  </div>
);

export default EditRoomWrapper;
