import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import toastr from 'toastr';
import GeneralInfo from '../GeneralInfo/GeneralInfo';
import RoomPhotos from '../RoomPhotos/RoomPhotos';
import BedSettings from '../BedSettings/BedSettings';
import RoomAmenities from '../RoomAmenities/RoomAmenities';
import FormStepper from '../../../../components/FormStepper/FormStepper';
import Button from '../../../../components/UI/Button/Button';
import FormikPatchTouched from '../../../../components/FormikPatchTouched/FormikPatchTouched';
import {
  getRoomGeneralAmenities,
  createRoom,
  sendRoomPhotos,
  sendBedSettings,
  sendRoomAmenities,
} from '../../../../services/rooms-manage.service';
import { createNotEmptyFieldsObject } from '../../../../helpers/helper-methods';
// import { fileUploads, updateFileStatus } from '../../../helpers/file-upload';
import {
  roomGeneralSchema,
  roomAmenitiesSchema,
  roomBedConfigSchema,
  roomPhotosSchema,
} from '../../../../constans/validation-schemas';
import { ROOM_BED_CONFIG_MIN } from '../../../../constans/validation';
import { ROOM_GENERAL_INFO } from '../../../../constans/form-fields';
import { SUCCESS_SEND_MSG } from '../../../../constans/texts/texts';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import styles from './AddRoom.module.scss';

const navItems = [
  {
    key: 'generalInfo', navText: 'General info', component: GeneralInfo, validationSchema: roomGeneralSchema,
  },
  {
    key: 'roomPhotos', navText: 'Room photos', component: RoomPhotos, validationSchema: roomPhotosSchema,
  },
  {
    key: 'bedSettings', navText: 'Bed settings', component: BedSettings, validationSchema: roomBedConfigSchema,
  },
  {
    key: 'roomAmenities', navText: 'Room amenities', component: RoomAmenities, validationSchema: roomAmenitiesSchema,
  },
];

const AddRoom = ({ id, path }) => {
  const [initialValues, setInitialValues] = useState({
    general: {
      main: {
        [ROOM_GENERAL_INFO.roomType.name]: '',
        [ROOM_GENERAL_INFO.roomDescr.name]: '',
        [ROOM_GENERAL_INFO.roomSurface.name]: '',
        [ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]: '',
        [ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]: '',
        [ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name]: '',
        [ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name]: '',
        [ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]: '',
        [ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]: '',
      },
      [ROOM_GENERAL_INFO.designedCapacitySum.name]: 0,
      [ROOM_GENERAL_INFO.maxCapacitySum.name]: 0,
      [ROOM_GENERAL_INFO.optimizedCapacitySum.name]: 0,
    },
    photos: [],
    bedConfigurations: [{
      title: 'Configuration #1',
      amount: ROOM_BED_CONFIG_MIN,
      bedTypes: [
        {
          title: '',
          bedWidth: '',
        },
      ],
    }],
    amenities: {
      general: [],
      custom: [],
    },
  });
  const [disableSubmitBtn, setDisableSubmitBtn] = useState();
  const [activeNavIndex, setActiveNavIndex] = useState(0);

  const switchNextTab = () => {
    setActiveNavIndex((prevState) => prevState + 1);
  };

  const switchPrevTab = () => {
    setActiveNavIndex((prevState) => prevState - 1);
  };

  const toggleSubmitBtn = () => setDisableSubmitBtn(!disableSubmitBtn);

  const TabToDisplay = navItems[activeNavIndex].component;

  const sendFormData = async ({
    general, bedConfigurations, amenities, photos,
  }) => {
    try {
      const {
        data: { id: roomId },
      } = await createRoom(id, createNotEmptyFieldsObject(general.main));
      // Send photos to s3
      await sendRoomPhotos(photos, roomId);
      //  Create bed settings
      await sendBedSettings(bedConfigurations, roomId);
      // Create room amenities
      await sendRoomAmenities(amenities, roomId);

      toastr.success(SUCCESS_SEND_MSG);
      toggleSubmitBtn();
      navigate(`/sellers/my-properties/${id}/rooms`);
    } catch (_e) {
      toggleSubmitBtn();
    }
  };

  const formSubmitHandler = (values) => {
    if (activeNavIndex < navItems.length - 1) {
      switchNextTab();
    } else if (activeNavIndex === navItems.length - 1) {
      toggleSubmitBtn();
      sendFormData(values);
    }
  };

  useEffect(() => {
    getRoomGeneralAmenities().then((res) => {
      const generalAmenitiesValues = res.data.map((amenity) => ({
        title: amenity.title,
        check: false,
      }));
      setInitialValues((prevState) => ({
        ...prevState,
        amenities: {
          ...prevState.amenities,
          general: generalAmenitiesValues,
        },
      }));
    });
  }, []);

  return (
    <div className="pt-0 container property-edit-wrapper">
      <div className="row col-12">
        <Breadcrumbs path={path} id={id} />
      </div>
      <div className="row flex-grow-1">
        <FormStepper
          activeNavIndex={activeNavIndex}
          navItems={navItems}
          containerClasses="col-12 col-lg-3"
        />
        <div className="col-12 col-lg-9">
          <Formik
            enableReinitialize
            onSubmit={formSubmitHandler}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={navItems[activeNavIndex].validationSchema}
            // validationSchema={validationSchema}
            initialValues={initialValues}
          >
            {({
              values, errors, setFieldValue, setFieldError,
            }) => (
              <Form className={`${styles.form} edit-form-wrapper`}>
                <FormikPatchTouched />
                <TabToDisplay
                  setFieldError={setFieldError}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                />
                {activeNavIndex === 0
                  ? (
                    <div className={`${styles.footer} flex`}>
                      <Link
                        to={`/sellers/my-properties/${id}/edit/rooms`}
                        className={`${styles.cancelBtn} btn btn_room-md flex flex-center btn-uppercased btn_brs-28 btn_padded btn_bordered t-700`}
                      >
                        <span className="btn_text">
                          Cancel
                        </span>
                      </Link>
                      <Button
                        type="submit"
                        classes={`${styles.nextSubmitBtn} btn btn_room-md btn-uppercased btn_common t-700`}
                      >
                        <span className="btn_text">
                          Save & Next
                        </span>
                      </Button>
                    </div>
                  )
                  : activeNavIndex < navItems.length
                    ? (
                      <div className={`${styles.footer} flex`}>
                        <Button
                          onClick={() => switchPrevTab()}
                          classes={`${styles.prevBtn} btn btn_room-md flex flex-center btn-uppercased btn_brs-28 btn_padded btn_bordered t-700`}
                        >
                          <span className="btn_text">
                            Previous
                          </span>
                        </Button>
                        <Link
                          to={`/sellers/my-properties/${id}/edit/rooms`}
                          className={`${styles.cancelBtn} btn btn_room-md flex flex-center btn-uppercased btn_brs-28 btn_padded btn_bordered t-700`}
                        >
                          <span className="btn_text">
                            Cancel
                          </span>
                        </Link>
                        {activeNavIndex < navItems.length - 1 ? (
                          <Button
                            type="submit"
                            classes="btn btn_room-md btn-uppercased btn_common t-700"
                          >
                            <span className="btn_text">
                              Save & Next
                            </span>
                          </Button>
                        ) : (
                          <Button
                            disabled={disableSubmitBtn}
                            type="submit"
                            classes="btn btn_room-md btn-uppercased btn_common t-700"
                          >
                            <span className="btn_text">
                              Submit
                            </span>
                          </Button>
                        )}
                      </div>
                    ) : null}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddRoom;
