import React from 'react';
import moment from 'moment';

import { DEFAULT_FORMAT } from '../../../../constans/formats';

import commonStyles from '../OfferDetails.module.scss';
import OfferDetailsSection from '../../../../components/OfferDetailsSection';

const GeneralInfo = ({
  title,
  code,
  room,
  maxNumberOfGuests,
  minimumStayDuration,
  endDate,
  startDate,
  noTimeLimit,
  inventoryNumber,
}) => (
  <>
    <div className="row flex flex-between">
      <OfferDetailsSection
        title="Offer name"
        value={title}
      />

      <OfferDetailsSection
        title="Offer code"
        value={code || '[duplicate]'}
      />
    </div>

    <div className="row flex flex-between">
      <OfferDetailsSection
        title="Room type"
        value={room?.title}
      />

      <OfferDetailsSection
        title="max number of guests"
        value={maxNumberOfGuests}
      />
    </div>

    <hr className={commonStyles.divider} />

    <div className={`flex ${commonStyles.sectionTitle}`}>
      dates
    </div>

    <div className="row flex flex-between">
      <OfferDetailsSection
        title="Dates of availability"
        value={!noTimeLimit ? `${moment(startDate).format(DEFAULT_FORMAT)} - ${moment(endDate).format(DEFAULT_FORMAT)}` : "No time limit"}
      />

      <OfferDetailsSection
        title="Minimum stay duration (nights)"
        value={minimumStayDuration}
      />
    </div>

    <hr className={commonStyles.divider} />

    <div className={`flex ${commonStyles.sectionTitle}`}>
      Inventory
    </div>

    <div className="row">
      <OfferDetailsSection
        cols="col-12"
        title="Inventory number"
        value={inventoryNumber}
      />
    </div>
  </>
);

export default GeneralInfo;
