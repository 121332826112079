import React from 'react';
import ReactPaginate from 'react-paginate';
import NextPage from '../../../assets/img/icons/ic-chevron-right-light-gray.svg';
import PrevPage from '../../../assets/img/icons/ic-chevron-left-light-gray.svg';
import FirstPage from '../../../assets/img/icons/ic-chevrons-left-light-gray.svg';
import LastPage from '../../../assets/img/icons/ic-chevrons-right-light-gray.svg';
import { PAGIN_MARGIN_PAGES, PAGIN_RANGE_PAGES } from '../../../constans/pagination';
import styles from '../../Pagination/index.module.scss';

const TablePagination = ({
  onPageChange, pages, pageSize, page,
}) => {
  const totalPages = Math.ceil(pages / pageSize);

  return (
    !!totalPages && (
      <div className={`${styles.pagination} flex flex-center`}>
        <FirstPage className={styles.minTablet} />
        <ReactPaginate
          previousLabel={<PrevPage />}
          nextLabel={<NextPage />}
          breakLabel="..."
          breakClassName={styles.buttonText}
          nextLinkClassName={styles.direction}
          previousLinkClassName={styles.direction}
          pageClassName={styles.button}
          pageLinkClassName={styles.buttonText}
          pageCount={totalPages}
          marginPagesDisplayed={PAGIN_MARGIN_PAGES}
          pageRangeDisplayed={PAGIN_RANGE_PAGES}
          onPageChange={({ selected: activePage }) => onPageChange(activePage)}
          containerClassName="flex flex-center"
          activeClassName={styles.buttonActive}
          forcePage={page}
        />
        <LastPage className={styles.minTablet} />
      </div>
    )
  );
};

export default TablePagination;
