import React, { useState } from 'react';
import toastr from 'toastr';
import { FieldArray, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';

import UploadIcon from '../../../../assets/img/icons/ic-cloud-small.svg';
import DeletableImage from '../../../../components/DeletableImage/DeletableImage';
import ImageModal from '../../../../components/ImageModal/ImageModal';
import {
  MAX_PHOTO_PER_LOAD,
  MAX_PHOTO_SIZE,
  PHOTO_FORMAT,
} from '../../../../constans/validation';
import { PHONE_WIDTH_BREAKPOINT } from '../../../../constans/header';
import { deleteRoomPhotos } from '../../../../services/rooms-manage.service';
import { IMAGE_REMOVED } from '../../../../constans/texts/texts';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

import styles from './RoomPhotos.module.scss';

const RoomPhotos = ({ roomId, values, setFieldError }) => {
  const [activeImage, setActiveImage] = useState(0);
  const [isModalShown, setIsModalShown] = useState(false);
  const width = useWindowWidth();
  const isDisabled = false;

  const deletePhoto = (imageId) => deleteRoomPhotos(roomId, [imageId]);

  return (
    <>
      <ImageModal
        images={values.photos}
        show={isModalShown}
        onArrowClick={setActiveImage}
        activeIndex={activeImage}
        onCloseClick={() => setIsModalShown(false)}
      />
      <div className="property-edit-title">
        <span>Room Photos</span>
      </div>
      <FieldArray
        name="photos"
        render={(arrayHelpers) => {
          const onDelete = (imageId, arrayIndex) => {
            setFieldError('photos', '');
            if (imageId) {
              deletePhoto(imageId).then(() => {
                arrayHelpers.remove(arrayIndex);
                toastr.success(IMAGE_REMOVED);
              });
            } else {
              arrayHelpers.remove(arrayIndex);
              toastr.success(IMAGE_REMOVED);
            }
          };

          const onDrop = (files) => files.map((fileData) => arrayHelpers.push({
            file: fileData,
            link: URL.createObjectURL(fileData),
          }));
          return (
            <>
              <Dropzone
                accept={PHOTO_FORMAT}
                maxFiles={MAX_PHOTO_PER_LOAD}
                maxSize={MAX_PHOTO_SIZE}
                onDrop={onDrop}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className={`
                      ${styles.dropzone}
                      ${isDisabled && styles.dropzoneDisabled}
                    `}
                  >
                    <input {...getInputProps()} />
                    <UploadIcon className={styles.uploadIcon} />
                    <p className={`${styles.dropzoneText}`}>
                      {width > PHONE_WIDTH_BREAKPOINT ? (
                        <>
                          Drag &amp; Drop or
                          {' '}
                          <span className="t-600">
                            Choose the files
                          </span>
                        </>
                      ) : (
                        <span className="t-600">
                          Upload files
                        </span>
                      )}
                    </p>
                  </div>
                )}
              </Dropzone>
              <div className={`flex flex-wrap ${styles.images}`}>
                {values.photos && values.photos.map(
                  (
                    {
                      id: imageId,
                      file: { link },
                      link: inlineFile,
                    },
                    index,
                  ) => (
                    <DeletableImage
                      key={`dorkj${index}`}
                      onPhotoClick={() => {
                        setActiveImage(index);
                        setIsModalShown(true);
                      }}
                      onDeletePhoto={() => onDelete(imageId, index)}
                      className={styles.image}
                      src={link || inlineFile}
                    />
                  ),
                )}
              </div>
              <ErrorMessage name="photos" render={(msg) => <div className={`${styles.error} error-input-msg text-center`}>{msg}</div>} />
            </>
          );
        }}
      />
    </>
  );
};

export default RoomPhotos;
