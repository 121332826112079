import React from 'react';
import { Link } from 'gatsby';
import { PROPERTY_CARD_LINKS } from '../../../constans/links';
import styles from './PropertyDetailsHeader.module.scss';

const PropertyDetailsHeader = ({ containerStyles, id }) => (
  <div
    className={`${styles.listWrapper} ${containerStyles} horizontal-list-wrapper flex flex-shrink-0`}
  >
    <div className="flex flex-v-center flex-shrink-0">
      {PROPERTY_CARD_LINKS.map(({ title, href }, index) => (
        <Link
          activeClassName="active"
          className={`${styles.listItem} horizontal-list-item t-600`}
          to={href.replace(':id', id)}
          key={index}
        >
          {title}
        </Link>
      ))}
    </div>
  </div>
);

export default PropertyDetailsHeader;
