import React from 'react';

const BedSettingsHeader = ({ children, containerStyles }) => (
  <div className={`${containerStyles} underlined-title-sm`}>
    {children}
  </div>
);

BedSettingsHeader.defaultProps = {
  containerStyles: '',
};

export default BedSettingsHeader;
