import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import http from '../../services/http.service';
import { API } from '../../constans/http';
import Gallery from '../../components/Gallery/Gallery';
import PropertyDetailsHeader from '../../components/PropertyDetails/PropertyDetailsHeader/PropertyDetailsHeader';
import Spinner from '../../components/UI/Spinner/Spinner';
// import MapIcon from '../../assets/img/icons/ic-location-gray.svg';
import EditIcon from '../../assets/img/icons/ic-edit-default.svg';
import styles from './index.module.scss';
import {
  filterAnchorsNavArray,
} from '../../helpers/helper-methods';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PropertyDetailsTitle from '../../components/PropertyDetails/PropertyDetailsTitle/PropertyDetailsTitle';
import PropertyDetailsInfoSeller from '../../components/PropertyDetails/PropertyDetailInfoSeller/PropertyDetailsInfoSeller';

const infoNavData = [
  {
    keys: ['accessibilities', 'accessibilityDescription'],
    hash: '#accesibilities',
    id: 'accessibilities',
    label: 'Accessibility',
  },
  {
    keys: ['socialAndEthicalDescription', 'environmentalDescription'],
    hash: '#policies',
    id: 'policies',
    label: 'Environmental & Social',
  },
  {
    keys: ['transports'],
    hash: '#transports',
    id: 'transports',
    label: 'Location and transports',
  },
  {
    keys: ['facilities', 'keyFacilities'],
    hash: '#facilities',
    id: 'facilities',
    label: 'List of facilities',
  },
  {
    keys: ['restaurants'],
    hash: '#restaurants',
    id: 'restaurants',
    label: 'Food & Beverages',
  },
  {
    keys: ['roomServices'],
    hash: '#roomServices',
    id: 'roomServices',
    label: 'room Services',
  },
  // {
  //   keys: ['surroundings'],
  //   hash: '#surroundings',
  //   id: 'surroundings',
  //   label: 'Surroundings',
  // },
];

// const locationsMap = {
//   ACTIVITIES: 'Activities',
//   COMMERCIALS: 'Commercial',
//   CULTURES: 'Cultural venues',
//   FOODS: 'Food, restaurant and bars',
//   NATURES: 'Nature and views',
//   TOURISTS: 'Tourists sights and landmarks',
// };
//
// const facilitiesMap = {
//   NOT_AVAILABLE: 'N/A',
// };
//
// const facilitiesInterval = {
//   'per/h': '/ h',
//   'per/day': '/ day',
// };
//
// const payedStatus = 'PAID';

const PropertyDetails = ({ id, location, path }) => {
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorsData, setAnchorsData] = useState(null);

  useEffect(() => {
    http
      .get(`${API.PROPERTIES}/${id}`)
      .then(({ data }) => {
        const predefinedNavObj = {
          aboutUs: { hash: '#aboutUs', id: 'aboutUs', label: 'About us' },
        };
        const filteredAnchorsObj = filterAnchorsNavArray(
          predefinedNavObj,
          infoNavData,
          data,
        );
        setAnchorsData(filteredAnchorsObj);
        setPropertyData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className={`${styles.wrapper} container`}>
      <Breadcrumbs path={path} id={id} />
      <PropertyDetailsHeader containerStyles={styles.header} id={id} />
      {loading ? (
        <Spinner spinnerClasses="spinner-primary" />
      ) : (
        propertyData && (
          <>
            <PropertyDetailsTitle
              propertyData={propertyData}
            >
              <Link
                to={`${location.pathname}/edit/general-info`}
                className={`${styles.editLink} btn br-17 btn_bordered flex flex-center`}
              >
                <EditIcon className={styles.icon} />
                Edit
              </Link>
            </PropertyDetailsTitle>
            {propertyData.photos && <Gallery images={propertyData.photos} />}
            <div className={`${styles.infoDataWrapper} row`}>
              <div className={`${styles.infoDataContainer} col-12 col-lg-9`}>
                <PropertyDetailsInfoSeller
                  anchorsData={anchorsData}
                  propertyData={propertyData}
                  containerStyles={styles.infoDataContainerInner}
                />
              </div>
              <div className={`${styles.infoDataNav} col-3`}>
                <div className={styles.infoDataNavList}>
                  {Object.keys(anchorsData).map((anchor, index) => (
                    <AnchorLink
                      key={`dfsoi${index}`}
                      to={`${location.pathname}${anchorsData[anchor].hash}`}
                      stripHash
                      className={styles.infoDataNavItem}
                    >
                      {anchorsData[anchor].label}
                    </AnchorLink>
                  ))}
                </div>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default PropertyDetails;

// {/*{propertyData.surroundings*/}
// {/*  && objectIsNotEmpty(propertyData.surroundings) && (*/}
// {/*    <div*/}
// {/*      id={anchorsData.surroundings.id}*/}
// {/*      className={styles.infoBlock}*/}
// {/*    >*/}
// {/*      <div className={styles.infoBlockTitle}>*/}
// {/*        Surroundings*/}
// {/*      </div>*/}
// {/*      <div className={styles.infoList}>*/}
// {/*        {Object.keys(propertyData.surroundings).map(*/}
// {/*          (surrItemKey, index) => (propertyData.surroundings[surrItemKey].length*/}
// {/*            > 0 ? (*/}
// {/*              <div*/}
// {/*                key={`fo${index}`}*/}
// {/*                className={`${styles.infoListItem} ${styles.infoListItemDotted}`}*/}
// {/*              >*/}
// {/*                {propertyData.surroundings[surrItemKey].map(*/}
// {/*                  (surrItem, indexInner) => (*/}
// {/*                    <div*/}
// {/*                      className={styles.surroundingsDataListItem}*/}
// {/*                      key={`fpo${indexInner}`}*/}
// {/*                    >*/}
// {/*                      {indexInner < 1 ? (*/}
// {/*                        <div*/}
// {/*                          className={`${styles.mb10} capitalized-text t-500`}*/}
// {/*                        >*/}
// {/*                          {surrItem.type.toLowerCase()}*/}
// {/*                        </div>*/}
// {/*                      ) : null}*/}
// {/*                      <div*/}
// {/*                        className={`${styles.listItemSubtitle}
// ${styles.surroundingsDataTitleDistance}`}*/}
// {/*                      >*/}
// {/*                        {surrItem.title}*/}
// {/*                        <span*/}
// {/*                          className={styles.subtitleDelimiter}*/}
// {/*                        >*/}
// {/*                          -*/}
// {/*                        </span>*/}
// {/*                        {surrItem.distance / KM_TO_METERS}*/}
// {/*                        <span*/}
// {/*                          className={styles.distanceUnits}*/}
// {/*                        >*/}
// {/*                          km*/}
// {/*                        </span>*/}
// {/*                      </div>*/}
// {/*                      {surrItem.description && (*/}
// {/*                        <div*/}
// {/*                          className={styles.listItemDescr}*/}
// {/*                        >*/}
// {/*                          {surrItem.description}*/}
// {/*                        </div>*/}
// {/*                      )}*/}
// {/*                    </div>*/}
// {/*                  ),*/}
// {/*                )}*/}
// {/*              </div>*/}
// {/*            ) : (*/}
// {/*              []*/}
// {/*            )),*/}
// {/*        )}*/}
// {/*      </div>*/}
// {/*    </div>*/}
// {/*)}*/}

// {/*<div className={styles.infoDataContainerInner}>*/}
// {/*  <div id={anchorsData.aboutUs.id} className={styles.infoBlock}>*/}
// {/*    <div className={styles.aboutUsTitle}>about us</div>*/}
// {/*    {propertyData.generalDescription && (*/}
// {/*      <>*/}
// {/*        <div*/}
// {/*          className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}*/}
// {/*        >*/}
// {/*          General intro and description*/}
// {/*        </div>*/}
// {/*        <div className={`${styles.infoBlockText}`}>*/}
// {/*          {propertyData.generalDescription}*/}
// {/*        </div>*/}
// {/*      </>*/}
// {/*    )}*/}
// {/*    {propertyData.brandDescription && (*/}
// {/*      <>*/}
// {/*        <div*/}
// {/*          className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}*/}
// {/*        >*/}
// {/*          Chain / brand*/}
// {/*        </div>*/}
// {/*        <div className={`${styles.infoBlockText}`}>*/}
// {/*          {propertyData.brandDescription}*/}
// {/*        </div>*/}
// {/*      </>*/}
// {/*    )}*/}
// {/*    {propertyData.aboutUsDescription && (*/}
// {/*      <>*/}
// {/*        <div*/}
// {/*          className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}*/}
// {/*        >*/}
// {/*          About us / values / word from management*/}
// {/*        </div>*/}
// {/*        <div className={`${styles.infoBlockText}`}>*/}
// {/*          {propertyData.aboutUsDescription}*/}
// {/*        </div>*/}
// {/*      </>*/}
// {/*    )}*/}
// {/*  </div>*/}
// {/*  {propertyData.accessibilities*/}
// {/*  || propertyData.accessibilityDescription ? (*/}
// {/*    <div*/}
// {/*      id={anchorsData.accessibilities.id}*/}
// {/*      className={styles.infoBlock}*/}
// {/*    >*/}
// {/*      <div className={styles.infoBlockTitle}>accessibility</div>*/}
//
// {/*      {propertyData.accessibilities && (*/}
// {/*        <div className={styles.infoList}>*/}
// {/*          {propertyData.accessibilities.map(*/}
// {/*            (accessibilityItem, index) => (*/}
// {/*              <div*/}
// {/*                key={`sdfdf${index}`}*/}
// {/*                className={`${styles.infoListItemDotted} ${styles.infoListItem}`}*/}
// {/*              >*/}
// {/*                <div*/}
// {/*                  className={`${styles.listItemTitleMargined} t-500`}*/}
// {/*                >*/}
// {/*                  {accessibilityItem.title}*/}
// {/*                </div>*/}
// {/*                {accessibilityItem.description ? (*/}
// {/*                  <div className={styles.listItemDescr}>*/}
// {/*                    {accessibilityItem.description}*/}
// {/*                  </div>*/}
// {/*                ) : null}*/}
// {/*              </div>*/}
// {/*            ),*/}
// {/*          )}*/}
// {/*        </div>*/}
// {/*      )}*/}
// {/*      {propertyData.accessibilityDescription && (*/}
// {/*        <>*/}
// {/*          <div*/}
// {/*            className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}*/}
// {/*          >*/}
// {/*            accessibility Description*/}
// {/*          </div>*/}
// {/*          <div className={`${styles.infoBlockText}`}>*/}
// {/*            {propertyData.accessibilityDescription}*/}
// {/*          </div>*/}
// {/*        </>*/}
// {/*      )}*/}
// {/*    </div>*/}
// {/*    ) : null}*/}
// {/*  {propertyData.environmentalDescription*/}
// {/*  || propertyData.socialAndEthicalDescription ? (*/}
// {/*    <div*/}
// {/*      id={anchorsData.policies.id}*/}
// {/*      className={styles.infoBlock}*/}
// {/*    >*/}
// {/*      <div className={styles.infoBlockTitle}>*/}
// {/*        Environmental & Social*/}
// {/*      </div>*/}
// {/*      <div className={styles.infoList}>*/}
// {/*        {propertyData.environmentalDescription && (*/}
// {/*          <div className={`${styles.infoListItem}`}>*/}
// {/*            <div*/}
// {/*              className={`${styles.infoBlockSubtitle} uppercased-text t-600`}*/}
// {/*            >*/}
// {/*              Environmental policies, projects and actions*/}
// {/*            </div>*/}
// {/*            <div className={styles.infoBlockText}>*/}
// {/*              {propertyData.environmentalDescription}*/}
// {/*            </div>*/}
// {/*          </div>*/}
// {/*        )}*/}
// {/*        {propertyData.socialAndEthicalDescription && (*/}
// {/*          <div className={`${styles.infoListItem}`}>*/}
// {/*            <div*/}
// {/*              className={`${styles.infoBlockSubtitle} uppercased-text t-600`}*/}
// {/*            >*/}
// {/*              Social policies, projects and actions*/}
// {/*            </div>*/}
// {/*            <div className={styles.infoBlockText}>*/}
// {/*              {propertyData.socialAndEthicalDescription}*/}
// {/*            </div>*/}
// {/*          </div>*/}
// {/*        )}*/}
// {/*      </div>*/}
// {/*    </div>*/}
// {/*    ) : null}*/}
// {/*  {propertyData.transports*/}
// {/*    && propertyData.transports.length > 0 && (*/}
// {/*      <div*/}
// {/*        id={anchorsData.transports.id}*/}
// {/*        className={styles.infoBlock}*/}
// {/*      >*/}
// {/*        <div className={styles.infoBlockTitle}>*/}
// {/*          Location and transports*/}
// {/*        </div>*/}
// {/*        <div className={styles.infoList}>*/}
// {/*          {propertyData.transports.map(*/}
// {/*            (transportItem, index) => (*/}
// {/*              <div*/}
// {/*                key={`sdo${index}`}*/}
// {/*                className={`${styles.infoListItem} ${styles.infoListItemDotted}`}*/}
// {/*              >*/}
// {/*                <div className={`${styles.mb10} t-500`}>*/}
// {/*                  {transportItem.type}*/}
// {/*                </div>*/}
// {/*                <div*/}
// {/*                  className={`${styles.listItemSubtitle} ${styles.mb5}`}*/}
// {/*                >*/}
// {/*                  {transportItem.title}*/}
// {/*                  <span className={styles.subtitleDelimiter}>*/}
// {/*                    -*/}
// {/*                  </span>*/}
// {/*                  {transportItem.distance / KM_TO_METERS}*/}
// {/*                  <span className={styles.distanceUnits}>*/}
// {/*                    km*/}
// {/*                  </span>*/}
// {/*                </div>*/}
// {/*                {transportItem.description && (*/}
// {/*                  <div className={styles.listItemDescr}>*/}
// {/*                    {transportItem.description}*/}
// {/*                  </div>*/}
// {/*                )}*/}
// {/*              </div>*/}
// {/*            ),*/}
// {/*          )}*/}
// {/*          {propertyData.surroundings*/}
// {/*          && objectIsNotEmpty(propertyData.surroundings)*/}
// {/*          && (*/}
// {/*            <>*/}
// {/*              {*/}
// {/*                Object.keys(propertyData.surroundings).map(*/}
// {/*                  (surrItemKey, index) => (*/}
// {/*                    propertyData.surroundings[surrItemKey].length > 0 ? (*/}
// {/*                      <div*/}
// {/*                        key={`fo${index}`}*/}
// {/*                        className={`${styles.infoListItem} ${styles.infoListItemDotted}`}*/}
// {/*                      >*/}
// {/*                        {propertyData.surroundings[surrItemKey].map(*/}
// {/*                          (surrItem, indexInner) => (*/}
// {/*                            <div*/}
// {/*                              className={styles.surroundingsDataListItem}*/}
// {/*                              key={`fpo${indexInner}`}*/}
// {/*                            >*/}
// {/*                              {indexInner < 1 ? (*/}
// {/*                                <div*/}
// {/*                                  className={`${styles.mb10} t-500`}*/}
// {/*                                >*/}
// {/*                                  {locationsMap[surrItem.type]}*/}
// {/*                                </div>*/}
// {/*                              ) : null}*/}
// {/*                              <div*/}
// {/*                                className={`${styles.listItemSubtitle}
// ${styles.surroundingsDataTitleDistance}`}*/}
// {/*                              >*/}
// {/*                                {surrItem.title}*/}
// {/*                                <span*/}
// {/*                                  className={styles.subtitleDelimiter}*/}
// {/*                                >*/}
// {/*                                  -*/}
// {/*                                </span>*/}
// {/*                                {surrItem.distance / KM_TO_METERS}*/}
// {/*                                <span*/}
// {/*                                  className={styles.distanceUnits}*/}
// {/*                                >*/}
// {/*                                  km*/}
// {/*                                </span>*/}
// {/*                              </div>*/}
// {/*                              {surrItem.description && (*/}
// {/*                                <div*/}
// {/*                                  className={styles.listItemDescr}*/}
// {/*                                >*/}
// {/*                                  {surrItem.description}*/}
// {/*                                </div>*/}
// {/*                              )}*/}
// {/*                            </div>*/}
// {/*                          ),*/}
// {/*                        )}*/}
// {/*                      </div>*/}
// {/*                    ) : (*/}
// {/*                      []*/}
// {/*                    )),*/}
// {/*                )*/}
// {/*              }*/}
// {/*            </>*/}
// {/*          )}*/}
// {/*        </div>*/}
// {/*      </div>*/}
// {/*  )}*/}
// {/*  {propertyData.facilities || propertyData.keyFacilities ? (*/}
// {/*    <div*/}
// {/*      id={anchorsData.facilities.id}*/}
// {/*      className={styles.infoBlock}*/}
// {/*    >*/}
// {/*      <div className={styles.infoList}>*/}
// {/*        {propertyData.facilities*/}
// {/*          && propertyData.facilities.length > 0 && (*/}
// {/*            <div className={styles.infoListItem}>*/}
// {/*              <div className={styles.infoBlockTitle}>*/}
// {/*                List of facilities*/}
// {/*              </div>*/}
// {/*              {propertyData.facilities.map((facility, item) => (*/}
// {/*                <div*/}
// {/*                  key={`cowhq${item}`}*/}
// {/*                  className={`${styles.infoListItemDotted} ${styles.mb15}`}*/}
// {/*                >*/}
// {/*                  <div className={`${styles.mb5} t-500`}>*/}
// {/*                    {facility.title}*/}
// {/*                  </div>*/}
// {/*                  <div className={`${styles.infoBlockText}`}>*/}
// {/*                    {facility.description}*/}
// {/*                  </div>*/}
// {/*                </div>*/}
// {/*              ))}*/}
// {/*            </div>*/}
// {/*        )}*/}
// {/*        {propertyData.keyFacilities*/}
// {/*          ? objectIsNotEmpty(propertyData.keyFacilities) && (*/}
// {/*          <div className={styles.infoListItem}>*/}
// {/*            <div className={styles.infoBlockTitle}>*/}
// {/*              Parking & Wi-fi*/}
// {/*            </div>*/}
// {/*            {Object.keys(propertyData.keyFacilities).map(*/}
// {/*              (keyfacilityKey, item) => (*/}
// {/*                <div*/}
// {/*                  key={`xdvcf${item}`}*/}
// {/*                  className={`${styles.infoListItemDotted} ${styles.mb15}`}*/}
// {/*                >*/}
// {/*                  <div className={`${styles.mb5} t-500`}>*/}
// {/*                    <span*/}
// {/*                      className={`${styles.mr5} capitalized-text`}*/}
// {/*                    >*/}
// {/*                      {facilitiesMap[propertyData*/}
// {/*                        .keyFacilities[keyfacilityKey].status]*/}
// {/*                        ? facilitiesMap[propertyData*/}
// {/*                          .keyFacilities[keyfacilityKey].status]*/}
// {/*                        : propertyData*/}
// {/*                          .keyFacilities[keyfacilityKey].status.toLowerCase()}*/}
// {/*                    </span>*/}
// {/*                    <span className="lowered-text">*/}
// {/*                      {*/}
// {/*                        propertyData.keyFacilities[*/}
// {/*                          keyfacilityKey*/}
// {/*                        ].type*/}
// {/*                      }*/}
// {/*                    </span>*/}
// {/*                    {propertyData.keyFacilities[*/}
// {/*                      keyfacilityKey*/}
// {/*                    ].status === payedStatus && (*/}
// {/*                      <>*/}
// {/*                        <span*/}
// {/*                          className={styles.subtitleDelimiter}*/}
// {/*                        >*/}
// {/*                          -*/}
// {/*                        </span>*/}
// {/*                        {propertyData.keyFacilities[*/}
// {/*                          keyfacilityKey*/}
// {/*                        ].price*/}
// {/*                        && propertyData.keyFacilities[*/}
// {/*                          keyfacilityKey*/}
// {/*                        ].price}*/}
// {/*                        <span*/}
// {/*                          className={`${styles.mr5} ${styles.ml5}`}*/}
// {/*                        >*/}
// {/*                          {propertyData.keyFacilities[*/}
// {/*                            keyfacilityKey*/}
// {/*                          ].currency*/}
// {/*                          && propertyData.keyFacilities[*/}
// {/*                            keyfacilityKey*/}
// {/*                          ].currency.code}*/}
// {/*                        </span>*/}
// {/*                        {facilitiesInterval[propertyData*/}
// {/*                          .keyFacilities[keyfacilityKey].interval]}*/}
// {/*                      </>*/}
// {/*                    )}*/}
// {/*                  </div>*/}
// {/*                  <div*/}
// {/*                    className={`${styles.infoBlockText}`}*/}
// {/*                  >*/}
// {/*                    {*/}
// {/*                          propertyData.keyFacilities[*/}
// {/*                            keyfacilityKey*/}
// {/*                          ].description*/}
// {/*                        }*/}
// {/*                  </div>*/}
// {/*                </div>*/}
// {/*              ),*/}
// {/*            )}*/}
// {/*          </div>*/}
// {/*          )*/}
// {/*          : null}*/}
// {/*      </div>*/}
// {/*    </div>*/}
// {/*  ) : null}*/}
//
// {/*  {propertyData.restaurants && (*/}
// {/*    <div*/}
// {/*      id={anchorsData.restaurants.id}*/}
// {/*      className={styles.infoBlock}*/}
// {/*    >*/}
// {/*      <div className={styles.infoBlockTitle}>*/}
// {/*        Food & Beverages*/}
// {/*      </div>*/}
// {/*      <div*/}
// {/*        className={`${styles.foodContainer} ${styles.infoList}`}*/}
// {/*      >*/}
// {/*        {propertyData.restaurants.map(({ id: restaurantId, ...restaurant }) => (*/}
// {/*          <PropertyRestaurant*/}
// {/*            className={styles.infoListItem}*/}
// {/*            key={restaurantId}*/}
// {/*            {...restaurant}*/}
// {/*          />*/}
// {/*        ))}*/}
// {/*      </div>*/}
// {/*    </div>*/}
// {/*  )}*/}
//
// {/*  {propertyData.roomServices && (*/}
// {/*    <div*/}
// {/*      id={anchorsData.roomServices.id}*/}
// {/*      className={styles.infoBlock}*/}
// {/*    >*/}
// {/*      <div className={styles.infoBlockTitle}>Room service</div>*/}
// {/*      <div*/}
// {/*        className={`${styles.foodContainer} ${styles.infoList}`}*/}
// {/*      >*/}
// {/*        {propertyData.roomServices.map((room, index) => (*/}
// {/*          <div*/}
// {/*            className={`${styles.infoListItemDotted} t-500`}*/}
// {/*            key={`sc${index}`}*/}
// {/*          >*/}
// {/*            <div*/}
// {/*              className={`${styles.mb10}
// ${styles.roomServiceDataTitle} capitalized-text t-500`}*/}
// {/*            >*/}
// {/*              <span>{room.title}</span>*/}
// {/*              {(room.fromTime && room.toTime)*/}
// {/*                ? (*/}
// {/*                  <>*/}
// {/*                    <span className={styles.subtitleDelimiter}>*/}
// {/*                      -*/}
// {/*                    </span>*/}
// {/*                    <span>{!!room.fromTime && formatTime(room.fromTime)}</span>*/}
// {/*                    <span className={styles.subtitleDelimiter}>*/}
// {/*                      -*/}
// {/*                    </span>*/}
// {/*                    <span>{!!room.toTime && formatTime(room.toTime)}</span>*/}
// {/*                  </>*/}
// {/*                )*/}
// {/*                : (<span> 24/7</span>)}*/}
// {/*            </div>*/}
// {/*            {room.description && (*/}
// {/*              <div*/}
// {/*                className={`${styles.listItemDescr} ${styles.roomServiceDataDescr}`}*/}
// {/*              >*/}
// {/*                {room.description}*/}
// {/*              </div>*/}
// {/*            )}*/}
// {/*          </div>*/}
// {/*        ))}*/}
// {/*      </div>*/}
// {/*    </div>*/}
// {/*  )}*/}
// {/*</div>*/}
