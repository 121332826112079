import React from 'react';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import ArrayFieldErrorMessage from '../../../../components/UI/ArrayFieldErrorMessage/ArrayFieldErrorMessage';
import { Input } from '../../../../components/UI/Input/Input';
import {
  MAX_NUMBER_VALUE,
  PROP_BRAND_MAX,
  PROP_DESCR_MAX,
} from '../../../../constans/validation';
import { DISTANCE_INPUT_STEP } from '../../../../constans/values';
import { LOCATION_TRANSPORT } from '../../../../constans/form-fields';

const LocationsInputsBlock = ({
  wrapperStyles,
  inputWrapperStyles,
  titleName,
  distanceName,
  commentName,
  titleStyles,
  distanceStyles,
  commentStyles,
}) => (
  <div className={`${wrapperStyles}`}>
    <InputWrapper containerClasses={`${inputWrapperStyles} ${titleStyles}`}>
      <Input
        hideError
        type="text"
        maxLength={PROP_BRAND_MAX}
        name={titleName}
        placeholder={LOCATION_TRANSPORT.locationName.placeholder}
      />
      <ArrayFieldErrorMessage name={titleName} />
    </InputWrapper>
    <InputWrapper containerClasses={`${inputWrapperStyles} ${distanceStyles}`}>
      <Input
        hideError
        type="number"
        step={DISTANCE_INPUT_STEP}
        maxLength={MAX_NUMBER_VALUE}
        name={distanceName}
        placeholder={LOCATION_TRANSPORT.distance.placeholder}
      />
      <ArrayFieldErrorMessage name={distanceName} />
    </InputWrapper>
    <InputWrapper containerClasses={`${inputWrapperStyles} ${commentStyles}`}>
      <Input
        hideError
        type="text"
        maxLength={PROP_DESCR_MAX}
        name={commentName}
        placeholder={LOCATION_TRANSPORT.locationComment.placeholder}
      />
      <ArrayFieldErrorMessage name={commentName} />
    </InputWrapper>
  </div>
);

LocationsInputsBlock.defaultProps = {
  wrapperStyles: '',
  inputWrapperStyles: '',
  titleStyles: '',
  distanceStyles: '',
  commentStyles: '',
};

export default LocationsInputsBlock;
