import moment from 'moment';

export const THIS_WEEK = 'this_week';
export const THIS_MONTH = 'this_month';
export const THREE_MONTHS = 'three_months';
export const ALL = 'all';
export const NEXT_THREE_MONTHS_PERIOD = 3;
export const MAX_CUSTOM_FEATURES = 10;
export const CUSTOM_FEATURE_TITLE_FIELD = 'newOtherFeatureTitle';

export const AVAILABILITY_DATE_OPTIONS = [
  {
    label: 'This week',
    value: THIS_WEEK,
  },
  {
    label: 'This month',
    value: THIS_MONTH,
  },
  {
    label: '3 Months',
    value: THREE_MONTHS,
  },
  {
    label: 'All',
    value: ALL,
  },
];

export const PERIODS = {
  [THIS_WEEK]: {
    startDate: moment().startOf('week').format(),
    endDate: moment().endOf('week').format(),
  },
  [THIS_MONTH]: {
    startDate: moment().startOf('month').format(),
    endDate: moment().endOf('month').format(),
  },
  [THREE_MONTHS]: {
    startDate: moment().startOf('month').format(),
    endDate: moment().startOf('month').add(NEXT_THREE_MONTHS_PERIOD, 'month').format(),
  },
  [ALL]: {
    startDate: null,
    endDate: null,
  },
};

export const OFFER_DETAILS_TAB_NAMES = [
  'General, Dates & Inventory',
  'Included features',
  'Price, Taxes and T&C',
];

export const OFFER_DETAILS_TAB_HEADERS = [
  'General info',
  'Included features',
  'Price before tax',
];
