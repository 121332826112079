import React, { useState, useEffect } from 'react';
import toastr from 'toastr';
import { Formik, Form } from 'formik';
import GeneralInfo from '../GeneralInfo/GeneralInfo';
import EditRoomWrapper from '../EditRoomWrapper/EditRoomWrapper';
import EditRoomFooter from '../EditRoomFooter/EditRoomFooter';
import { ROOM_GENERAL_INFO } from '../../../../constans/form-fields';
import { roomGeneralSchema } from '../../../../constans/validation-schemas';
import { editRoomGeneralInfo, getRoomGeneralInfo } from '../../../../services/rooms-manage.service';
import { createNotEmptyFieldsObject } from '../../../../helpers/helper-methods';

import { SUCCESS_SEND_MSG } from '../../../../constans/texts/texts';

const EditGeneralInfo = ({ id, roomId, path }) => {
  const [initialValues, setInitialValues] = useState({
    general: {
      main: {
        [ROOM_GENERAL_INFO.roomType.name]: '',
        [ROOM_GENERAL_INFO.roomDescr.name]: '',
        [ROOM_GENERAL_INFO.roomSurface.name]: '',
        [ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]: '',
        [ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]: '',
        [ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name]: '',
        [ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name]: '',
        [ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]: '',
        [ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]: '',
      },
      [ROOM_GENERAL_INFO.designedCapacitySum.name]: 0,
      [ROOM_GENERAL_INFO.maxCapacitySum.name]: 0,
      [ROOM_GENERAL_INFO.optimizedCapacitySum.name]: 0,
    },
  });

  const fetchData = () => {
    getRoomGeneralInfo(roomId).then(({ data }) => {
      const updatedInitialData = {
        general: {
          ...initialValues,
          main: {
            [ROOM_GENERAL_INFO.roomType.name]: data.title ? data.title : '',
            [ROOM_GENERAL_INFO.roomDescr.name]: data.description ? data.description : '',
            [ROOM_GENERAL_INFO.roomSurface.name]: data.surface ? data.surface : '',
            [ROOM_GENERAL_INFO.roomDesignedCapacityForAdults.name]: data.designedCapacityForAdults ? data.designedCapacityForAdults : '',
            [ROOM_GENERAL_INFO.roomDesignedCapacityForKids.name]: data.designedCapacityForKids ? data.designedCapacityForKids : '',
            [ROOM_GENERAL_INFO.roomOptimizedCapacityForAdults.name]: data.optimizedCapacityForAdults ? data.optimizedCapacityForAdults : '',
            [ROOM_GENERAL_INFO.roomOptimizedCapacityForKids.name]: data.optimizedCapacityForKids ? data.optimizedCapacityForKids : '',
            [ROOM_GENERAL_INFO.roomMaximumCapacityForAdults.name]: data.maximumCapacityForAdults ? data.maximumCapacityForAdults : '',
            [ROOM_GENERAL_INFO.roomMaximumCapacityForKids.name]: data.maximumCapacityForKids ? data.maximumCapacityForKids : '',
          },
        },
      };
      setInitialValues(updatedInitialData);
    });
  };

  const formSubmitHandler = async ({ general }) => {
    try {
      await editRoomGeneralInfo(roomId, createNotEmptyFieldsObject(general.main));
      toastr.success(SUCCESS_SEND_MSG);
    } catch (e) {
    // Do nothing . . .
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <EditRoomWrapper
      propertyId={id}
      roomId={roomId}
      path={path}
    >
      <Formik
        enableReinitialize
        onSubmit={formSubmitHandler}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={roomGeneralSchema}
        initialValues={initialValues}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="edit-from-mobile edit-form-wrapper">
            <GeneralInfo
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
            <EditRoomFooter propertyId={id} />
          </Form>
        )}
      </Formik>
    </EditRoomWrapper>
  );
};

export default EditGeneralInfo;
