import React, { useEffect, useMemo, useState } from 'react';
import { navigate } from 'gatsby';

import PropertiesService from '../../services/properties.service';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Filters from '../../components/Filters/Filters';
import OfferCard from '../../components/OfferCard/OfferCard';
import PropertyDetailsHeader from '../../components/PropertyDetails/PropertyDetailsHeader/PropertyDetailsHeader';
import DeleteOfferModal from '../../components/DeleteOfferModal/DeleteOfferModal';
import Pagination from '../../components/Pagination';
import IconPlus from '../../assets/img/icons/ic-plus-active.svg';
import IconDelete from '../../assets/img/icons/ic-delete-default.svg';
import { LIMIT } from '../../constans/pagination';
import { CheckboxWithoutBinding } from '../../components/UI/Checkbox/Checkbox';
import {
  toggleBodyStylesForModal,
  setCurrentPaginationPageMultiple,
} from '../../helpers/helper-methods';

import styles from './Offers.module.scss';

const Offers = ({ id, path }) => {
  const service = useMemo(() => PropertiesService(), []);
  const [offers, setOffers] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState();
  const [modalShown, setModalShown] = useState(false);
  const [multiOffersModalShown, setMultiOffersModalShown] = useState(false);
  const [filters, setFilters] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const [total, setTotal] = useState(0);
  const [nextOffsetPage, setNextOffsetPage] = useState(0);

  const toggleMultiDeleteModal = () => {
    toggleBodyStylesForModal();
    setMultiOffersModalShown((prevState) => !prevState);
  };

  const toggleDeleteModal = () => {
    toggleBodyStylesForModal();
    setModalShown((prevState) => !prevState);
  };

  const isAllChecked = (offers.length === toDelete.length) && offers.length;

  const handlePagination = ({ selected: page }) => {
    service
      .getPropertyOffers(id, {
        limit: LIMIT,
        offset: page * LIMIT,
        ...filters,
      })
      .then(({ data, pagination: { totalCount, nextOffset } }) => {
        setOffers(data);
        setPageNumber(page);
        setToDelete([]);
        setTotal(totalCount);
        setNextOffsetPage(nextOffset);
      });
  };

  const openModal = (offerId) => {
    toggleDeleteModal();
    setSelectedOfferId(offerId);
  };

  const handleDeleteAll = (offersIds) => service.deleteOffers(id, offersIds)
    .then(() => {
      setOffers(offers.filter(({ id: offerId }) => !offersIds.includes(offerId)));
      toggleMultiDeleteModal();
      setMultiOffersModalShown(false);
    })
    .then(() => handlePagination(
      setCurrentPaginationPageMultiple(nextOffsetPage, total, pageNumber, LIMIT, toDelete.length),
    ))
    .then(() => setToDelete([]));

  const handleDelete = (offerId) => service.deleteOffers(id, [offerId])
    .then(() => {
      const filterByOfferId = (arrayElement) => (arrayElement !== offerId);

      setOffers(offers.filter(({ id: $id }) => filterByOfferId($id)));
      setToDelete(toDelete.filter(filterByOfferId));
      toggleDeleteModal();
    })
    .then(() => handlePagination(
      setCurrentPaginationPageMultiple(nextOffsetPage, total, pageNumber, LIMIT, 1),
    ));

  const handleCheck = ({ currentTarget }) => {
    const { value } = currentTarget;
    const formatedValue = Number(value);

    if (toDelete.includes(formatedValue)) {
      setToDelete([...toDelete.filter((val) => val !== formatedValue)]);
    } else {
      setToDelete([...toDelete, formatedValue]);
    }
  };

  const handleCheckAll = () => (isAllChecked
    ? setToDelete([])
    : setToDelete(offers.map(({ id: offerId }) => (offerId))));

  useEffect(() => {
    service.getPropertyOffers(id, { limit: LIMIT, ...filters })
      .then(({ data, pagination: { totalCount, nextOffset } }) => {
        setOffers(data);
        setPageNumber(0);
        setToDelete([]);
        setTotal(totalCount);
        setNextOffsetPage(nextOffset);
      });
  }, [filters]);

  return (
    <div className="container">
      <DeleteOfferModal
        show={modalShown}
        offerId={selectedOfferId}
        onSubmit={handleDelete}
        closeModal={toggleDeleteModal}
      />
      <DeleteOfferModal
        multiple
        show={multiOffersModalShown}
        offerId={toDelete}
        onSubmit={handleDeleteAll}
        closeModal={toggleMultiDeleteModal}
      />
      <div className={styles.crumbs}>
        <Breadcrumbs
          path={path}
          id={id}
        />
      </div>
      <PropertyDetailsHeader containerStyles={styles.header} id={id} />
      <div className={`row flex-grow-1 flex ${styles.pageWrapper}`}>
        <div className="col-lg-3">
          <Filters id={Number(id)} setFilters={setFilters} />
        </div>
        <div className={`col ${styles.noTablet}`}>
          <button
            type="button"
            className={`
              ${styles.buttonAdd}
              btn btn-uppercased btn_common t-600
            `}
            onClick={() => navigate(`/sellers/my-properties/${id}/offers/create-offer`)}
          >
            <IconPlus className={styles.iconPlus} />
            Create offer
          </button>
        </div>
        <div className="col-12 col-lg-9">
          <div className="flex flex-v-center flex-between">
            <CheckboxWithoutBinding
              onChange={handleCheckAll}
              checkboxClasses={`${styles.checkbox} c-custom__check-bordered-primary c-custom__check-sm`}
              containerClasses={`${styles.checkboxContainer} ${styles.selectAll}`}
              labelClasses={`${styles.checkboxLabel} ${styles.checkboxLabelAll} flex flex-v-center`}
              checked={isAllChecked}
            >
              Select all
            </CheckboxWithoutBinding>
            <button
              type="button"
              className={`
                ${styles.buttonAdd}
                ${styles.hideTablet}
                btn btn-uppercased btn_common t-600
              `}
              onClick={() => navigate(`/sellers/my-properties/${id}/offers/create-offer`)}
            >
              <IconPlus className={styles.iconPlus} />
              Create offer
            </button>
          </div>
          {!!toDelete.length && (
            <div className={`${styles.selected} flex flex-between flex-v-center`}>
              <p className="mb-0">
                {`Selected ${toDelete.length} Offer(s) per page`}
              </p>
              <button
                type="button"
                onClick={toggleMultiDeleteModal}
                className={`
                  ${styles.buttonDelete}
                  flex flex-v-center
                `}
              >
                <IconDelete />
                Delete
              </button>
            </div>
          )}

          {offers.map((offer) => (
            <OfferCard
              propertyId={Number(id)}
              key={offer.id}
              onDeleteClick={openModal}
              {...offer}
            >
              <CheckboxWithoutBinding
                onChange={handleCheck}
                checkboxClasses={`
                  ${styles.checkbox} 
                  c-custom__check-bordered-primary
                  c-custom__check-sm
                  c-custom__custom-input
                  c-custom__check
                `}
                containerClasses={styles.checkboxContainer}
                labelClasses={`${styles.checkboxLabel} text-truncate flex flex-v-center`}
                value={offer.id}
                checked={toDelete.includes(offer.id)}
              >
                {offer.room.title}
              </CheckboxWithoutBinding>
            </OfferCard>
          ))}

          <Pagination
            perPage={LIMIT}
            forcePage={pageNumber}
            total={total}
            callback={handlePagination}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
