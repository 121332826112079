import React, { useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';

import { Input } from '../../../../components/UI/Input/Input';
import InputWrapper from '../../../../components/UI/InputWrapper/InputWrapper';
import Label from '../../../../components/UI/Label/Label';
import { DEFAULT_FORMAT } from '../../../../constans/formats';
import CustomSelect from '../../../../components/UI/CustomSelect/CustomSelect';
import IconCalendar from '../../../../assets/img/icons/ic-calendar-active.svg';
import CustomCreatableSelect from '../../../../components/UI/CustomCreatableSelect/CustomCreatableSelect';
import { MAX_TOTAL_CAPACITY } from '../../../../constans/roomsServices';
import { ADULT_PREFIX, KIDS_PREFIX } from '../../../../constans/search';
import { MAX_CODE_LENGTH, MAX_OFFER_TITLE_LENGTH } from '../../../../constans/validation';
import CheckboxBlock from '../../../../components/UI/CheckboxBlock/CheckboxBlock';

import Error from '../../../../components/UI/Error/Error';

import styles from './GeneralOfferInfoTab.module.scss';
import commonStyles from '../CreateOffer.module.scss';

function GeneralOfferInfoTab({
  values, roomCategories, setFieldValue, errors, offerId,
}) {
  const getDesignedRoomCapacityByRoomId = () => roomCategories
    .filter(({ value }) => value === values.roomId)
    .map(({ designedCapacity }) => designedCapacity)
    .pop();

  const getRoomCapacityByRoomId = () => roomCategories
    .filter(({ value }) => value === values.roomId)
    .map(({
      designedCapacityForAdults,
      designedCapacityForKids,
    }) => `(${designedCapacityForAdults}${ADULT_PREFIX} + ${designedCapacityForKids || 0}${KIDS_PREFIX})`)
    .pop();

  const maxGeuestsValues = [
    { label: `as per designed capacity ${getRoomCapacityByRoomId()}`, value: getDesignedRoomCapacityByRoomId() || MAX_TOTAL_CAPACITY },
  ];

  if (values.maxNumberOfGuests) {
    if (values.maxNumberOfGuests !== getDesignedRoomCapacityByRoomId()) {
      maxGeuestsValues.push(
        { label: values.maxNumberOfGuests, value: values.maxNumberOfGuests },
      );
    }
  }

  const disableStartDates = (current) => {
    if (offerId) {
      return null;
    }

    return moment().endOf('day') && current < moment().endOf('day');
  };

  const disableEndDates = (current) => current && current <= moment(values.startDate).startOf('day');

  useEffect(() => {
    if (values.roomId) {
      setFieldValue('maxRoomCapacity', roomCategories?.find(({ value }) => value === values.roomId)?.maxCapacity || MAX_TOTAL_CAPACITY);
    }
  }, []);

  return (
    <>
      <p className={commonStyles.cardTitle}>
        General info
      </p>
      <hr className={commonStyles.divider} />
      <div className={`flex flex-between ${commonStyles.inputRow}`}>
        <InputWrapper containerClasses={commonStyles.input}>
          <Label>Offer name</Label>
          <Input
            type="text"
            maxLength={MAX_OFFER_TITLE_LENGTH}
            name="title"
            placeholder="Enter offer name"
            autocomplete="on"
          />
        </InputWrapper>
        <InputWrapper containerClasses={commonStyles.input}>
          <Label>Offer code</Label>
          <Input
            type="text"
            name="code"
            maxLength={MAX_CODE_LENGTH}
            placeholder="AD2345"
          />
        </InputWrapper>
      </div>
      <div className={`flex flex-between ${commonStyles.inputRow}`}>
        <InputWrapper
          containerClasses={commonStyles.input}
        >
          <Label>Room type</Label>
          <CustomSelect
            containerStyles={styles.select}
            options={roomCategories}
            placeholder="Select room type"
            name="roomId"
            onChange={(value) => {
              setFieldValue('maxNumberOfGuests', value.designedCapacity);
              setFieldValue('maxRoomCapacity', value.maxCapacity);
            }}
          />
        </InputWrapper>
        <InputWrapper
          containerClasses={commonStyles.input}
        >
          <Label>Max number of guests</Label>
          <CustomCreatableSelect
            withCustomValue={false}
            disabled={!values.roomId}
            options={maxGeuestsValues}
            placeholder="Type or choose quantity"
            name="maxNumberOfGuests"
          />
        </InputWrapper>
      </div>

      <p className={commonStyles.cardTitle}>
        Dates
      </p>
      <hr className={commonStyles.divider} />

      <InputWrapper
        containerClasses={commonStyles.input}
      >
        <CheckboxBlock
          titleValue="No time limit"
          inputName="noTimeLimit"
          checkBoxName="noTimeLimit"
          withComment={false}
        />
      </InputWrapper>

      {!values.noTimeLimit && (
      <div className={`flex flex-between ${commonStyles.inputRow}`}>
        <InputWrapper
          containerClasses={commonStyles.input}
        >
          <Label>Beginning date</Label>
          <DatePicker
            size="large"
            format={DEFAULT_FORMAT}
            placeholder="Start date"
            suffixIcon={<IconCalendar />}
            allowClear={false}
            name="startDate"
            disabledDate={disableStartDates}
            value={values.startDate ? moment(values.startDate) : null}
            onChange={($moment) => setFieldValue('startDate', $moment.format())}
            style={{
              borderRadius: '6px',
              width: '100%',
            }}
          />
          {errors.startDate && <Error error={errors.startDate} />}
        </InputWrapper>
        <InputWrapper
          containerClasses={commonStyles.input}
        >
          <Label>End date</Label>
          <DatePicker
            size="large"
            format={DEFAULT_FORMAT}
            placeholder="End date"
            suffixIcon={<IconCalendar />}
            allowClear={false}
            name="endDate"
            disabledDate={disableEndDates}
            disabled={!values.startDate}
            value={values.endDate ? moment(values.endDate) : null}
            onChange={($moment) => setFieldValue('endDate', $moment.format())}
            style={{
              borderRadius: '6px',
              width: '100%',
            }}
          />
          {errors.endDate
            && (
              <Error
                error={errors.endDate?.replace(':val', moment(values.startDate).format(DEFAULT_FORMAT))}
              />
            )}
        </InputWrapper>
      </div>
      )}
      <InputWrapper
        containerClasses={commonStyles.inputLone}
      >
        <Label>Minimum stay duration</Label>
        <Input
          type="number"
          name="minimumStayDuration"
          placeholder="# of days"
        />
      </InputWrapper>
      <p className={commonStyles.cardTitle}>
        Inventory
      </p>
      <hr className={commonStyles.divider} />
      <div className="flex flex-between">
        <InputWrapper
          containerClasses={commonStyles.inputLone}
        >
          <Label>Inventory number </Label>
          <Input
            type="number"
            name="inventoryNumber"
            placeholder="Enter inventory number"
          />
        </InputWrapper>
      </div>
    </>
  );
}

export default GeneralOfferInfoTab;
