import http from './http.service';

const TermsAndConditionsService = () => {
  const basePath = '/terms-and-conditions';

  const getTermsAndConditionByPropertyId = (model) => http.get(basePath, { params: model });

  return {
    getTermsAndConditionByPropertyId,
  };
};

export default TermsAndConditionsService;
