import React, { useEffect, useState } from 'react';
import Spinner from '../../../components/UI/Spinner/Spinner';
import SellerSettingsWrapper from '../SellerSettingsWrapper/SellerSettingsWrapper';
import { stripeConnectHandler } from '../../../helpers/helper-methods';

const SellerStripeRefresh = () => {
  const [loading, setLoading] = useState(false);
  const toggleLoading = () => setLoading((prevState) => !prevState);

  useEffect(() => {
    stripeConnectHandler(toggleLoading);
  }, []);

  return (
    <SellerSettingsWrapper>
      {loading ? <Spinner spinnerClasses="spinner-primary" /> : 'Stripe error occurred'}
    </SellerSettingsWrapper>
  );
};

export default SellerStripeRefresh;
