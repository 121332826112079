import React from 'react';
import styles from './TableContentWrapper.module.scss';

const TableContentWrapper = ({ children }) => (
  <div className={styles.contentWrapper}>
    {children}
  </div>
);

export default TableContentWrapper;
