/* eslint-disable max-len */
import React from 'react';
import { KM_TO_METERS } from '../../../constans/values';
import PropertyRestaurant from '../../PropertyRestaurant/PropertyRestaurant';
import { objectIsNotEmpty, formatTime, formatCurrency } from '../../../helpers/helper-methods';
import {
  locationsMap,
  facilitiesMap,
  facilitiesInterval,
  payedStatus,
} from '../../../constans/model-maps';

import styles from './PropertyDetailsInfoSeller.module.scss';
import { NA } from '../../../constans/roomsServices';

function PropertyDetailsInfoSeller({
  propertyData,
  containerStyles,
  anchorsData,
  foodImageStyles,
}) {
  return (
    <div className={containerStyles}>
      <div id={anchorsData.aboutUs.id} className={styles.infoBlock}>
        <div className={styles.aboutUsTitle}>about us</div>
        {propertyData.generalDescription && (
        <>
          <div
            className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}
          >
            General intro and description
          </div>
          <div className={`${styles.infoBlockText}`}>
            {propertyData.generalDescription}
          </div>
        </>
        )}
        {propertyData.brandDescription && (
        <>
          <div
            className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}
          >
            Chain / brand
          </div>
          <div className={`${styles.infoBlockText}`}>
            {propertyData.brandDescription}
          </div>
        </>
        )}
        {propertyData.aboutUsDescription && (
        <>
          <div
            className={`${styles.generalDescr} ${styles.infoBlockSubtitle}`}
          >
            About us / values / word from management
          </div>
          <div className={`${styles.infoBlockText}`}>
            {propertyData.aboutUsDescription}
          </div>
        </>
        )}
      </div>
      {propertyData.accessibilities
      || propertyData.accessibilityDescription ? (
        <div
          id={anchorsData.accessibilities.id}
          className={styles.infoBlock}
        >
          <div className={styles.infoBlockTitle}>accessibility</div>

          {propertyData.accessibilities && (
          <div className={styles.infoList}>
            {propertyData.accessibilities.map(
              (accessibilityItem, index) => (
                <div
                  key={`sdfdf${index}`}
                  className={`${styles.infoListItemDotted} ${styles.infoListItem}`}
                >
                  <div
                    className={`${styles.listItemTitleMargined} t-500`}
                  >
                    {accessibilityItem.title}
                  </div>
                  {accessibilityItem.description ? (
                    <div className={styles.listItemDescr}>
                      {accessibilityItem.description}
                    </div>
                  ) : null}
                </div>
              ),
            )}
          </div>
          )}
        </div>
        ) : null}
      {propertyData.environmentalDescription
      || propertyData.socialAndEthicalDescription ? (
        <div
          id={anchorsData.policies.id}
          className={styles.infoBlock}
        >
          <div className={styles.infoBlockTitle}>
            Environmental & Social
          </div>
          <div className={styles.infoList}>
            {propertyData.environmentalDescription && (
            <div className={`${styles.infoListItem}`}>
              <div
                className={`${styles.infoBlockSubtitle} uppercased-text t-600`}
              >
                Environmental policies, projects and actions
              </div>
              <div className={styles.infoBlockText}>
                {propertyData.environmentalDescription}
              </div>
            </div>
            )}
            {propertyData.socialAndEthicalDescription && (
            <div className={`${styles.infoListItem}`}>
              <div
                className={`${styles.infoBlockSubtitle} uppercased-text t-600`}
              >
                Social policies, projects and actions
              </div>
              <div className={styles.infoBlockText}>
                {propertyData.socialAndEthicalDescription}
              </div>
            </div>
            )}
          </div>
        </div>
        ) : null}
      {propertyData.transports
      && propertyData.transports.length > 0 && (
        <div
          id={anchorsData.transports.id}
          className={styles.infoBlock}
        >
          <div className={styles.infoBlockTitle}>
            Location and transports
          </div>
          <div className={styles.infoList}>
            {propertyData.transports.map(
              (transportItem, index) => (
                <div
                  key={`sdo${index}`}
                  className={`${styles.infoListItem} ${styles.infoListItemDotted}`}
                >
                  <div className={`${styles.mb10} t-500`}>
                    {transportItem.type}
                  </div>
                  <div
                    className={`${styles.listItemSubtitle} ${styles.mb5}`}
                  >
                    {transportItem.title}
                    <span className={styles.subtitleDelimiter}>
                      -
                    </span>
                    {transportItem.distance / KM_TO_METERS}
                    <span className={styles.distanceUnits}>
                      km
                    </span>
                  </div>
                  {transportItem.description && (
                    <div className={styles.listItemDescr}>
                      {transportItem.description}
                    </div>
                  )}
                </div>
              ),
            )}
            {propertyData.surroundings
              && objectIsNotEmpty(propertyData.surroundings)
              && (
                <>
                  {
                    Object.keys(propertyData.surroundings).map(
                      (surrItemKey, index) => (
                        propertyData.surroundings[surrItemKey].length > 0 ? (
                          <div
                            key={`fo${index}`}
                            className={`${styles.infoListItem} ${styles.infoListItemDotted}`}
                          >
                            {propertyData.surroundings[surrItemKey].map(
                              (surrItem, indexInner) => (
                                <div
                                  className={styles.surroundingsDataListItem}
                                  key={`fpo${indexInner}`}
                                >
                                  {indexInner < 1 ? (
                                    <div
                                      className={`${styles.mb10} t-500`}
                                    >
                                      {locationsMap[surrItem.type]}
                                    </div>
                                  ) : null}
                                  <div
                                    className={`${styles.listItemSubtitle} ${styles.surroundingsDataTitleDistance}`}
                                  >
                                    {surrItem.title}
                                    <span
                                      className={styles.subtitleDelimiter}
                                    >
                                      -
                                    </span>
                                    {surrItem.distance / KM_TO_METERS}
                                    <span
                                      className={styles.distanceUnits}
                                    >
                                      km
                                    </span>
                                  </div>
                                  {surrItem.description && (
                                    <div
                                      className={styles.listItemDescr}
                                    >
                                      {surrItem.description}
                                    </div>
                                  )}
                                </div>
                              ),
                            )}
                          </div>
                        ) : (
                          []
                        )),
                    )
                  }
                </>
              )}
          </div>
        </div>
      )}
      {propertyData.facilities || propertyData.keyFacilities ? (
        <div
          id={anchorsData.facilities.id}
          className={styles.infoBlock}
        >
          <div className={styles.infoList}>
            {propertyData.facilities
            && propertyData.facilities.length > 0 && (
              <div className={styles.infoListItem}>
                <div className={styles.infoBlockTitle}>
                  List of facilities
                </div>
                {propertyData.facilities.map((facility, item) => (
                  <div
                    key={`cowhq${item}`}
                    className={`${styles.infoListItemDotted} ${styles.mb15}`}
                  >
                    <div className={`${styles.mb5} t-500`}>
                      {facility.title}
                    </div>
                    <div className={`${styles.infoBlockText}`}>
                      {facility.description}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {propertyData.keyFacilities
              ? objectIsNotEmpty(propertyData.keyFacilities) && (
              <div className={styles.infoListItem}>
                <div className={styles.infoBlockTitle}>
                  Parking & Wi-fi
                </div>
                {Object.keys(propertyData.keyFacilities).map(
                  (keyfacilityKey, item) => (
                    <div
                      key={`xdvcf${item}`}
                      className={`${styles.infoListItemDotted} ${styles.mb15}`}
                    >
                      <div className={`${styles.mb5} t-500`}>
                        <span
                          className={`${styles.mr5} capitalized-text`}
                        >
                          {facilitiesMap[propertyData
                            .keyFacilities[keyfacilityKey].status]
                            ? facilitiesMap[propertyData
                              .keyFacilities[keyfacilityKey].status]
                            : propertyData
                              .keyFacilities[keyfacilityKey].status.toLowerCase()}
                        </span>
                        <span className="lowered-text">
                          {
                            propertyData.keyFacilities[
                              keyfacilityKey
                            ].type
                          }
                        </span>
                        {propertyData.keyFacilities[
                          keyfacilityKey
                        ].status === payedStatus && (
                        <>
                          <span
                            className={styles.subtitleDelimiter}
                          >
                            -
                          </span>
                          {propertyData.keyFacilities[keyfacilityKey].price
                                && propertyData.keyFacilities[keyfacilityKey].currency.code ? (
                                  <span
                                    className={`${styles.mr5} ${styles.ml5}`}
                                  >
                                    {formatCurrency(
                                      propertyData.keyFacilities[keyfacilityKey].currency.code,
                                      propertyData.keyFacilities[keyfacilityKey].price,
                                    )}
                                  </span>
                            ) : null}
                          {facilitiesInterval[propertyData
                            .keyFacilities[keyfacilityKey].interval]}
                        </>
                        )}
                      </div>
                      <div
                        className={`${styles.infoBlockText}`}
                      >
                        {
                          propertyData.keyFacilities[
                            keyfacilityKey
                          ].description
                        }
                      </div>
                    </div>
                  ),
                )}
              </div>
              ) : null}
          </div>
        </div>
      ) : null}
      {propertyData.restaurants && (
      <div
        id={anchorsData.restaurants.id}
        className={styles.infoBlock}
      >
        <div className={styles.infoBlockTitle}>
          Food & Beverages
        </div>
        <div
          className={`${styles.foodContainer} ${styles.infoList}`}
        >
          {propertyData.restaurants.map(({ id: restaurantId, ...restaurant }) => (
            <PropertyRestaurant
              foodImageStyles={foodImageStyles}
              className={styles.infoListItem}
              key={restaurantId}
              {...restaurant}
            />
          ))}
        </div>
      </div>
      )}
      {propertyData.roomServices && (
      <div
        id={anchorsData.roomServices.id}
        className={`${styles.infoBlock} ${styles.lastBlock}`}
      >
        <div className={styles.infoBlockTitle}>Room service</div>
        <div
          className={`${styles.foodContainer} ${styles.infoList}`}
        >
          {propertyData.roomServices.map((room, index) => (
            <div
              className={`${styles.infoListItemDotted} t-500`}
              key={`sc${index}`}
            >
              <div
                className={`${styles.mb10} ${styles.roomServiceDataTitle} capitalized-text t-500`}
              >
                <span>{room.title}</span>
                {(room.fromTime && room.toTime)
                  ? (
                    <>
                      <span className={styles.subtitleDelimiter}>
                        -
                      </span>
                      <span>{!!room.fromTime && formatTime(room.fromTime)}</span>
                      <span className={styles.subtitleDelimiter}>
                        -
                      </span>
                      <span>{!!room.toTime && formatTime(room.toTime)}</span>
                    </>
                  ) : (room.status === NA.value ? (
                    <span>
                      {' '}
                      {NA.label}
                    </span>
                  ) : <span> 24/7</span>)}
              </div>
              {room.description && (
              <div
                className={`${styles.listItemDescr} ${styles.roomServiceDataDescr}`}
              >
                { /* room.description */ }
              </div>
              )}
            </div>
          ))}
        </div>
      </div>
      )}
    </div>
  );
}

PropertyDetailsInfoSeller.defaultProps = {
  foodImageStyles: '',
  containerStyles: '',
  anchorsData: {
    aboutUs: {
      id: null,
    },
    accessibilities: {
      id: null,
    },
    policies: {
      id: null,
    },
    transports: {
      id: null,
    },
    facilities: {
      id: null,
    },
    restaurants: {
      id: null,
    },
    roomServices: {
      id: null,
    },
  },
};

export default PropertyDetailsInfoSeller;
