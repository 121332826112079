import React from 'react';
import { Link } from 'gatsby';
import { SELLER_NAVBAR } from '../../constans/links';

import styles from './index.module.scss';

const SellerHeader = () => (
  <div className={styles.wrapper}>
    <div className={`container flex ${styles.linkRow}`}>
      {SELLER_NAVBAR.map(({ title, href }, index) => (
        <Link
          key={index}
          to={href}
          className={styles.link}
          activeClassName={styles.linkActive}
        >
          <div className={styles.dot} />
          {title}
        </Link>
      ))}
    </div>
  </div>
);

export default SellerHeader;
