import React, { useState } from 'react';
import { Link } from 'gatsby';
import http from '../../../services/http.service';
import EditIcon from '../../../assets/img/icons/ic-edit-default-new.svg';
import RoomItemSlider from '../RoomItemSlider/RoomItemSlider';
import RoomCapacityItem from '../RoomCapacityItem/RoomCapacityItem';
import AmenitiesBtn from '../AmenitiesBtn/AmenitiesBtn';
import AmenitiesModal from '../AmenitiesModal/AmenitiesModal';
import RemoveModal from '../../UI/RemoveModal/RemoveModal';
import RoomItemTitle from '../RoomItemTitle/RoomItemTitle';
import RoomItemDescr from '../RoomItemDescr/RoomItemDescr';
import RoomCapacityList from '../RoomCapacityList/RoomCapacityList';
import styles from './PropertyEditRoomItem.module.scss';
import { toggleBodyStylesForModal } from '../../../helpers/helper-methods';

const PropertyEditRoomItem = ({
  capacitiesObj,
  title,
  editUrl,
  description,
  index,
  amenities,
  photos,
  surface,
  itemStyles,
  bedTypes,
  roomId,
  getRooms,
  _pageNumber,
  checkForPaginUpdate,
}) => {
  const [displayModal, setDisplayModal] = useState(false);

  const toggleModal = () => {
    toggleBodyStylesForModal();
    setDisplayModal(!displayModal);
  };

  const removeRoomHandler = () => {
    http.delete(`/rooms/${roomId}`)
      .then(() => getRooms(checkForPaginUpdate()));
  };

  return (
    <div className={itemStyles}>
      <div
        className={`
          ${styles.actionBtnsWrapper} 
          flex flex-v-center
        `}
      >
        <Link
          to={editUrl}
          className={`${styles.actionBtn} ${styles.editLink} flex-inline`}
        >
          <EditIcon className={styles.icon} />
        </Link>
        <RemoveModal
          showRemove
          modalWrapperClasses={styles.removeModalWrapper}
          removeIconClasses={`${styles.actionBtn} ${styles.removeBtn}`}
          modalSubtitle="Do you want to remove this item?"
          modalSubmit={removeRoomHandler}
        />
      </div>
      <div className={`${styles.mainContentWrapper} flex`}>
        <RoomItemTitle containerStyles={`${styles.titleMobile} t-600`}>
          #
          {index}
          <span className={styles.titleDelimiter}>-</span>
          {title}
        </RoomItemTitle>
        <RoomItemSlider
          wrapperStyles={styles.sliderWrapper}
          slideStyles={styles.slideItem}
          photosArr={photos}
        />
        <div className={styles.textual}>
          <RoomItemTitle containerStyles={`${styles.title} t-600`}>
            #
            {index}
            <span className={styles.titleDelimiter}>-</span>
            {title}
          </RoomItemTitle>
          <RoomItemDescr containerStyles={`${styles.description} t-500`}>{description}</RoomItemDescr>
        </div>
      </div>
      <RoomCapacityList>
        {Object.keys(capacitiesObj).map((capacityKey, indexStep) => (
          <RoomCapacityItem
            key={`sdlkfj${indexStep}`}
            containerStyles={styles.capacityItem}
            title={capacitiesObj[capacityKey].cardLabel}
          >
            {capacitiesObj[capacityKey].values.map((capacityValues, innerIndex) => (
              <span
                key={`sdfd${innerIndex}`}
              >
                {capacityValues.value}
                {capacityValues.prefix}
              </span>
            ))}
          </RoomCapacityItem>
        ))}
        {amenities?.length || capacitiesObj || surface || bedTypes ? (
          <>
            <AmenitiesBtn
              onClick={toggleModal}
              amenitiesLength={amenities?.length}
            />
            <AmenitiesModal
              surface={surface}
              showModal={displayModal}
              toggleModal={toggleModal}
              amenities={amenities}
              capacitiesObj={capacitiesObj}
              bedTypesArr={bedTypes}
            />
          </>
        ) : null}
      </RoomCapacityList>
    </div>
  );
};

PropertyEditRoomItem.defaultProps = {
  containerStyles: '',
  itemStyles: '',
  editUrl: '/',
};

export default PropertyEditRoomItem;
